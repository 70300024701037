import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';
import { toast } from 'react-toastify';
import { removeLastExtension } from '../../../helpers/removeExtension';

const { setActionsPreviewData, setActionsPreviewStatus } = slice.actions;

const getActionsPreview = (url: any, params: any) => async () => {
  try {
    const response = await API.actionsPreview.getActionsPreview(url, params);
    dispatch(setActionsPreviewData(response.data));
    dispatch(setActionsPreviewStatus(response.status));
    // toast.success('Preview successful!');
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    toast.error(removeLastExtension(error?.response.data.message));
  }
};

export default {
  getActionsPreview
};
