import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { DeleteProps } from '../../../types/reduxTypes/delete';

const createReducer = <T extends SliceCaseReducers<DeleteProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setDeleteData(state, action) {
    state.deleteData = action.payload;
  },
  setDeleteStatus(state, action) {
    state.deleteStatus = action.payload;
  }
});

export default reducers;
