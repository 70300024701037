import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const modelAddingStatusManager = {
  modelAddingStatus(url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'GET'
    });
  }
};

export default modelAddingStatusManager;
