import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setModelSettingsData, setModelSettingsStatus } = slice.actions;

const getModelSettings = (url: any, params: any) => async () => {
  try {
    const response = await API.modelSettings.getModelSettings(url, params);
    dispatch(setModelSettingsData(response.data));
    dispatch(setModelSettingsStatus(response.status));
  } catch (error) {}
};

export default {
  getModelSettings
};
