import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { deployProps } from '../../../types/reduxTypes/deploy';

const createReducer = <T extends SliceCaseReducers<deployProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setDeployData(state, action) {
    state.deployData = action.payload;
  },
  setDeployStatus(state, action) {
    state.deployStatus = action.payload;
  }
});

export default reducers;
