import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const deleteManager = {
  deleteFile(params: any, url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'DELETE',
      data: params
    });
  }
};

export default deleteManager;
