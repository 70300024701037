import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.prediction;

const predictionData = createSelector([selector], (state) => state);

export default {
  predictionData
};
