import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  problemType: Yup.string().required('Please select a problemType'),
  targetColumn: Yup.string().required('Please select a model type'),
  modelType: Yup.string().required('Please select a target column')
});

export const initialValues = {
  problemType: '',
  modelType: '',
  targetColumn: ''
};
