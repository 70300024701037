import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const containerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0 50px',
  color: '#000'
};

export const titleStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
};

export const formContainerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '40px',
  marginBottom: '233px'
};

export const buttonContainerStyles: SxProps<Theme> = {
};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};

export const backButtonStyles: React.CSSProperties = {
  backgroundColor: '#202090',
  color: '#fff'
};

export const nextButtonStyles: React.CSSProperties = {
  backgroundColor: '#202090',
  color: '#fff',
  float: 'right'
};
export const loadingContainerStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '1100px',
  margin: '0',
  height: '600px'
};

export const loadingIconStyles: React.CSSProperties = {
  color: 'blue'
};

export const inputErrorStyles: React.CSSProperties = {
  position: 'absolute',
  color: 'red',
  fontSize: '12px',
  fontStyle: 'italic',
  top: '398px'
};
