import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, useFormik } from 'formik';
import { Button, CircularProgress, Grid, TextareaAutosize } from '@mui/material';
import { validationSchema, initialValues } from './Step6.constants';
import {
  backButtonStyles,
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  descriptionStyles,
  exampleStyles,
  formContainerStyles,
  formStyles,
  loadingStyles,
  textFieldStyles,
  titleStyles
} from './Step6.styles';
import { IStep6 } from './Step6.models';
import { modelAddingMiddleware } from '../../../../store/slices/modelAdding';
import { dispatch } from '../../../../store/hooks';
import { modelAddingStatusMiddleware } from '../../../../store/slices/modelAddingStatus';
import { userModelsMiddleware } from '../../../../store/slices/userModels';
import { storageMiddleware } from '../../../../store/slices/storage';
import { toast } from 'react-toastify';
import { contentStyles } from '../Step1/Step1.styles';
import { removeLastExtension } from '../../../../helpers/removeExtension';
import { blob } from 'stream/consumers';
import { IBlock } from '../Step52/Step52.models';

const Step6: React.FC<IStep6> = ({
  modelName,
  step1Data,
  step2Data,
  step3Data,
  step4Data,
  step5Data,
  step6Data,
  onClose,
  setStep5Data,
  setStep6Data,
  setActiveStep
}) => {
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [isJson, setIsJson] = useState<boolean>(false);
  const userId = localStorage.getItem('userId');
  const isJsonRef = useRef(false);

  const formik = useFormik({
    initialValues: {
      features: step6Data.features?.length > 0 ? step6Data.features : ''
    },
    validationSchema,
    onSubmit
  });

  const { values, handleChange, errors, touched } = formik;

  function safeParseJSON(jsonString: any) {
    try {
      const parsedJSON = JSON.parse(jsonString);
      isJsonRef.current = true;
      return parsedJSON;
    } catch (error) {
      setIsLoading(false);
      toast.error('Invalid JSON format. Please ensure the features are correctly formatted as a JSON array.');
      isJsonRef.current = false;
      return null; // Return null or appropriate fallback if parsing fails
    }
  }

  useEffect(() => {
    const trimmedFeatures = values.features.trim();
    setIsFormValid(trimmedFeatures.length > 0 && trimmedFeatures.startsWith('[') && trimmedFeatures.endsWith(']'));
    setStep6Data({ ...values });
  }, [errors, touched, values.features]);

  function onBackClick() {
    setActiveStep(5);
  }

  async function onSubmit() {
    // const updatedColumnsTopCategories = step5Data.blocks.reduce((acc: any, block: IBlock) => {
    //   const { dropdownValue, firstInputValue, secondInputValue } = block;
    //
    //   // Ensure none of the required values are empty
    //   if (dropdownValue && firstInputValue && secondInputValue) {
    //     acc[dropdownValue] = {
    //       top: firstInputValue,
    //       category_name: secondInputValue
    //     };
    //   }
    //
    //   return acc;
    // }, {});
    //
    // setStep5Data((prevData: any) => ({
    //   ...prevData,
    //   columns_top_categories: updatedColumnsTopCategories
    // }));
    const modelAddingUrl = '/model_import/model_adding';
    setIsLoading(true);
    const featuresArray = safeParseJSON(values.features.replace(/'/g, '"'));
    if (!isJsonRef.current) {
      setIsLoading(false);
      return;
    }

    const updatedColumnsNa = step3Data.blocks.reduce((acc: any, block) => {
      const { inputValue, radioButtonValue, customValue } = block;

      // Conditions to add to columns_na
      if (inputValue && radioButtonValue) {
        if (radioButtonValue === 'custom value' && customValue) {
          acc[inputValue] = { customValue };
        } else if (radioButtonValue !== 'custom value') {
          acc[inputValue] = radioButtonValue;
        }
      }

      return acc;
    }, {});

    const updatedColumnsTopCategories = step5Data.blocks.reduce((acc: any, block: IBlock) => {
      const { dropdownValue, firstInputValue, secondInputValue } = block;

      // Conditions to add to columns_top_categories
      if (dropdownValue && firstInputValue && secondInputValue) {
        acc[dropdownValue] = {
          top: parseInt(firstInputValue),
          category_name: secondInputValue
        };
      }

      return acc;
    }, {});

    const params = {
      model_name: modelName,
      blob_name: step1Data.uploadedFile,
      task: step2Data.problemType,
      target_name: step2Data.targetColumn,
      model_family:
        step2Data.modelType === 'logistic_regression' || step2Data.modelType === 'Linear regression'
          ? 'sklearn'
          : step2Data.modelType,
      na_handling: {
        skip: !Object.keys(updatedColumnsNa).length,
        columns_na: updatedColumnsNa
      },
      numerical_preprocessing: {
        skip: step4Data.option !== 'yes',
        scaling_method: step4Data.option === 'yes' ? step4Data.preprocessing : ''
      },
      categorical_preprocessing: {
        skip: step5Data.allCheck === 'no',
        encoding: step5Data.encoding,
        all_top_categories:
          step5Data.question_option === 'yes' &&
          step5Data?.all_top_categories &&
          Object.keys(step5Data?.all_top_categories.all_top_categories).length > 0
            ? step5Data.all_top_categories.all_top_categories
            : null,
        columns_top_categories:
          step5Data.question_option === 'no' && step5Data.blocks && step5Data.blocks.length > 0
            ? updatedColumnsTopCategories
            : null
      },
      model_features: featuresArray
    };
    const modelAddingData = await dispatch(modelAddingMiddleware.modelAdding(modelAddingUrl, params));

    if (modelAddingData && Object.keys(modelAddingData).length > 0 && modelAddingData?.data?.status_update) {
      const modelAddingStatusUrl = `/model_import/model_adding_status/${modelAddingData.data.status_update}`;
      const getUserModelsURL = `/datasource/user_models`;
      let intervalId: any = setInterval(async () => {
        try {
          const modelAddingStatusData = await dispatch(
            modelAddingStatusMiddleware.modelAddingStatus(modelAddingStatusUrl)
          );

          if (modelAddingStatusData.status === 200) {
            if (modelAddingStatusData.data.queue_state === 'SUCCESS') {
              setIsLoading(false);
              onClose();
              dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
              const getStorageURL = `blob_file/get_user_storage_size`;
              dispatch(storageMiddleware.getStorage(getStorageURL));
              clearInterval(intervalId);
              toast.success(` The '${removeLastExtension(modelName)}' model setup was successful.`);
              setActiveStep(modelAddingStatusData.data.message);
            } else if (
              modelAddingStatusData.data.queue_state === 'FAILURE' ||
              (modelAddingStatusData && Object.keys(modelAddingStatusData).length === 0)
            ) {
              setIsLoading(false);
              // onClose();
              clearInterval(intervalId);
              if (modelAddingStatusData && Object.keys(modelAddingStatusData).length === 0) {
                toast.warn(removeLastExtension(modelAddingStatusData.data.message));
                setActiveStep(modelAddingStatusData.data.message);
              } else {
                toast.error(removeLastExtension(modelAddingStatusData.data.message));
                setActiveStep(modelAddingStatusData.data.message);
              }
            }
          } else {
            setIsLoading(false);
            // onClose();
            clearInterval(intervalId);
            toast.error(removeLastExtension(modelAddingStatusData.data.message));
            setActiveStep(modelAddingStatusData.data.step);
          }
        } catch (error: any) {
          setIsLoading(false);
          onClose();
          clearInterval(intervalId);
          toast.error(removeLastExtension(error?.response?.data?.message), {
            autoClose: 5000 // duration in milliseconds (e.g., 5000ms = 5 seconds) //timing for toast
          });
        }
      }, 5000);
    }
  }

  return (
    <>
      {!isLoading ? (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form style={formContainerStyles}>
            <Grid item xs={12} md={12} style={containerStyles}>
              <div style={contentStyles}>
                <Grid item xs={12}>
                  <h2 style={titleStyles}>Model Features</h2>
                </Grid>
                <Grid item xs={12} md={12} style={formStyles}>
                  <h3 style={descriptionStyles}>
                    Provide the preprocessed final feature names in the correct sequence used in the model training
                    process.
                  </h3>
                  <span style={exampleStyles}>["prep_feature_1", "prep_feature_2", .... "prep_feature_n"]</span>
                  <TextareaAutosize
                    // onBlur={handleBlur}
                    // onKeyDown={(event) => {}}
                    value={values.features}
                    name="features"
                    style={textFieldStyles}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trim();
                      handleChange(e);
                      try {
                        const parsedFeatures = JSON.parse(trimmedValue.replace(/'/g, '"'));
                        setStep6Data({ ...values, features: parsedFeatures });
                      } catch (error) {
                        console.error('Invalid JSON format');
                      }
                    }}
                  />
                </Grid>
              </div>
              <Grid item xs={12} md={12} sx={buttonContainerStyles}>
                <Button
                  variant="contained"
                  size="large"
                  style={backButtonStyles}
                  sx={buttonStyles}
                  onClick={onBackClick}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{
                    width: '120px',
                    height: '60px',
                    borderRadius: '8px',
                    fontFamily: 'Circular Std Book',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    backgroundColor: !isFormValid ? '#d9d9d9' : '#334063',
                    color: !isFormValid ? '#635F5F' : '#fff',
                    float: 'right'
                  }}
                  sx={buttonStyles}
                  onClick={onSubmit}
                  disabled={!isFormValid}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      ) : (
        <div style={loadingStyles}>
          <CircularProgress style={{ color: 'blue' }} />
        </div>
      )}
    </>
  );
};

export default Step6;
