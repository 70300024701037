import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { deployStatusProps } from '../../../types/reduxTypes/deployStatus';

const createReducer = <T extends SliceCaseReducers<deployStatusProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setDeployStatusData(state, action) {
    state.deployStatusData = action.payload;
  },
  setDeployStStatus(state, action) {
    state.deployStStatus = action.payload;
  }
});

export default reducers;
