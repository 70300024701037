import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { DownloadProps } from '../../../types/reduxTypes/download';

const createReducer = <T extends SliceCaseReducers<DownloadProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setDownloadData(state, action) {
    state.downloadData = action.payload;
  },
  setDownloadStatus(state, action) {
    state.downloadStatus = action.payload;
  }
});

export default reducers;
