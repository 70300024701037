import API from '../../../../manager/API';
import * as CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import { Form, Formik, useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Input, TextField } from '@mui/material';

import {
  buttonStyles,
  containerStyles,
  formContainerStyles,
  inputContainerStyles,
  inputStyles,
  lastInputStyles,
  loadingContainerStyles,
  loadingIconStyles,
  resetStyles,
  singleInputStyles,
  titleStyles
} from './Step2.styles';
import { IStep2 } from './Step2.models';
import { validationSchema, snowflakeTestConnectionURL } from './Step2.constants';
import { Cookies } from 'react-cookie';
import { removeLastExtension } from '../../../../helpers/removeExtension';

const Step2: FC<IStep2> = ({ setActiveStep, step2Data, setStep2Data, step2EncryptedData, setStep2EncryptedData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const cookies = new Cookies();

  const formik = useFormik({
    initialValues: {
      user: cookies.get('step2Data') ? cookies.get('step2Data').user : '',
      password: cookies.get('step2Data') ? cookies.get('step2Data').password : '',
      account: cookies.get('step2Data') ? cookies.get('step2Data').account : '',
      warehouse: cookies.get('step2Data') ? cookies.get('step2Data').warehouse : '',
      database: cookies.get('step2Data') ? cookies.get('step2Data').database : '',
      schema:
        cookies.get('step2SchemaValue') && cookies.get('step2SchemaValue').schema === 'PUBLIC'
          ? ''
          : cookies.get('step2SchemaValue')
    },
    validationSchema,
    onSubmit
  });

  const { values, handleChange, handleBlur, errors, touched, resetForm } = formik;

  function onBackClick() {
    setActiveStep(1);
  }

  function onResetClick() {
    formik.setValues({ user: '', password: '', account: '', warehouse: '', database: '', schema: '' });
  }

  async function onSubmit() {
    const ENCRYPTION_SECRET_KEY = `${process.env.ENCRYPTION_SECRET_KEY}`;
    setLoading(true);
    try {
      // Encrypt the values of step2Data

      // Make the API call with encrypted data
      const response = await API.snowflakeTestConnection.getSnowflakeTestConnection(
        snowflakeTestConnectionURL,
        step2EncryptedData
      );

      toast.success(removeLastExtension(response.data.message));
      setActiveStep(3);
      cookies.set('step2Data', JSON.stringify(step2Data), { path: '/' });
      if (values.schema.length > 0) {
        cookies.set('step2SchemaValue', values.schema, { path: '/' });
      } else {
        cookies.remove('step2SchemaValue', { path: '/' });
      }
    } catch (error: any) {
      toast.error(removeLastExtension(error?.response?.data?.message));
    }
    setLoading(false);
  }

  useEffect(() => {
    setIsFormValid(
      (Object.keys(errors).length === 0 && Object.keys(touched).length > 0) ||
        (step2Data.user.length > 0 &&
          step2Data.password.length > 0 &&
          step2Data.account.length > 0 &&
          step2Data.warehouse.length > 0 &&
          step2Data.database.length > 0 &&
          values.user.length > 0 &&
          values.password.length > 0 &&
          values.account.length > 0 &&
          values.warehouse.length > 0 &&
          values.database.length > 0) ||
        (cookies.get('step2Data') &&
          Object.keys(cookies.get('step2Data')).length > 0 &&
          Object.keys(errors).length === 0)
    );
    setStep2Data({ ...values, schema: values.schema || 'PUBLIC' });
  }, [errors, touched, values]);

  useEffect(() => {
    const encryptedStep2Data: Record<string, string> = {};
    for (const prop in step2Data) {
      if (step2Data.hasOwnProperty(prop)) {
        const value = step2Data[prop];
        const encryptedValue = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse('PXae2rdvJWgX1x8C'), {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        encryptedStep2Data[prop] = encryptedValue.toString();
      }
    }
    setStep2EncryptedData(encryptedStep2Data);
  }, [step2Data]);

  return (
    <>
      {!loading ? (
        <Formik initialValues={step2Data} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form style={formContainerStyles}>
            <Grid item xs={12} md={12} style={containerStyles}>
              <Grid item xs={12}>
                <h2 style={titleStyles}>Database Login Credentials</h2>
              </Grid>
              <Grid item xs={12} md={12} style={inputContainerStyles}>
                <Grid item style={inputStyles}>
                  <TextField
                    error={!!(errors.user && touched.user)}
                    id="user"
                    label="Username:"
                    type="text"
                    name="user"
                    value={values.user}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.user && touched.user ? `${errors.user}` : ''}
                    inputProps={{ style: { height: '10px' } }}
                    style={singleInputStyles}
                  />
                </Grid>
                <Grid item style={inputStyles}>
                  <TextField
                    error={!!(errors.password && touched.password)}
                    id="password"
                    label="Password:"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.password && touched.password ? `${errors.password}` : ''}
                    inputProps={{ style: { height: '10px' } }}
                    style={singleInputStyles}
                  />
                </Grid>
                <Grid item style={inputStyles}>
                  <TextField
                    error={!!(errors.account && touched.account)}
                    id="account"
                    label="Account:"
                    type="text"
                    name="account"
                    value={values.account}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.account && touched.account ? `${errors.account}` : ''}
                    inputProps={{ style: { height: '10px' } }}
                    style={singleInputStyles}
                  />
                </Grid>
                <Grid item style={inputStyles}>
                  <TextField
                    error={!!(errors.warehouse && touched.warehouse)}
                    id="warehouse"
                    label="Warehouse:"
                    type="text"
                    name="warehouse"
                    value={values.warehouse}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.warehouse && touched.warehouse ? `${errors.warehouse}` : ''}
                    inputProps={{ style: { height: '10px' } }}
                    style={singleInputStyles}
                  />
                </Grid>
                <Grid item style={inputStyles}>
                  <TextField
                    error={!!(errors.database && touched.database)}
                    id="database"
                    label="Database:"
                    type="text"
                    name="database"
                    value={values.database}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.database && touched.database ? `${errors.database}` : ''}
                    inputProps={{ style: { height: '10px' } }}
                    style={singleInputStyles}
                  />
                </Grid>
                <Grid item style={inputStyles}>
                  <TextField
                    error={!!(errors.schema && touched.schema)}
                    id="schema"
                    label="Schema: (optional)"
                    type="text"
                    name="schema"
                    value={values.schema}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.schema && touched.schema ? `${errors.schema}` : ''}
                    inputProps={{ style: { height: '10px' } }}
                    style={lastInputStyles}
                  />
                  <p onClick={onResetClick} style={resetStyles}>
                    Reset
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    width: '120px',
                    height: '60px',
                    borderRadius: '8px',
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    backgroundColor: '#334063',
                    color: '#fff'
                  }}
                  sx={buttonStyles}
                  onClick={onBackClick}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    width: '120px',
                    height: '60px',
                    borderRadius: '8px',
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    backgroundColor: !isFormValid ? '#d9d9d9' : '#334063',
                    color: !isFormValid ? '#635F5F' : '#fff',
                    float: 'right'
                  }}
                  sx={buttonStyles}
                  type="submit"
                  onClick={onSubmit}
                  disabled={!isFormValid}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      ) : (
        <div style={loadingContainerStyles}>
          <CircularProgress style={loadingIconStyles} />
        </div>
      )}
    </>
  );
};

export default Step2;
