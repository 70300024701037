import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.userModels;

const userModelsData = createSelector([selector], (state) => state);

export default {
  userModelsData
};
