import React, { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardContent, Dialog, DialogTitle, IconButton } from '@mui/material';

import { dispatch, useAppSelector } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { deleteMiddleware, deleteSelector } from '../../store/slices/delete';
import { removeLastExtension } from '../../helpers/removeExtension';

export interface DeleteDialogProps {
  fileName?: any;
  fileSize?: number;
  shapValueWanted?: boolean;
  isModels?: boolean;
  selected?: any;
  setSelected?: any;
}

const DeleteDialog = ({ fileName, fileSize, isModels, selected, setSelected }: DeleteDialogProps) => {
  const userId = localStorage.getItem('userId');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { deleteStatus } = useAppSelector(deleteSelector.deleteData);

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.DeleteModal));
  };

  const onDeleteClick = async () => {
    onModalClose();
    setIsDisabled(true);
    setSelected([]);
    const params = {
      files: Array.isArray(fileName) ? fileName : [fileName]
    };
    const deleteFileUrl = '/blob_file/delete';
    dispatch(deleteMiddleware.deleteFile(params, deleteFileUrl));
  };

  return (
    <Dialog onClose={onModalClose} open>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Are you sure you want to {isModels ? 'drop' : 'delete'}{' '}
        {selected.length > 1 ? 'selected files' : `${removeLastExtension(fileName)}`}?
      </DialogTitle>
      <Card>
        <CardContent sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-around', gap: '16px' }}>
            <Button variant="outlined" fullWidth onClick={onModalClose}>
              Cancel
            </Button>
            <Button
              disabled={isDisabled}
              style={{ color: '#fff', backgroundColor: '#D90429' }}
              variant="contained"
              onClick={onDeleteClick}
              fullWidth
              title="Delete"
              size="large"
            >
              <DeleteIcon />
              {isModels ? 'Drop' : 'Delete'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default DeleteDialog;
