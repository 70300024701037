import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import MainDashboard from '../../components/MainDashboard';
import { DatasourceActions } from '../../components/MainDashboard/Actions/DatasourceActions';
import { data, DatasourceDashboardSearch, headCells } from '../../helpers/dashboardData';
import { dispatch, useAppSelector } from '../../store/hooks';
import { resourcesMiddleware, resourcesSelector } from '../../store/slices/resources';

const ResourcesPage = () => {
  const theme = useTheme();
  const { resourcesData } = useAppSelector(resourcesSelector.resourcesData);

  const getAllResourcesURL = `/datasource/all-files`;
  const [newArray, setNewArray] = useState<any>([]);

  useEffect(() => {
    dispatch(resourcesMiddleware.getResources(getAllResourcesURL));
  }, []);

  useEffect(() => {
    if (resourcesData && resourcesData.length > 0) {
      const newArray1 = resourcesData.map((object: any, index: any) => {
        return { id: index + 1, ...object };
      });
      setNewArray(newArray1);
    } else {
      setNewArray([]);
    }
  }, [resourcesData]);

  return (
    <div
      style={{
        padding: '5px',
        backgroundColor: theme.palette.primary.light
      }}
    >
      <MainDashboard
        isModels={false}
        minimize={false}
        hasUpload
        title="My Datasets"
        data={newArray}
        setNewArray={setNewArray}
        tableHeaders={headCells}
        actions={DatasourceActions}
        searchProps={DatasourceDashboardSearch}
      />
    </div>
  );
};

export default ResourcesPage;
