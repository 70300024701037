import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, CircularProgress, Table, TableContainer, TablePagination, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ArrangementOrder } from 'types';
import { Product } from 'types/customer';

import { RowsPerPage } from '../../helpers/dashboardData';
import { IDashboardProps, Order } from '../../types';
import MainCard from '../MainCard';

import DashboardBody from './DashboardBody';
import DashboardHeader from './DashboardHeader';
import DashboardSearch from './DashboardSearch';
import { dispatch, useAppSelector } from '../../store/hooks';
import { resourcesSelector } from '../../store/slices/resources';
import { userModelsSelector } from '../../store/slices/userModels';
import { fontSize } from '@mui/system';
import { modelSummaryMiddleware, modelSummarySelector } from '../../store/slices/modelSummary';

const MainDashboard = (props: IDashboardProps) => {
  const {
    a,
    setA,
    isComp,
    isModels,
    minimize,
    title,
    data,
    tableHeaders,
    actions,
    hasUpload,
    setActions,
    setNewArray,
    searchProps,
    analyzeManagerTableData,
    setAnalyzeManagerTableData,
    analyzeManagerChartData,
    setAnalyzeManagerChartData,
    rowDataStatus,
    setRowDataStatus,
    setUserModelsArray
  } = props;
  const { modelSummaryData } = useAppSelector(modelSummarySelector.modelSummaryData);

  const [order, setOrder] = useState<ArrangementOrder>(Order.desc);
  const [orderBy, setOrderBy] = useState<string>('creation_time');
  const [selected, setSelected] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<Product[]>(data);
  const [processing, setProcessing] = useState<boolean>(false);
  const [isModelSetupModalOpen, setIsModelSetupModalOpen] = useState<boolean>(false);
  const [pushableObject, setPushableObject] = useState<any>({});
  const [checked, setChecked] = useState<any>(false);
  const [fileSize, setFileSize] = useState<any>(0);
  const [rowData, setRowData] = useState<any>({});
  const [isChecked, setIsChecked] = useState<any>(false);
  const theme = useTheme();
  const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
    const isAsc = orderBy === property && order === Order.asc;

    setOrder(isAsc ? Order.desc : Order.asc);
    setOrderBy(property);
  };

  const [uploadCount, setUploadCount] = React.useState(10);
  const { resourcesStatus } = useAppSelector(resourcesSelector.resourcesData);
  const { userModelsStatus } = useAppSelector(userModelsSelector.userModelsData);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setUploadCount((beforeValue) => (beforeValue >= 100 ? 0 : beforeValue + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    setPushableObject({
      filename: modelSummaryData?.successfully?.model_name,
      model: modelSummaryData?.successfully?.summary?.model_info?.Model,
      recall: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.Recall,
      precision: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.Precision,
      f1_score: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.F1_score,
      accuracy: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.Accuracy,
      auc: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.AUC,
      AIC: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.AIC,
      Adjusted_R2: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.Adjusted_R2,
      BIC: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.BIC,
      MAE: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.MAE,
      MSE: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.MSE,
      R2: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.R2,
      RMSE: modelSummaryData?.successfully?.summary?.Model_performance_metrics?.RMSE,
      Problem_type: modelSummaryData?.successfully?.summary?.model_info?.Problem_type
    });
  }, [modelSummaryData]);

  useEffect(() => {
    if (setAnalyzeManagerTableData && analyzeManagerTableData) {
      if (Object.keys(pushableObject).some((key) => pushableObject[key] !== undefined) && checked) {
        setAnalyzeManagerTableData((prevData: any) => [...prevData, pushableObject]);
      }
    }
  }, [pushableObject]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedId = rows.map((n: any) => n[tableHeaders[0].id]);
      if (setAnalyzeManagerTableData) {
        setAnalyzeManagerTableData(rows);
      }
      if (setAnalyzeManagerChartData) {
        if (analyzeManagerChartData) {
          setAnalyzeManagerChartData((st: any) => {
            rows.map((row: any) => {
              st[row.file_name] = row.chart;
            });
            return st;
          });
        }
      }

      setSelected(newSelectedId);

      return;
    }

    setSelected([]);
    if (setAnalyzeManagerTableData) {
      setAnalyzeManagerTableData([]);
    }
    if (setAnalyzeManagerChartData) {
      setAnalyzeManagerChartData({});
    }
  };

  const handleClick = async (
    event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
    data: any,
    name: string
  ) => {
    setFileSize(data.size);
    const selectedIndex = selected.indexOf(data.file_name);
    if (selectedIndex === -1) {
      setRowData(data);
    }
    let newSelected: string[] = [];
    switch (true) {
      case selectedIndex === -1:
        const userId = localStorage.getItem('userId');
        const modelSummarySummaryUrl = `/model_summary/get_summary?model_name=${data.file_name}`;
        if (isComp) {
          dispatch(modelSummaryMiddleware.getModelSummary(modelSummarySummaryUrl))
            .then((response) => {
              // Assuming response contains modelSummaryData
              if (setAnalyzeManagerChartData && analyzeManagerChartData) {
                setAnalyzeManagerChartData((st: any) => {
                  st[data.file_name] = response?.data?.successfully?.summary?.ROC_AUC_curve?.fpr.map(
                    (val: any, idx: any) => [val, response.data.successfully.summary.ROC_AUC_curve.tpr[idx]]
                  );
                  return st;
                });
              }
            })
            .catch((error) => {
              // Handle any errors from the dispatch call
              console.error('Error in dispatch:', error);
            });
        }
        newSelected = newSelected.concat(selected, data.file_name);
        setChecked(true);
        break;
      case selectedIndex === 0:
        newSelected = newSelected.concat(selected.slice(1));
        if (setAnalyzeManagerTableData) {
          if (analyzeManagerTableData) {
            const filteredArray = analyzeManagerTableData.filter((object) => object.filename !== data.file_name);
            setAnalyzeManagerTableData(filteredArray);
          }
        }
        setChecked(false);
        if (setAnalyzeManagerChartData) {
          if (analyzeManagerChartData) {
            delete analyzeManagerChartData[data.file_name];
          }
        }
        break;
      case selectedIndex === selected.length - 1:
        newSelected = newSelected.concat(selected.slice(0, -1));
        if (setAnalyzeManagerTableData) {
          if (analyzeManagerTableData) {
            const filteredArray = analyzeManagerTableData.filter((object) => object.filename !== data.file_name);
            setAnalyzeManagerTableData(filteredArray);
          }
        }
        if (setAnalyzeManagerChartData) {
          if (analyzeManagerChartData) {
            delete analyzeManagerChartData[data.file_name];
          }
        }
        break;
      case selectedIndex > 0:
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        break;
      default:
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ): void => {
    if (event?.target.value) {
      setRowsPerPage(parseInt(event?.target.value, 10));
    }

    setPage(0);
  };

  return (
    <MainCard
      title={title}
      content={false}
      className="resourcesTitle"
      style={{ fontSize: '34px!important', backgroundColor: theme.palette.primary.light }}
    >
      <span>
        <DashboardSearch
          setChecked={setIsChecked}
          page={page}
          setPage={setPage}
          isModelSetupModalOpen={isModelSetupModalOpen}
          setIsModelSetupModalOpen={setIsModelSetupModalOpen}
          setUserModelsArray={setUserModelsArray}
          rowDataStatus={rowDataStatus}
          setRowDataStatus={setRowDataStatus}
          fileSize={fileSize}
          isModels={isModels}
          tableData={data}
          setNewArray={setNewArray}
          processing={processing}
          setProcessing={setProcessing}
          rows={rows}
          actions={actions}
          setActions={setActions}
          setRows={setRows}
          hasUpload={Boolean(hasUpload)}
          searchProps={searchProps}
          numSelected={selected.length}
          selected={selected}
          setSelected={setSelected}
          rowData={rowData}
          setRowData={setRowData}
          data={data}
        />
        {!isModels && resourcesStatus === 200 ? (
          <>
            <TableContainer>
              {processing && (
                <Box style={{ float: 'right', marginRight: '100px' }} position="relative" display="inline-flex">
                  <CircularProgress style={{ color: 'blue' }} variant="determinate" value={uploadCount} />
                  <Box
                    bottom={0}
                    right={0}
                    top={0}
                    justifyContent="center"
                    left={0}
                    display="flex"
                    alignItems="center"
                    position="absolute"
                  />
                  {/*  {`${Math.round(uploadCount)}%`}*/}
                  {/*</Box>*/}
                </Box>
              )}
              <Table sx={{ minWidth: 750, borderColor: theme.palette.primary.dark }} aria-labelledby="tableTitle">
                <DashboardHeader
                  isComp={isComp}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  tableHeaders={tableHeaders}
                />
                <DashboardBody
                  checked={isChecked}
                  setChecked={setIsChecked}
                  isModels={isModels}
                  processing={processing}
                  setProcessing={setProcessing}
                  rows={rows}
                  order={order}
                  orderBy={orderBy}
                  handleClick={handleClick}
                  selected={selected}
                  setSelected={setSelected}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  tableHeaders={tableHeaders}
                  actions={actions}
                  setActions={setActions}
                />
              </Table>
            </TableContainer>

            <TablePagination
              style={{ marginBottom: '50px' }}
              rowsPerPageOptions={RowsPerPage}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : isModels && userModelsStatus === 200 ? (
          <>
            <TableContainer>
              {/*{processing && (*/}
              {/*  <Box style={{ float: 'right', marginRight: '100px' }} position="relative" display="inline-flex">*/}
              {/*    <CircularProgress style={{ color: 'blue' }} variant="determinate" value={uploadCount} />*/}
              {/*    <Box*/}
              {/*      bottom={0}*/}
              {/*      right={0}*/}
              {/*      top={0}*/}
              {/*      justifyContent="center"*/}
              {/*      left={0}*/}
              {/*      display="flex"*/}
              {/*      alignItems="center"*/}
              {/*      position="absolute"*/}
              {/*    >*/}
              {/*      {`${Math.round(uploadCount)}%`}*/}
              {/*    </Box>*/}
              {/*  </Box>*/}
              {/*)}*/}
              <Table sx={{ minWidth: 750, borderColor: theme.palette.primary.dark }} aria-labelledby="tableTitle">
                <DashboardHeader
                  isComp={isComp}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  tableHeaders={tableHeaders}
                />
                <DashboardBody
                  checked={checked}
                  setChecked={setChecked}
                  isModelSetupModalOpen={isModelSetupModalOpen}
                  setIsModelSetupModalOpen={setIsModelSetupModalOpen}
                  a={a}
                  setA={setA}
                  isModels={isModels}
                  processing={processing}
                  setProcessing={setProcessing}
                  rows={rows}
                  order={order}
                  orderBy={orderBy}
                  handleClick={handleClick}
                  selected={selected}
                  setSelected={setSelected}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  tableHeaders={tableHeaders}
                  actions={actions}
                  setActions={setActions}
                />
              </Table>
            </TableContainer>

            <TablePagination
              style={{ marginBottom: '50px' }}
              rowsPerPageOptions={RowsPerPage}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}
          >
            <CircularProgress style={{ color: 'blue' }} />
          </div>
        )}
      </span>
      {/*{minimize && (*/}
      {/*  <div style={{ textAlign: 'center' }}>*/}
      {/*    <Button*/}
      {/*      onClick={() => {*/}
      {/*        setShowDashboard((prevState) => !prevState);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {showDashboard ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </MainCard>
  );
};

export default MainDashboard;
