import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import Layout from '../components/Layout';
import Router from '../router/Router';

import 'react-toastify/dist/ReactToastify.css';
import { dispatch, useAppSelector } from '../store/hooks';
import { authMiddleware, authSelector } from '../store/slices/auth';
import { CircularProgress } from '@mui/material';

const App = () => {
  const { authData, authStatus } = useAppSelector(authSelector.authData);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token !== null && token) {
      localStorage.setItem('accessToken', token);

      dispatch(authMiddleware.getAuthData());
    }

    if (token === null) {
      if (localStorage.getItem('accessToken')) {
        dispatch(authMiddleware.getAuthData());
      }
    }
  }, []);

  function setCookie(name:any, value:any, days:any) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    const cookieString = `${name}=${value};expires=${expires.toUTCString()}`;
    document.cookie = cookieString;
  }

  //123

  return (
    <>
      {authStatus === 200 ? (
        authData?.user_info?.user_me_data.hasOwnProperty('userId') ? (
          <Layout>
            <Router />
            <ToastContainer />
          </Layout>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100vw',
              height: '100vh',
            }}
          >
            YOU ARE NOT AUTHORIZED
          </div>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
          }}
        >
          <CircularProgress style={{ color: 'blue' }} />
        </div>
      )}
    </>
  );

};

export default App;
