import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.actionsPreview;

const actionsPreviewData = createSelector([selector], (state) => state);

export default {
  actionsPreviewData
};
