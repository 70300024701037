import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setModelViewData, setModelViewStatus } = slice.actions;

const getModelView = (url: any) => async () => {
  try {
    const response = await API.modelView.getModelView(url);
    dispatch(setModelViewData(response.data));
    dispatch(setModelViewStatus(response.status));
  } catch (error) {}
};

export default {
  getModelView
};
