import { styled } from '@mui/material/styles';

const ModelSummaryStyled = styled('div')(() => ({
  '.dialog-title': {
    textAlign: 'center',
    position: 'relative'
  },
  '.close-button': {
    position: 'absolute',
    right: 20,
    top: 10
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '20px'
  },
  td: {
    border: '1px solid black',
    padding: '4px'
  },
  '.rotated-cell': {
    transform: 'rotate(-90deg)',
    width: '50px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '.true-cell': {
    padding: '50px 10px',
    backgroundColor: '#ea9999',
    color: 'white',
    fontWeight: '600'
  },
  '.false-cell': {
    padding: '20px 10px',
    backgroundColor: '#b6d7a8',
    color: 'white',
    fontWeight: '600'
  },
  '.neutral-cell': {
    backgroundColor: 'lightblue'
  }
}));

export default ModelSummaryStyled;
