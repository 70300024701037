import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setSnowflakeTestConnectionData, setSnowflakeTestConnectionStatus } = slice.actions;

const getSnowflakeTestConnection = (url: any, params: any) => async () => {
  //todo
  try {
    const response = await API.snowflakeTestConnection.getSnowflakeTestConnection(url, params);
    dispatch(setSnowflakeTestConnectionData(response.data));
    dispatch(setSnowflakeTestConnectionStatus(response.status));
  } catch (error: any) {
    dispatch(setSnowflakeTestConnectionData(error?.response?.data));
    dispatch(setSnowflakeTestConnectionStatus(error?.response?.status));
  }
};

export default {
  getSnowflakeTestConnection
};
