import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';
import { toast } from 'react-toastify';
import { removeLastExtension } from '../../../helpers/removeExtension';
import { modalsMiddleware } from '../modals';
import { ModalName } from '../../../types/modals';

export const { setViewUrlData, setViewUrlStatus } = slice.actions;

const getViewUrl = (url: any) => async () => {
  try {
    const response = await API.viewUrl.getViewUrl(url);
    dispatch(setViewUrlData(response.data));
    dispatch(setViewUrlStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    toast.error(removeLastExtension(error.response.data.message));
    dispatch(modalsMiddleware.closeModal(ModalName.urlDialog));
    dispatch(setViewUrlData({}));
    dispatch(setViewUrlStatus(0));
    return { status: error.response.status, data: error.response.data };
  }
};

export default {
  getViewUrl
};
