import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const snowflakeDataPushStatusManager = {
  getSnowflakeDataPushStatus(url: any) {
    return axiosInstance.v2.get(url);
  }
};

export default snowflakeDataPushStatusManager;