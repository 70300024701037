import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setResourcesData, setResourcesStatus } = slice.actions;

const getResources = (url: any) => async () => {
  try {
    const response = await API.resources.getResources(url);
    dispatch(setResourcesData(response.data));
    dispatch(setResourcesStatus(response.status));
  } catch (error) {}
};

export default {
  getResources
};
