import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ModelSummaryProps } from '../../../types/reduxTypes/modelSummary';

const createReducer = <T extends SliceCaseReducers<ModelSummaryProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setModelSummaryData(state, action) {
    state.modelSummaryData = action.payload;
  },
  setModelSummaryStatus(state, action) {
    state.modelSummaryStatus = action.payload;
  }
});

export default reducers;
