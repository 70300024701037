import React, { useEffect, useState } from 'react';

import { PlusIconButton } from '../../components/Buttons';
import DatasourceMenu from '../../components/DatasourceMenu';
import { DatasourceActions } from '../../components/MainDashboard/Actions/DatasourceActions';
import MainDashboard from '../../components/MainDashboard/index';
import { DatasourceDashboardSearch, headCells } from '../../helpers/dashboardData';
import { dispatch, useAppSelector } from '../../store/hooks';
import { resourcesMiddleware, resourcesSelector } from '../../store/slices/resources';

const DatasourceManager = () => {
  const [datasourceCount, setDatasourceCount] = useState<number[]>([]);
  const [countError, setCountError] = useState<boolean>(false);

  const { resourcesData } = useAppSelector(resourcesSelector.resourcesData);
//todo

  // const params = {
  //   container_name: `containerfromblobservice${localStorage.getItem('userId')}`
  // };
  //
  // useEffect(() => {
  //   dispatch(resourcesMiddleware.getResources(params));
  // }, []);
  let newArray = resourcesData.map((object: any, index: any) => {
    return { id: index + 1, ...object };
  });

  const onPlusClick = () => {
    if (!datasourceCount.length) {
      setDatasourceCount([0]);
    } else if (datasourceCount.length < 10) {
      setDatasourceCount((prev) => [...prev, datasourceCount[datasourceCount.length - 1] + 1]);
    }

    if (datasourceCount.length === 9) {
      setCountError(true);
    }
  };

  const deleteDatasource = (element: number) => {
    setDatasourceCount([
      ...datasourceCount.slice(0, datasourceCount.indexOf(element)),
      ...datasourceCount.slice(datasourceCount.indexOf(element) + 1)
    ]);
  };

  return (
    <>
      <div>
        <PlusIconButton handlePlusButtonClick={onPlusClick} title="Add a new dataset" error={countError} />
      </div>
      {datasourceCount &&
        datasourceCount.map((el) => (
          <div key={el}>
            <DatasourceMenu element={el} deleteDatasource={deleteDatasource} />
          </div>
        ))}
      <div style={{ marginTop: '16px' }}>
        <MainDashboard
          isModels={false}
          minimize
          hasUpload
          title="Your Datasets"
          data={newArray}
          tableHeaders={headCells}
          actions={DatasourceActions}
          searchProps={DatasourceDashboardSearch}
        />
      </div>
    </>
  );
};

export default DatasourceManager;
