import React, { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import { dispatch, useAppSelector } from 'store/hooks';
import { modalsMiddleware, modalsSelector } from 'store/slices/modals';

const useStyles = makeStyles({
  snackbar: {
    width: '30wv',
    '@media (min-width: 780px)': {
      width: '40wv'
    }
  },
  alertBanner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    '& .MuiAlert-icon': {
      fontSize: 30
    }
  }
});

export const ToastNotification = React.forwardRef(() => {
  const classes = useStyles();
  const toastNotificationPopUp = useAppSelector(modalsSelector.toastNotificationPopUp);

  const onClose = useCallback(() => {
    dispatch(
      modalsMiddleware.setToastNotificationPopUpState({
        open: false,
        props: {}
      })
    );
  }, []);

  return (
    <Snackbar
      open={toastNotificationPopUp.open}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={classes.snackbar}
    >
      <Alert
        variant="filled"
        severity={toastNotificationPopUp.props.severityType}
        className={classes.alertBanner}
        action={
          <IconButton aria-label="close" size="large" onClick={onClose}>
            <CloseIcon fontSize="inherit" sx={{ color: (theme) => theme.palette.common.white }} />
          </IconButton>
        }
      >
        <Typography sx={{ fontWeight: 600, color: (theme) => theme.palette.common.white }}>
          {toastNotificationPopUp.props.description}
        </Typography>
      </Alert>
    </Snackbar>
  );
});
