import axios, { AxiosRequestConfig } from 'axios';

export const Axios = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const baseURLV2 = process.env.REACT_APP_BASE_URL_V2;

  const v1 = axios.create({ baseURL: `${baseURL}` });
  const v2 = axios.create({ baseURL: `${baseURLV2}` });

  v1.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const requestConfig = config;

    requestConfig.headers = {
      Authorization: `${localStorage.getItem('accessToken')}`,
      // RefreshToken: 'Panyan'
    };

    return requestConfig;
  });

  v2.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const requestConfig = config;

    requestConfig.headers = {
      Authorization: `${localStorage.getItem('accessToken')}`,
      // RefreshToken: 'Panyan'
    };

    return requestConfig;
  });

  return { v1, v2 };
};
