import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { viewUrlProps } from '../../../types/reduxTypes/viewUrl';

const createReducer = <T extends SliceCaseReducers<viewUrlProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setViewUrlData(state, action) {
    state.viewUrlData = action.payload;
  },
  setViewUrlStatus(state, action) {
    state.viewUrlStatus = action.payload;
  }
});

export default reducers;
