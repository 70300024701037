export const FieldsValidator = (fields: { label: string; data: any }[]) => {
  const errorFields: string[] = [];

  fields.forEach((field) => {
    if (field.data.length === 0) {
      errorFields.push(field.label);
    }
  });

  return errorFields;
};
