import { Axios } from '../axiosInstance';

import { IUsersReqParams } from './usersManagerTypes';

const baseURL = '/user';
const axiosInstance = Axios();

const usersManager = {
  getUser(params: IUsersReqParams) {
    return axiosInstance.v2.get(`${baseURL}/${params.id}`);
  },

  addUser(params: IUsersReqParams) {
    return axiosInstance.v2.post(`${baseURL}/${params.id}/`, params.form);
  }
};

export default usersManager;
