import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';
import { toast } from 'react-toastify';
import { removeLastExtension } from '../../../helpers/removeExtension';

export const { setUploadModelData, setUploadModelStatus } = slice.actions;

const uploadModel = (params: any, url: any) => async () => {
  try {
    const response = await API.uploadModel.uploadModel(params, url);
    dispatch(setUploadModelData(response.data));
    dispatch(setUploadModelStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    toast.error(removeLastExtension(error.response.data.message));
  }
};

export default {
  uploadModel
};
