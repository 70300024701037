import React from 'react';
import { useAppSelector } from 'store/hooks';
import { modalsSelector } from 'store/slices/modals';

import { ToastNotification } from '../ToastNotification';

export const ToastNotificationsController = () => {
  const toastNotificationPopUp = useAppSelector(modalsSelector.toastNotificationPopUp);

  return toastNotificationPopUp.open === false ? null : <ToastNotification />;
};
