import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { UserModelsProps } from '../../../types/reduxTypes/userModels';

const createReducer = <T extends SliceCaseReducers<UserModelsProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setUserModelsData(state, action) {
    state.userModelsData = action.payload;
  },
  setUserModelsStatus(state, action) {
    state.userModelsStatus = action.payload;
  }
});

export default reducers;
