import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setSnowflakeDataPushStatusData, setSnowflakeDataPushStatus } = slice.actions;

const getSnowflakeDataPushStatus = (url: any) => async () => {
  //todo
  try {
    const response = await API.snowflakeDataPushStatus.getSnowflakeDataPushStatus(url);
    dispatch(setSnowflakeDataPushStatusData(response.data));
    dispatch(setSnowflakeDataPushStatus(response.status));
  } catch (error: any) {
    dispatch(setSnowflakeDataPushStatusData(error?.response?.data));
    dispatch(setSnowflakeDataPushStatus(error?.response?.status));
  }
};

export default {
  getSnowflakeDataPushStatus
};
