import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import { dispatch } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { IconInfoCircle } from '@tabler/icons';
import { classificationMetricsOptions, layerOptions, regressionMetricsOptions } from '../../helpers/managers';
import { datasourceUsersMiddleware } from '../../store/slices/datasourceUsers';
import { isNaN } from 'formik';

export interface ModelInfoDialogProps {
  modelInfo: string;
  problemType: string;
  params: any;
  setParams: any;
  paramsBoost: any;
  setParamsBoost: any;
  boostSaveObject: any;
  setBoostSaveObject: any;
  boostReset: any;
}

const XGBoostDialog = ({
  modelInfo,
  problemType,
  params,
  setParams,
  paramsBoost,
  setParamsBoost,
  boostSaveObject,
  setBoostSaveObject,
  boostReset
}: ModelInfoDialogProps) => {
  const theme = useTheme();

  const initialValues = {
    xgBoostToggle: true,
    metric: problemType === 'classification' ? classificationMetricsOptions[0] : regressionMetricsOptions[0],
    time: 0.1,
    xgBoostLearningRate: 0.3,
    xgBoostNumberOfTrees: 100,
    xgBoostMaximumDepth: 6,
    xgBoostMaximumLeafNodes: 10
  };
  const [xgBoostToggle, setXGBoostToggle] = useState(boostSaveObject.xgBoostToggle ?? initialValues.xgBoostToggle);
  const [xgBoostMetrics, setXGBoostMetrics] = useState(boostSaveObject.metric ?? initialValues.metric);
  const [xgBoostTime, setXGBoostTime] = useState(boostSaveObject.time ?? initialValues.time);
  const [xgBoostLearningRate, setXGBoostLearningRate] = useState(
    boostSaveObject.xgBoostLearningRate ?? initialValues.xgBoostLearningRate
  );
  const [xgBoostNumberOfTrees, setXGBoostNumberOfTrees] = useState(
    boostSaveObject.xgBoostNumberOfTrees ?? initialValues.xgBoostNumberOfTrees
  );
  const [xgBoostMaximumDepth, setXGBoostMaximumDepth] = useState(
    boostSaveObject.xgBoostMaximumDepth ?? initialValues.xgBoostMaximumDepth
  );
  const [xgBoostMaximumLeafNodes, setXGBoostMaximumLeafNodes] = useState(
    boostSaveObject.xgBoostMaximumLeafNodes ?? initialValues.xgBoostMaximumLeafNodes
  );
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (xgBoostToggle) {
      if (xgBoostMetrics === undefined || !xgBoostMetrics || isNaN(xgBoostTime)) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    } else {
      if (
        isNaN(xgBoostLearningRate) ||
        isNaN(xgBoostNumberOfTrees) ||
        isNaN(xgBoostMaximumDepth) ||
        isNaN(xgBoostMaximumLeafNodes)
      ) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    }
  }, [
    xgBoostMetrics,
    xgBoostTime,
    xgBoostLearningRate,
    xgBoostNumberOfTrees,
    xgBoostMaximumDepth,
    xgBoostMaximumLeafNodes,
    xgBoostToggle
  ]);

  const handleReset = () => {
    // setXGBoostToggle(initialValues.xgBoostToggle)
    setXGBoostMetrics(initialValues.metric);
    setXGBoostTime(initialValues.time);
    setXGBoostLearningRate(initialValues.xgBoostLearningRate);
    setXGBoostNumberOfTrees(initialValues.xgBoostNumberOfTrees);
    setXGBoostMaximumDepth(initialValues.xgBoostMaximumDepth);
    setXGBoostMaximumLeafNodes(initialValues.xgBoostMaximumLeafNodes);
    boostReset({});
  };

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.XGBoostInfoModal));
  };

  const handleXGBoostToggle = () => {
    setXGBoostToggle(!xgBoostToggle);
  };

  const handleXGBoostMetrics = (e: any) => {
    const value = e.target.outerText;
    setXGBoostMetrics(value);
  };

  const handleXGBoostMaxTime = (e: any) => {
    const value = parseFloat(e.target.value);
    setXGBoostTime(value);
  };

  const handleXGBoostLearningRate = (e: any) => {
    let value = parseFloat(e.target.value);
    value = Math.min(Math.max(value, 0.00001), 0.99999);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setXGBoostLearningRate(value);
  };

  const handleXGBoostNumberOfTrees = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 10), 500);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setXGBoostNumberOfTrees(value);
  };

  const handleXGBoostMaxDepth = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 3), 10);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setXGBoostMaximumDepth(value);
  };

  const handleXGBoostMaxNodes = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 3), 30);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setXGBoostMaximumLeafNodes(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === '.') {
      e.preventDefault();
    }
  };

  const onSaveClick = () => {
    setBoostSaveObject({
      xgBoostToggle: xgBoostToggle,
      metric: typeof xgBoostMetrics === 'object' ? xgBoostMetrics.label : xgBoostMetrics,
      time: xgBoostTime,
      xgBoostLearningRate: xgBoostLearningRate,
      xgBoostNumberOfTrees: xgBoostNumberOfTrees,
      xgBoostMaximumDepth: xgBoostMaximumDepth,
      xgBoostMaximumLeafNodes: xgBoostMaximumLeafNodes
    });
    if (xgBoostToggle) {
      setParamsBoost(() => ({
        ...params,
        models: {
          [modelInfo]: {
            one_model: !xgBoostToggle,
            time: xgBoostTime,
            metric: typeof xgBoostMetrics === 'object' ? xgBoostMetrics.label : xgBoostMetrics
          }
        }
      }));
    } else {
      setParamsBoost(() => ({
        ...params,
        models: {
          [modelInfo]: {
            one_model: !xgBoostToggle,
            learning_rate: xgBoostLearningRate,
            n_estimators: xgBoostNumberOfTrees,
            max_depth: xgBoostMaximumDepth,
            max_leaves: xgBoostMaximumLeafNodes
          }
        }
      }));
    }
    onModalClose();
  };

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <div style={{ maxHeight: '100%' }}>
        <DialogTitle style={{ textAlign: 'center', position: 'relative', cursor: 'move' }} id="draggable-dialog-title">
          {`${modelInfo} Model Config`}
          <IconButton
            onClick={onModalClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <CardContent sx={{ pt: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
              <Tooltip title="The One Model  trains a specific model based on the parameters specified by the user.">
                <div
                  style={{
                    marginTop: '-15px'
                  }}
                >
                  <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                </div>
              </Tooltip>
              <div style={{ color: '#000', fontSize: '22px', marginRight: '10px' }}>One Model</div>
              <div
                style={{
                  width: '70px',
                  height: '35px',
                  borderRadius: '35px',
                  backgroundColor: xgBoostToggle ? 'green' : 'green',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  cursor: 'pointer',
                  padding: '0 5px',
                  boxSizing: 'border-box'
                }}
                onClick={handleXGBoostToggle}
              >
                <div
                  style={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    transform: xgBoostToggle ? 'translateX(38px)' : 'translateX(0)',
                    transition: 'transform 0.2s ease-in-out',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer'
                  }}
                />
              </div>
              <div style={{ color: '#000', fontSize: '22px', marginLeft: '10px' }}>Best Model</div>
              <Tooltip title="The  Best Model gives the leading model determined by the algorithm based on the Metrics  and Time specified by the user.">
                <div
                  style={{
                    marginTop: '-15px'
                  }}
                >
                  <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                </div>
              </Tooltip>
            </div>
            {xgBoostToggle ? (
              <div>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Metrics</p>
                      <Tooltip title="Select one of this parameters, which should be taken into account for model convergence.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <Autocomplete
                      style={{ width: '100%' }}
                      options={
                        problemType === 'classification' ? classificationMetricsOptions : regressionMetricsOptions
                      }
                      value={typeof xgBoostMetrics === 'object' ? xgBoostMetrics.label : xgBoostMetrics}
                      onChange={handleXGBoostMetrics}
                      renderInput={(params) => <TextField {...params} label="" />}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Time</p>
                      <Tooltip title="Select how long you are able to expect for completion of model training process in hours.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      value={xgBoostTime}
                      onChange={handleXGBoostMaxTime}
                      placeholder="Select Time"
                      inputProps={{ type: 'number', min: 0.1 }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Learning Rate</p>
                      <Tooltip title="Hyperparameter,  that defines the adjustment in the weights of network with respect to the gradient descent.Please enter 0-1 value">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleXGBoostLearningRate}
                      type="text"
                      // onKeyDown={handleKeyDown}
                      value={xgBoostLearningRate}
                      inputProps={{
                        min: 0.00001,
                        step: 0.00001,
                        type: 'number',
                        max: 0.99999
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Number of Trees</p>
                      <Tooltip title="Specifies the number of trees in the model">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleXGBoostNumberOfTrees}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={xgBoostNumberOfTrees}
                      inputProps={{
                        min: 10,
                        step: 1,
                        type: 'number',
                        max: 500
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Maximum depth </p>
                      <Tooltip title="Specifies the maximum depth of each tree in the model">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleXGBoostMaxDepth}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={xgBoostMaximumDepth}
                      inputProps={{
                        min: 3,
                        step: 1,
                        type: 'number',
                        max: 10
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Maximum leaf nodes</p>
                      <Tooltip title="Specifies the maximum number of terminal nodes or leaves in a tree.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleXGBoostMaxNodes}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={xgBoostMaximumLeafNodes}
                      inputProps={{
                        min: 1,
                        step: 1,
                        type: 'number',
                        max: 500
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    margin: '20px auto',
                    height: '52px',
                    background: theme.palette.warning.dark,
                    '&:hover': { background: theme.palette.warning.main },
                    color: 'grey.900'
                  }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: disableSave ? '#CCCCCC' : '#202090',
                    color: disableSave ? '#666666' : '#fff'
                  }}
                  sx={{ margin: '20px auto', height: '52px' }}
                  onClick={onSaveClick}
                  disabled={disableSave}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Dialog>
  );
};

export default XGBoostDialog;
