import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { uploadDatasetProps } from '../../../types/reduxTypes/uploadDataset';

const createReducer = <T extends SliceCaseReducers<uploadDatasetProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setUploadDatasetData(state, action) {
    state.uploadDatasetData = action.payload;
  },
  setUploadDatasetStatus(state, action) {
    state.uploadDatasetStatus = action.payload;
  }
});

export default reducers;
