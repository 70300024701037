import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.modelTrain;

const ModelTrainData = createSelector([selector], (state) => state);

export default {
  ModelTrainData
};
