import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.modelAddingStatus;

const modelAddingStatusData = createSelector([selector], (state) => state);

export default {
  modelAddingStatusData
};
