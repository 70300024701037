import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ResourcesProps } from '../../../types/reduxTypes/resources';

const createReducer = <T extends SliceCaseReducers<ResourcesProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setResourcesData(state, action) {
    state.resourcesData = action.payload;
  },
  setResourcesStatus(state, action) {
    state.resourcesStatus = action.payload;
  }
});

export default reducers;
