import { combineReducers } from 'redux';

import { viewsSlice } from './slices/menu';
import { modalsSlice } from './slices/modals';
import { modelsSlice } from './slices/models';
import { userSlice } from './slices/user';
import { resourcesSlice } from './slices/resources';
import { authSlice } from './slices/auth';
import { healthSlice } from './slices/health';
import { uploadSlice } from './slices/upload';
import { downloadSlice } from './slices/download';
import { deleteSlice } from './slices/delete';
import { uploadStatusSlice } from './slices/uploadStatus';
import { summarySlice } from './slices/summary';
import { datasourceColumnsSlice } from './slices/datasourceColumns';
import { datasourceUsersSlice } from './slices/datasourceUsers';
import { modelTrainSlice } from './slices/modelTrain';
import { trainStatusSlice } from './slices/trainStatus';
import { trainProgressSlice } from './slices/trainProgress';
import { userModelsSlice } from './slices/userModels';
import { predictionSlice } from './slices/prediction';
import { predictionProgressSlice } from './slices/predictionProgress';
import { predictionStatusSlice } from './slices/predictionStatus';
import { modelListSlice } from './slices/modelList';
import { modelViewSlice } from './slices/modelView';
import { modelSettingsSlice } from './slices/modelSettings';
import { modelSummarySlice } from './slices/modelSummary';
import { previewSlice } from './slices/preview';
import { storageSlice } from './slices/storage';
import { actionsPreviewSlice } from './slices/actionsPreview';
import { previewStatusSlice } from './slices/previewStatus';
import { deploySlice } from './slices/deploy';
import { deployStatusSlice } from './slices/deployStatus';
import { viewUrlSlice } from './slices/viewUrl';
import { undeploySlice } from './slices/undeploy';
import { snowflakeTestConnectionSlice } from './slices/snowflakeTestConnection';
import { snowflakeGetQuerySlice } from './slices/snowflakeGetQuery';
import { snowflakePushDataToAzureSlice } from './slices/snowflakePushdataToAzure';
import { snowflakeDataPushStatusSlice } from './slices/snowflakeDataPushStatus';
import { uploadModelSlice } from './slices/uploadModel';
import { modelAddingSlice } from './slices/modelAdding';
import { uploadDatasetSlice } from './slices/uploadDataset';
import { uploadModelStatusSlice } from './slices/uploadModelStatus';
import { modelAddingStatusSlice } from './slices/modelAddingStatus';
import { modelSummaryArraysSlice } from './slices/modelSummaryArrays';
import { modelSummarySaveSlice } from './slices/modelSummarySave';

const reducer = combineReducers({
  views: viewsSlice.reducer,
  modals: modalsSlice.reducer,
  models: modelsSlice.reducer,
  user: userSlice.reducer,
  resources: resourcesSlice.reducer,
  snowflakeTestConnection: snowflakeTestConnectionSlice.reducer,
  snowflakeGetQuery: snowflakeGetQuerySlice.reducer,
  snowflakePushDataToAzure: snowflakePushDataToAzureSlice.reducer,
  snowflakeDataPushStatus: snowflakeDataPushStatusSlice.reducer,
  auth: authSlice.reducer,
  health: healthSlice.reducer,
  upload: uploadSlice.reducer,
  uploadStatus: uploadStatusSlice.reducer,
  uploadModelStatus: uploadModelStatusSlice.reducer,
  modelAddingStatus: modelAddingStatusSlice.reducer,
  modelAdding: modelAddingSlice.reducer,
  download: downloadSlice.reducer,
  delete: deleteSlice.reducer,
  summary: summarySlice.reducer,
  datasourceColumns: datasourceColumnsSlice.reducer,
  datasourceUsers: datasourceUsersSlice.reducer,
  modelTrain: modelTrainSlice.reducer,
  trainStatus: trainStatusSlice.reducer,
  trainProgress: trainProgressSlice.reducer,
  userModels: userModelsSlice.reducer,
  prediction: predictionSlice.reducer,
  predictionProgress: predictionProgressSlice.reducer,
  predictionStatus: predictionStatusSlice.reducer,
  modelList: modelListSlice.reducer,
  modelView: modelViewSlice.reducer,
  modelSettings: modelSettingsSlice.reducer,
  modelSummary: modelSummarySlice.reducer,
  modelSummaryArrays: modelSummaryArraysSlice.reducer,
  modelSummarySave: modelSummarySaveSlice.reducer,
  preview: previewSlice.reducer,
  storage: storageSlice.reducer,
  actionsPreview: actionsPreviewSlice.reducer,
  previewStatus: previewStatusSlice.reducer,
  deploy: deploySlice.reducer,
  undeploy: undeploySlice.reducer,
  deployStatus: deployStatusSlice.reducer,
  viewUrl: viewUrlSlice.reducer,
  uploadModel: uploadModelSlice.reducer,
  uploadDataset: uploadDatasetSlice.reducer
});

export default reducer;
