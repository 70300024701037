import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Slider,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Select, { components } from 'react-select';
import { ProblemTypes } from '../../types/components/managers';
import { Type } from '../../types/customer';
import Checkboxes, { ICheckboxOption } from '../Checkboxes';
import Progress from '../Progress';
import { dispatch, useAppSelector } from '../../store/hooks';
import { datasourceUsersMiddleware, datasourceUsersSelector } from '../../store/slices/datasourceUsers';
import { datasourceColumnsMiddleware, datasourceColumnsSelector } from '../../store/slices/datasourceColumns';
import { modelTrainMiddleware } from '../../store/slices/modelTrain';
import { trainStatusMiddleware } from '../../store/slices/trainStatus';
import { toast } from 'react-toastify';
import { trainProgressMiddleware } from '../../store/slices/trainProgress';
import { userModelsMiddleware } from '../../store/slices/userModels';
import { modelListMiddleware, modelListSelector } from '../../store/slices/modelList';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { storageMiddleware } from '../../store/slices/storage';
import API from '../../manager/API';
import { data } from '../../helpers/dashboardData';
import { IconInfoCircle } from '@tabler/icons';
import { classificationMetricsOptions, layerOptions, regressionMetricsOptions } from '../../helpers/managers';
import { removeLastExtension } from '../../helpers/removeExtension';

interface DatasetMenuProps {
  element: number;
  deleteDataset: (element: number) => void;
}

type OptionType = {
  label: string;
  value: string;
};

// eslint-disable-next-line max-lines-per-function
const DatasetMenu = ({ element, deleteDataset }: DatasetMenuProps) => {
  const theme = useTheme();
  const userId = localStorage.getItem('userId');
  const [indepColumnOptionsWithId, setIndepColumnOptionsWithId] = useState<OptionType[]>([]);
  const [progress, setProgress] = React.useState(0);
  const [progressLR, setProgressLR] = React.useState(0);
  const [progressBoost, setProgressBoost] = React.useState(0);
  const [progressCatboost, setProgressCatboost] = React.useState(0);
  const [progressRandom, setProgressRandom] = React.useState(0);
  const [trainModels, setTrainModels] = useState<ICheckboxOption[]>([]);
  const [modelList, setModelList] = useState<any>([]);
  const [training, setTraining] = useState<boolean>(false);
  const [trainingLR, setTrainingLR] = useState<boolean>(false);
  const [trainingBoost, setTrainingBoost] = useState<boolean>(false);
  const [trainingCatboost, setTrainingCatboost] = useState<boolean>(false);
  const [trainingRandom, setTrainingRandom] = useState<boolean>(false);
  const [problemType, setProblemType] = useState<Type>('classification');
  const [inDepColumnValueArray, setInDepColumnValueArray] = useState<any[]>([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [expandedModel, setExpandedModel] = useState<string | null>(null);
  const [nnCheckedValue, setNnCheckedValue] = useState<string>('');
  const [LrCheckedValue, setLrCheckedValue] = useState<string>('');
  const [boostCheckedValue, setBoostCheckedValue] = useState<string>('');
  const [catboostCheckedValue, setCatboostCheckedValue] = useState<string>('');
  const [randomCheckedValue, setRandomCheckedValue] = useState<string>('');
  const [checkAllOptions, setCheckAllOptions] = useState<boolean>(true);
  const [depColumnOptionsWithIds, setDepColumnOptionsWithIds] = useState<any>([]);
  const [boostSaveObject, setBoostSaveObject] = useState<any>({});
  const [catboostSaveObject, setCatboostSaveObject] = useState<any>({});
  const [randomSaveObject, setRandomSaveObject] = useState<any>({});
  const [logisticSaveObject, setLogisticSaveObject] = useState<any>({});
  const [nnSaveObject, setNNSaveObject] = useState<any>({});

  const { datasourceUsersData } = useAppSelector(datasourceUsersSelector.DatasourceUsersData);

  const { modelListData } = useAppSelector(modelListSelector.modelListData);
  const { datasourceColumnsData } = useAppSelector(datasourceColumnsSelector.DatasourceColumnsData);
  const getDatasourceUsersURL = `/datasource/user_datasources`;
  //ggghjkkkkk
  const getModelListURL = '/model_settings/model_list';
  const [params, setParams] = useState<any>({
    train_portion: 0.8,
    features: [],
    blob_name: '',
    target_name: '',
    task: 'classification',
    models: {
      NN: {
        one_model: false,
        time: 1,
        metric:
          problemType === 'classification' ? classificationMetricsOptions[0].label : regressionMetricsOptions[0].label,
        optimizer: 'Adam',
        batch_norm: true,
        layers: layerOptions[2].value,
        epochs: 2,
        nodes: [256, 256, 256],
        activations: ['ReLU', 'ReLU', 'ReLU'],
        dropout_rates: [0.1, 0.1, 0.1],
        learning_rate: 0.001
      }
    }
  });

  const [paramsLR, setParamsLR] = useState<any>({
    train_portion: 0.8,
    features: [],
    blob_name: '',
    target_name: '',
    task: 'classification',
    models: {
      LogisticRegression: {
        one_model: false,
        time: 0.1,
        metric:
          problemType === 'classification' ? classificationMetricsOptions[0].label : regressionMetricsOptions[0].label
      }
    }
  });

  const [paramsBoost, setParamsBoost] = useState<any>({
    train_portion: 0.8,
    features: [],
    blob_name: '',
    target_name: '',
    task: 'classification',
    models: {
      XGBoost: {
        one_model: false,
        time: 0.1,
        metric:
          problemType === 'classification' ? classificationMetricsOptions[0].label : regressionMetricsOptions[0].label
      }
    }
  });

  const [paramsCatboost, setParamsCatboost] = useState<any>({
    train_portion: 0.8,
    features: [],
    blob_name: '',
    target_name: '',
    task: 'classification',
    models: {
      CatBoost: {
        one_model: false,
        time: 0.1,
        metric:
          problemType === 'classification' ? classificationMetricsOptions[0].label : regressionMetricsOptions[0].label
      }
    }
  });

  const [paramsRandom, setParamsRandom] = useState<any>({
    train_portion: 0.8,
    features: [],
    blob_name: '',
    target_name: '',
    task: 'classification',
    models: {
      RandomForest: {
        one_model: false,
        time: 0.1,
        metric:
          problemType === 'classification' ? classificationMetricsOptions[0].label : regressionMetricsOptions[0].label
      }
    }
  });

  const selectAllOption = {
    label: 'Select All',
    value: 'selectAll',
    component: () => (
      <div>
        <input
          type="checkbox"
          checked={inDepColumnValueArray.length === indepColumnOptionsWithId.length}
          onChange={(e) => inDepColumnValue(e.target.checked ? [selectAllOption, ...indepColumnOptionsWithId] : [])}
        />
        <span>Select All</span>
      </div>
    )
  };
  const Option = (props: any) => {
    const { data } = props;
    return data.component ? (
      <components.Option {...props}>{data.component()}</components.Option>
    ) : (
      <components.Option {...props} />
    );
  };
  const modifiedOptions = indepColumnOptionsWithId;

  useEffect(() => {
    dispatch(datasourceUsersMiddleware.getDatasourceUsers(getDatasourceUsersURL));
    // dispatch(modelListMiddleware.getModelList(getModelListURL));
  }, []);

  const boostReset = (x: any) => {
    setBoostSaveObject(x);
  };

  const catboostReset = (x: any) => {
    setCatboostSaveObject(x);
  };

  const randomReset = (x: any) => {
    setRandomSaveObject(x);
  };
  const logisticReset = (x: any) => {
    setLogisticSaveObject(x);
  };
  const nnReset = (x: any) => {
    setNNSaveObject(x);
  };

  useEffect(() => {
    // Replace this section with your provided object structure
    let providedModelList = {
      model_list: [
        { NN: ['NN'] },
        { 'Linear Model': ['Logistic Regression'] },
        { 'Tree based models': ['XGBoost', 'CatBoost', 'Random Forest'] }
      ]
    };

    if (problemType === 'classification') {
      // Modify the model list for classification
      providedModelList = {
        model_list: [
          { NN: ['NN'] },
          { 'Linear Model': ['Logistic Regression'] },
          { 'Tree based models': ['XGBoost', 'CatBoost', 'Random Forest'] }
        ]
      };
    } else if (problemType === 'regression') {
      // Modify the model list for regression
      providedModelList = {
        model_list: [
          { NN: ['NN'] },
          { 'Linear Model': ['Linear Regression'] },
          { 'Tree based models': ['XGBoost', 'CatBoost', 'Random Forest'] }
        ]
      };
    }

    if (providedModelList?.model_list) {
      // Flatten the provided model_list object and create an array of options with nested sub-options
      const modelOptions = providedModelList.model_list.flatMap((modelGroup: any) => {
        const groupName = Object.keys(modelGroup)[0];
        const groupOptions = modelGroup[groupName];
        if (!groupOptions) {
          return { label: groupName, value: groupName, checked: false };
        }
        const subOptions = groupOptions.map((subOption: string) => ({
          label: subOption,
          value: `${groupName}.${subOption}`,
          checked: false
        }));
        return { label: groupName, options: subOptions, expanded: expandedModel === groupName };
      });
      setModelList(modelOptions);
    }
  }, [expandedModel, problemType]);
  const handleExpandModel = (groupName: string) => {
    setExpandedModel((prevExpanded) => (prevExpanded === groupName ? null : groupName));
  };

  const datasetValue = (selectedOption: any) => {
    const value = selectedOption.id;
    if (value !== undefined) {
      const getDatasourceColumnsURL = `/datasource/columns?file_name=${value}`;
      dispatch(datasourceColumnsMiddleware.getDatasourceColumns(getDatasourceColumnsURL));
    }
    setParams({ ...params, blob_name: '', target_name: '', features: [] });
    setParamsLR({ ...paramsLR, blob_name: '', target_name: '', features: [] });
    setParamsBoost({ ...paramsBoost, blob_name: '', target_name: '', features: [] });
    setParamsCatboost({ ...paramsCatboost, blob_name: '', target_name: '', features: [] });
    setParamsRandom({ ...paramsRandom, blob_name: '', target_name: '', features: [] });
    if (value === undefined) {
      setParams({ ...params, blob_name: '', target_name: '' });
      setParamsLR({ ...paramsLR, blob_name: '', target_name: '' });
      setParamsBoost({ ...paramsBoost, blob_name: '', target_name: '' });
      setParamsCatboost({ ...paramsCatboost, blob_name: '', target_name: '' });
      setParamsRandom({ ...paramsRandom, blob_name: '', target_name: '' });
    } else {
      setParams({ ...params, blob_name: `${value}` });
      setParamsLR({ ...paramsLR, blob_name: `${value}` });
      setParamsBoost({ ...paramsBoost, blob_name: `${value}` });
      setParamsCatboost({ ...paramsCatboost, blob_name: `${value}` });
      setParamsRandom({ ...paramsRandom, blob_name: `${value}` });
    }
    if (value === undefined) {
      params.target_name = '';
      setIndepColumnOptionsWithId([]);
    }
  };
  const depColumnValue = (e: any) => {
    const value = e.target.outerText;
    setIndepColumnOptionsWithId(depColumnOptionsWithIds.filter((option: any) => option.label !== value));
    params.features = [];
    paramsLR.features = [];
    paramsBoost.features = [];
    paramsCatboost.features = [];
    paramsRandom.features = [];
    setInDepColumnValueArray([]);
    if (value === undefined) {
      setParams({ ...params, target_name: '' });
      setParamsLR({ ...paramsLR, target_name: '' });
      setParamsBoost({ ...paramsBoost, target_name: '' });
      setParamsCatboost({ ...paramsBoost, target_name: '' });
      setParamsRandom({ ...paramsRandom, target_name: '' });
    } else {
      setParams({ ...params, target_name: value });
      setParamsLR({ ...paramsLR, target_name: value });
      setParamsBoost({ ...paramsBoost, target_name: value });
      setParamsCatboost({ ...paramsCatboost, target_name: value });
      setParamsRandom({ ...paramsRandom, target_name: value });
    }
  };
  const inDepColumnValue = (selectedOptions: any) => {
    const values = selectedOptions.map((option: any) => option.value);
    if (values.includes('selectAll')) {
      setInDepColumnValueArray(modifiedOptions.map((option: any) => option.value));
      setParams({ ...params, features: modifiedOptions.map((option: any) => option.label) });
      setParamsLR({ ...paramsLR, features: modifiedOptions.map((option: any) => option.label) });
      setParamsBoost({ ...paramsBoost, features: modifiedOptions.map((option: any) => option.label) });
      setParamsCatboost({ ...paramsCatboost, features: modifiedOptions.map((option: any) => option.label) });
      setParamsRandom({ ...paramsRandom, features: modifiedOptions.map((option: any) => option.label) });
    } else {
      setInDepColumnValueArray(values.filter((value: any) => value !== 'selectAll'));
      setParams({
        ...params,
        features: values
          .filter((value: any) => value !== 'selectAll')
          .map((value: any) => indepColumnOptionsWithId.find((option: any) => option.value === value)?.label)
      });
      setParamsLR({
        ...paramsLR,
        features: values
          .filter((value: any) => value !== 'selectAll')
          .map((value: any) => indepColumnOptionsWithId.find((option: any) => option.value === value)?.label)
      });
      setParamsBoost({
        ...paramsBoost,
        features: values
          .filter((value: any) => value !== 'selectAll')
          .map((value: any) => indepColumnOptionsWithId.find((option: any) => option.value === value)?.label)
      });
      setParamsCatboost({
        ...paramsCatboost,
        features: values
          .filter((value: any) => value !== 'selectAll')
          .map((value: any) => indepColumnOptionsWithId.find((option: any) => option.value === value)?.label)
      });
      setParamsRandom({
        ...paramsRandom,
        features: values
          .filter((value: any) => value !== 'selectAll')
          .map((value: any) => indepColumnOptionsWithId.find((option: any) => option.value === value)?.label)
      });
    }
  };

  const sliderValue = (event: any, newValue: any) => {
    setParams({ ...params, train_portion: newValue });
    setParamsLR({ ...paramsLR, train_portion: newValue });
    setParamsBoost({ ...paramsBoost, train_portion: newValue });
    setParamsCatboost({ ...paramsCatboost, train_portion: newValue });
    setParamsRandom({ ...paramsRandom, train_portion: newValue });
  };

  const datasetOptionsWithIds = datasourceUsersData.map((option: any, index: any) => ({
    id: option,
    label: option
  }));
  useEffect(() => {
    const x =
      datasourceColumnsData.length > 0
        ? datasourceColumnsData.map((option: any, index: any) => ({
            value: index,
            label: removeLastExtension(option)
          }))
        : [];

    setDepColumnOptionsWithIds([...x]);
  }, [datasourceColumnsData]);

  const trainModel = (modelName: any, params: any, setTrainingFn: any, setProgressFn: any) => {
    return new Promise<void>((resolve, reject) => {
      setTrainingFn(true);
      const getModelTrainURL = '/trainmodel/process_task/trainmodel';
      const trainingPromise = API.modelTrain
        .getModelTrain(params, getModelTrainURL)
        .then((dataOfModelTrain) => {
          if (dataOfModelTrain && dataOfModelTrain.status === 200) {
            const statusUpdate = dataOfModelTrain.data.status_update;
            const getTrainStatusURL = `/trainmodel/train_status/${statusUpdate}`;
            const getTrainProgressURL = `/trainmodel/train_progress/${statusUpdate}`;

            const checkModelStatus = async () => {
              try {
                const dataOfTrainStatus: any = await Promise.race([API.trainStatus.getTrainStatus(getTrainStatusURL)]);

                const dataOfTrainProgress = await API.trainProgress.getTrainProgress(getTrainProgressURL);

                if (
                  setProgressFn &&
                  dataOfTrainProgress.data.progress &&
                  dataOfTrainProgress.data.progress.length > 0
                ) {
                  setProgressFn(dataOfTrainProgress.data.progress);
                }

                if (dataOfTrainStatus && dataOfTrainStatus.data.queue_state === 'SUCCESS') {
                  toast.success(`${modelName} Model Trained Successfully!`);
                  setTrainingFn(false);
                  const getUserModelsURL = `/datasource/user_models`;
                  dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
                  const getStorageURL = `blob_file/get_user_storage_size`;
                  dispatch(storageMiddleware.getStorage(getStorageURL));
                  clearInterval(intervalId);
                  setProgressFn(0);
                  resolve(); // Resolve the promise when the model is trained successfully
                } else if (dataOfTrainStatus && dataOfTrainStatus.data.queue_state === 'FAILURE') {
                  toast.error(removeLastExtension(dataOfTrainStatus.data.message));
                  setTrainingFn(false);
                  clearInterval(intervalId);
                  setProgressFn(0);
                  reject(new Error(`${modelName} Model did not train!`)); // Reject the promise in case of failure
                } else if (dataOfTrainStatus === undefined || dataOfTrainProgress === undefined) {
                  clearInterval(intervalId);
                  setTrainingFn(false);
                  toast.error(removeLastExtension(dataOfTrainStatus.data.message));
                  setProgressFn(0);
                  reject(new Error('Model did not Train!')); // Reject the promise in case of undefined data
                } else if (Object.keys(dataOfTrainStatus).length === 0) {
                  clearInterval(intervalId);
                  setTrainingFn(false);
                  toast.warn('You will see your trained model after refresh');
                }
              } catch (error: any) {
                clearInterval(intervalId);
                setTrainingFn(false);
                toast.error(removeLastExtension(error?.response.data.message));
                console.error(error);
                reject(error); // Reject the promise in case of an error
              }
            };

            let intervalId = setInterval(checkModelStatus, 10000);
          } else {
            setTrainingFn(false);
            toast.error('Model did not Trained!');
            reject(new Error('Model did not Train!')); // Reject the promise if getModelTrain does not return status 200
          }
        })
        .catch((error: any) => {
          setTrainingFn(false);
          toast.error(removeLastExtension(error?.response.data.message));
          console.error(error);
          reject(error); // Reject the promise in case of an error
        });
    });
  };

  const handleTrainModelClick = async () => {
    const trainingPromises = [];

    if (nnCheckedValue === 'NN') {
      const trainPromise = () => {
        return trainModel('NN', params, setTraining, setProgress);
      };
      trainingPromises.push(trainPromise);
    }

    if (LrCheckedValue === 'Logistic Regression') {
      const trainPromise = () => {
        return trainModel('LogisticRegression', paramsLR, setTrainingLR, setProgressLR);
      };
      trainingPromises.push(trainPromise);
    }

    if (LrCheckedValue === 'Linear Regression') {
      const trainPromise = () => {
        return trainModel(LrCheckedValue, paramsLR, setTrainingLR, setProgressLR);
      };
      trainingPromises.push(trainPromise);
    }

    if (boostCheckedValue === 'XGBoost') {
      const trainPromise = () => {
        return trainModel('XGBoost', paramsBoost, setTrainingBoost, setProgressBoost);
      };
      trainingPromises.push(trainPromise);
    }

    if (catboostCheckedValue === 'Catboost') {
      const trainPromise = () => {
        return trainModel('CatBoost', paramsCatboost, setTrainingCatboost, setProgressCatboost);
      };
      trainingPromises.push(trainPromise);
    }

    if (randomCheckedValue === 'Random Forest') {
      const trainPromise = () => {
        return trainModel('RandomForest', paramsRandom, setTrainingRandom, setProgressRandom);
      };
      trainingPromises.push(trainPromise);
    }

    Promise.allSettled(trainingPromises.map((promise) => promise()))
      .then((results) => {
        // results is an array of objects with { status, value, reason }
      })
      .catch((error) => {
        console.error('An unexpected error occurred:', error);
      })
      .finally(() => {
        // This will execute after all training promises have either resolved or rejected
        deleteDataset(element); // Make sure element is correctly defined
      });
  };

  const onClearClick = () => {
    setParams({ ...params, blob_name: '', target_name: '', features: [], train_portion: 0.8 });
    setNnCheckedValue('');
    setLrCheckedValue('');
    setBoostCheckedValue('');
    setCatboostCheckedValue('');
    setRandomCheckedValue('');
    setCheckAllOptions(false);
    modelList.forEach((model: any) => {
      if (model.options.length > 0) {
        model.options[0].checked = false;
      }
    });
  };

  const modelInfoModalOpen = (
    { modelInfo }: { modelInfo: string },
    { problemType }: { problemType: string },
    { params }: { params: any },
    { setParams }: { setParams: any },
    { paramsLR }: { paramsLR: any },
    { setParamsLR }: { setParamsLR: any },
    { paramsBoost }: { paramsBoost: any },
    { setParamsBoost }: { setParamsBoost: any },
    { paramsCatboost }: { paramsCatboost: any },
    { setParamsCatboost }: { setParamsCatboost: any },
    { paramsRandom }: { paramsRandom: any },
    { setParamsRandom }: { setParamsRandom: any },
    { nnSaveObject }: { nnSaveObject: any },
    { setNNSaveObject }: { setNNSaveObject: any },
    { nnReset }: { nnReset: any },
    { logisticSaveObject }: { logisticSaveObject: any },
    { setLogisticSaveObject }: { setLogisticSaveObject: any },
    { logisticReset }: { logisticReset: any },
    { boostSaveObject }: { boostSaveObject: any },
    { setBoostSaveObject }: { setBoostSaveObject: any },
    { boostReset }: { boostReset: any },
    { catboostSaveObject }: { catboostSaveObject: any },
    { setCatboostSaveObject }: { setCatboostSaveObject: any },
    { catboostReset }: { catboostReset: any },
    { randomSaveObject }: { randomSaveObject: any },
    { setRandomSaveObject }: { setRandomSaveObject: any },
    { randomReset }: { randomReset: any }
  ) => {
    if (modelInfo === 'NN') {
      dispatch(
        modalsMiddleware.openModal({
          name: ModalName.NNInfoModal,
          props: { modelInfo, problemType, params, setParams, nnSaveObject, setNNSaveObject, nnReset }
        })
      );
    }

    if (modelInfo === 'Logistic Regression') {
      dispatch(
        modalsMiddleware.openModal({
          name: ModalName.LogisticRegressionInfoModal,
          props: {
            modelInfo,
            problemType,
            params,
            setParams,
            paramsLR,
            setParamsLR,
            logisticSaveObject,
            setLogisticSaveObject,
            logisticReset
          }
        })
      );
    }

    if (modelInfo === 'Linear Regression') {
      dispatch(
        modalsMiddleware.openModal({
          name: ModalName.LogisticRegressionInfoModal,
          props: {
            modelInfo,
            problemType,
            params,
            setParams,
            paramsLR,
            setParamsLR,
            logisticSaveObject,
            setLogisticSaveObject,
            logisticReset
          }
        })
      );
    }

    if (modelInfo === 'XGBoost') {
      dispatch(
        modalsMiddleware.openModal({
          name: ModalName.XGBoostInfoModal,
          props: {
            modelInfo,
            problemType,
            params,
            setParams,
            paramsBoost,
            setParamsBoost,
            boostSaveObject,
            setBoostSaveObject,
            boostReset
          }
        })
      );
    }

    if (modelInfo === 'CatBoost') {
      dispatch(
        modalsMiddleware.openModal({
          name: ModalName.CatboostInfoModal,
          props: {
            modelInfo,
            problemType,
            params,
            setParams,
            paramsCatboost,
            setParamsCatboost,
            catboostSaveObject,
            setCatboostSaveObject,
            catboostReset
          }
        })
      );
    }

    if (modelInfo === 'Random Forest') {
      dispatch(
        modalsMiddleware.openModal({
          name: ModalName.RandomInfoModal,
          props: {
            modelInfo,
            problemType,
            params,
            setParams,
            paramsRandom,
            setParamsRandom,
            randomSaveObject,
            setRandomSaveObject,
            randomReset
          }
        })
      );
    }
  };

  return (
    <div style={{ backgroundColor: theme.palette.grey[100], marginTop: '8px', borderRadius: '8px' }}>
      <div>
        {training ? (
          <div style={{ padding: '70px 230px' }}>
            <span>{removeLastExtension(params.blob_name)}</span>
            <span>/{Object.keys(params.models)[0]}</span>
            <Progress progress={progress} setProgress={setProgress} />
          </div>
        ) : null}

        {trainingLR ? (
          <div style={{ padding: '70px 230px' }}>
            <span>{removeLastExtension(paramsLR.blob_name)}</span>
            <span>/{Object.keys(paramsLR.models)[0]}</span>
            <Progress progress={progressLR} setProgress={setProgressLR} />
          </div>
        ) : null}

        {trainingBoost ? (
          <div style={{ padding: '70px 230px' }}>
            <span>{removeLastExtension(paramsBoost.blob_name)}</span>
            <span>/{Object.keys(paramsBoost.models)[0]}</span>
            <Progress progress={progressBoost} setProgress={setProgressBoost} />
          </div>
        ) : null}

        {trainingCatboost ? (
          <div style={{ padding: '70px 230px' }}>
            <span>{removeLastExtension(paramsCatboost.blob_name)}</span>
            <span>/{Object.keys(paramsCatboost.models)[0]}</span>
            <Progress progress={progressCatboost} setProgress={setProgressCatboost} />
          </div>
        ) : null}

        {trainingRandom ? (
          <div style={{ padding: '70px 230px' }}>
            <span>{removeLastExtension(paramsRandom.blob_name)}</span>
            <span>/{Object.keys(paramsRandom.models)[0]}</span>
            <Progress progress={progressRandom} setProgress={setProgressRandom} />
          </div>
        ) : null}
        {!training && !trainingLR && !trainingBoost && !trainingCatboost && !trainingRandom && (
          <Grid
            container
            direction="row"
            style={{
              backgroundColor: theme.palette.primary.light,
              marginTop: '8px',
              borderRadius: '8px',
              position: 'relative'
            }}
          >
            <IconButton
              size="large"
              onClick={() => {
                deleteDataset(element);
              }}
              sx={{ position: 'absolute', right: '5px' }}
            >
              <CloseIcon />
            </IconButton>
            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <p style={{ fontSize: '18px', fontWeight: '600' }}>Select Dataset</p>
              <Autocomplete
                value={removeLastExtension(params.blob_name)}
                options={datasetOptionsWithIds}
                onChange={(event, newValue) => datasetValue(newValue)}
                className={params.blob_name ? '' : 'hide-clear-indicator'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="File name"
                    // error={errorFields.includes('dataset')}
                    // helperText={errorFields.includes('dataset') && 'Select Dataset'}
                    label=""
                  />
                )}
                renderOption={(props, option: any) => (
                  <li {...props} style={{ whiteSpace: 'pre' }}>
                    {removeLastExtension(option?.label)}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <p style={{ fontSize: '18px', fontWeight: '600' }}>Select Target Column</p>
              <Autocomplete
                options={depColumnOptionsWithIds}
                onChange={depColumnValue}
                value={params.target_name}
                className={params.target_name ? '' : 'hide-clear-indicator'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      minHeight: '53.13px',
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: 'none'
                    }}
                    label=""
                  />
                )}
                disabled={params.blob_name === ''}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <p style={{ fontSize: '18px', fontWeight: '600' }}>Select Independent Columns</p>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    minHeight: '53.13px',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    background: '#fafafa',
                    border: state.isDisabled ? '1px solid #B9B9B9' : '1px solid #8D8D8D'
                  }),
                  option: (base) => ({
                    ...base,
                    padding: '8px'
                  })
                }}
                isMulti
                options={
                  inDepColumnValueArray.length === indepColumnOptionsWithId.length
                    ? modifiedOptions
                    : [selectAllOption, ...modifiedOptions]
                }
                onChange={inDepColumnValue}
                value={
                  params.target_name === ''
                    ? null
                    : modifiedOptions.filter((option: any) => inDepColumnValueArray.includes(option.value))
                }
                components={{ Option }}
                menuIsOpen={menuIsOpen}
                onMenuClose={() => setMenuIsOpen(false)}
                onMenuOpen={() => setMenuIsOpen(true)}
                closeMenuOnSelect={false}
                isDisabled={params.target_name === ''}
                placeholder=""
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <p style={{ fontSize: '18px', fontWeight: '600' }}>Select Problem Type</p>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  margin: '10px',
                  padding: '10px',
                  borderRadius: '14px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={ProblemTypes.Classification}
                      checked={problemType === ProblemTypes.Classification}
                      onClick={() => {
                        setCheckAllOptions(false);
                        setParams({ ...params, task: ProblemTypes.Classification });
                        setParamsLR({ ...paramsLR, task: ProblemTypes.Classification });
                        setParamsBoost({ ...paramsBoost, task: ProblemTypes.Classification });
                        setParamsCatboost({ ...paramsCatboost, task: ProblemTypes.Classification });
                        setParamsRandom({ ...paramsRandom, task: ProblemTypes.Classification });
                        setProblemType(ProblemTypes.Classification);
                        setNNSaveObject({});
                        setLogisticSaveObject({});
                        setBoostSaveObject({});
                        setRandomSaveObject({});
                      }}
                    />
                  }
                  label="Classification"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={ProblemTypes.Regression}
                      checked={problemType === ProblemTypes.Regression}
                      onClick={() => {
                        setNNSaveObject({});
                        setLogisticSaveObject({});
                        setBoostSaveObject({});
                        setRandomSaveObject({});
                        setCheckAllOptions(false);
                        setParams({ ...params, task: ProblemTypes.Regression });
                        setParams((prevParams: any) => ({
                          ...prevParams,
                          models: {
                            NN: {
                              one_model: false,
                              time: 1,
                              metric: 'RMSE'
                            }
                          }
                        }));
                        setParamsLR({ ...paramsLR, task: ProblemTypes.Regression });
                        setParamsLR((prevParams: any) => ({
                          ...prevParams,
                          models: {
                            LinearRegression: {
                              one_model: false,
                              time: 0.1,
                              metric: 'RMSE'
                            }
                          }
                        }));
                        setParamsBoost({ ...paramsBoost, task: ProblemTypes.Regression });
                        setParamsBoost((prevParams: any) => ({
                          ...prevParams,
                          models: {
                            ...prevParams.models,
                            XGBoost: {
                              ...prevParams.models.XGBoost,
                              metric: 'RMSE'
                            }
                          }
                        }));
                        setParamsCatboost({ ...paramsCatboost, task: ProblemTypes.Regression });
                        setParamsCatboost((prevParams: any) => ({
                          ...prevParams,
                          models: {
                            ...prevParams.models,
                            CatBoost: {
                              ...prevParams.models.CatBoost,
                              metric: 'RMSE'
                            }
                          }
                        }));
                        setParamsRandom({ ...paramsRandom, task: ProblemTypes.Regression });
                        setParamsRandom((prevParams: any) => ({
                          ...prevParams,
                          models: {
                            ...prevParams.models,
                            RandomForest: {
                              ...prevParams.models.Random,
                              metric: 'RMSE',
                              time: 0.1,
                              one_model: false
                            }
                          }
                        }));
                        setProblemType(ProblemTypes.Regression);
                      }}
                      // disabled={true}
                    />
                  }
                  label="Regression"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <p style={{ fontSize: '18px', fontWeight: '600' }}>Select Model</p>
              <Checkboxes
                paramsLR={paramsLR}
                setParamsLR={setParamsLR}
                paramsBoost={paramsBoost}
                setParamsBoost={setParamsBoost}
                paramsCatboost={paramsCatboost}
                setParamsCatboost={setParamsCatboost}
                paramsRandom={paramsRandom}
                setParamsRandom={setParamsRandom}
                modelInfoModalOpen={modelInfoModalOpen}
                params={params}
                setParams={setParams}
                problemType={problemType}
                error={false}
                options={modelList}
                setCheckedOptions={setTrainModels}
                title="Select Models"
                expandedModel={expandedModel}
                onExpandModel={handleExpandModel}
                setNnCheckedValue={setNnCheckedValue}
                setLrCheckedValue={setLrCheckedValue}
                setBoostCheckedValue={setBoostCheckedValue}
                setCatboostCheckedValue={setCatboostCheckedValue}
                setRandomCheckedValue={setRandomCheckedValue}
                checkAllOptions={checkAllOptions}
                setCheckAllOptions={setCheckAllOptions}
                nnSaveObject={nnSaveObject}
                setNNSaveObject={setNNSaveObject}
                nnReset={nnReset}
                logisticSaveObject={logisticSaveObject}
                setLogisticSaveObject={setLogisticSaveObject}
                logisticReset={logisticReset}
                boostSaveObject={boostSaveObject}
                setBoostSaveObject={setBoostSaveObject}
                boostReset={boostReset}
                catboostSaveObject={catboostSaveObject}
                setCatboostSaveObject={setCatboostSaveObject}
                catboostReset={catboostReset}
                randomSaveObject={randomSaveObject}
                setRandomSaveObject={setRandomSaveObject}
                randomReset={randomReset}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <p style={{ fontSize: '18px', fontWeight: '600' }}>Train Proportion</p>
              <Grid item xs={12} container spacing={2} alignItems="center" sx={{ mt: 2.5 }}>
                <Grid item>
                  <Typography variant="h6" color="primary">
                    0.5
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Slider
                    color="secondary"
                    defaultValue={0.75}
                    value={params.train_portion}
                    valueLabelDisplay="on"
                    aria-labelledby="discrete-slider-small-steps"
                    onChange={sliderValue}
                    marks
                    step={0.05}
                    min={0.5}
                    max={0.99}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="primary">
                    0.99
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    margin: '20px auto',
                    height: '52px',
                    background: theme.palette.warning.dark,
                    '&:hover': { background: theme.palette.warning.main },
                    color: 'grey.900'
                  }}
                  onClick={onClearClick}
                  disabled={
                    params.target_name === '' &&
                    params.blob_name === '' &&
                    params.features.length === 0 &&
                    modelList.every((model: any) => !model.options[0].checked)
                  }
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor:
                      params.target_name === '' ||
                      params.blob_name === '' ||
                      params.features.length === 0 ||
                      !modelList.some((model: any) => model.options.some((option: any) => option.checked))
                        ? '#CCCCCC'
                        : '#202090',
                    color:
                      params.target_name === '' ||
                      params.blob_name === '' ||
                      params.features.length === 0 ||
                      !modelList.some((model: any) => model.options.some((option: any) => option.checked))
                        ? '#666666'
                        : '#fff'
                  }}
                  sx={{ margin: '20px auto', height: '52px' }}
                  onClick={handleTrainModelClick}
                  disabled={
                    params.target_name === '' ||
                    params.blob_name === '' ||
                    params.features.length === 0 ||
                    !modelList.some((model: any) => model.options.some((option: any) => option.checked))
                  }
                >
                  Train Model
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default DatasetMenu;
