import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const snowflakePushDataToAzureManager = {
  getSnowflakePushDataToAzure(url: any, params: any) {
    return axiosInstance.v2({
      url: url,
      method: 'POST',
      data: params
    });
  }
};

export default snowflakePushDataToAzureManager;