import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { PreviewStatusProps } from '../../../types/reduxTypes/previewStatus';

const createReducer = <T extends SliceCaseReducers<PreviewStatusProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setPreviewStatusData(state, action) {
    state.previewStatusData = action.payload;
  }
});

export default reducers;
