import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setModelSummaryData, setModelSummaryStatus } = slice.actions;

const getModelSummary = (url: any) => async () => {
  try {
    const response = await API.modelSummary.getModelSummary(url);
    dispatch(setModelSummaryData(response.data));
    dispatch(setModelSummaryStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getModelSummary
};
