import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ModelViewProps } from '../../../types/reduxTypes/modelView';

const createReducer = <T extends SliceCaseReducers<ModelViewProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setModelViewData(state, action) {
    state.modelViewData = action.payload;
  },
  setModelViewStatus(state, action) {
    state.modelViewStatus = action.payload;
  }
});

export default reducers;
