import * as Yup from 'yup';

export const snowflakeGetQueryURL = '/snowflake_adding/get_query';

export const validationSchema = Yup.object().shape({
  query: Yup.string().required('Query is required')
});

export const initialValues = {
  query: ''
};
