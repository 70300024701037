import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const resourcesManager = {
  getResources(url: any) {
    return axiosInstance.v2.get(url);
  }
};

export default resourcesManager;
