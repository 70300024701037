import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React from 'react';

export const titleStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
};

export const formContainerStyles: React.CSSProperties = {
  width: '100%'
};

export const descriptionStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '40px',
  minHeight: '130px'
};

export const containerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0 50px',
  color: '#000'
};

export const descriptionColorStyles = {
  color: 'rgba(0, 0, 0, 0.80)',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  margin: '0'
};
export const inputColorStyles = {
  color: 'rgba(0, 0, 0, 0.80)',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  margin: '0 0 5px 0'
};

export const tableContainerStyles = {
  width: '100%',
  maxHeight: '290px',
  marginBottom: '-5px'
};

export const tableHeadStyles = {
  backgroundColor: 'rgb(32, 32, 144)',
  color: '#fff'
};

export const tableCellColorStyles: React.CSSProperties = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  color: '#fff'
};
export const bodyColorStyles: React.CSSProperties = {
  maxHeight: 'calc(100% - 48px)',
  overflowY: 'scroll',
  color: '#fff'
};

export const buttonContainerStyles: SxProps<Theme> = {
};

export const styledTableCellStyles: SxProps<Theme> = {
  pl: 3
};

export const tableStyles: SxProps<Theme> = {
  width: '100%'
};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};

export const backButtonStyles: React.CSSProperties = {
  backgroundColor: '#202090',
  color: '#fff'
};
