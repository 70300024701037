import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Button, Grid, IconButton, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { MergeTypes, top100Films } from '../../helpers/managers';
import { FieldsValidator } from '../../helpers/validator';
import { DatasourceMenuData, MultipleInput, SingleInput } from '../../types/components/managers/datasource';
import Progress from '../Progress';

import DatasourceMenuStyled from './DatasourceMenuStyled';

interface DatasourceMenuProps {
  element: number;
  deleteDatasource: (element: number) => void;
}

// eslint-disable-next-line max-lines-per-function
const DatasourceMenu = ({ element, deleteDatasource }: DatasourceMenuProps) => {
  const [datasourceMenu, setDatasourceMenu] = useState<SingleInput>('');
  const [operationType, setOperationType] = useState<SingleInput>('');
  const [progressing, setProgressing] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [fieldName, setFieldName] = useState<MultipleInput[]>([]);
  const [mergeWith, setMergeWith] = useState<SingleInput>('');
  const [mergeType, setMergeType] = useState<SingleInput>('');
  const [leftColumn, setLeftColumn] = useState<MultipleInput[]>([]);
  const [rightColumn, setRightColumn] = useState<MultipleInput[]>([]);
  const theme = useTheme();

  const handleProcessing = () => {
    let datasourceData: DatasourceMenuData[] = [
      { label: 'datasourceMenu', data: datasourceMenu },
      { label: 'operationType', data: operationType }
    ];
    const mergeData = [
      { label: 'mergeWith', data: mergeWith },
      { label: 'mergeType', data: mergeType },
      { label: 'leftColumn', data: leftColumn },
      { label: 'rightColumn', data: rightColumn }
    ];

    if (operationType === 'Merge Operation') {
      datasourceData = [...datasourceData, ...mergeData];
    } else {
      datasourceData.push({ label: 'fieldName', data: fieldName });
    }

    const errorsCount = FieldsValidator(datasourceData);

    setErrorFields(errorsCount);

    if (errorsCount.length === 0) {
      setProgressing(true);
    }
  };

  const handleClear = () => {
    setDatasourceMenu('');
  };

  return (
    <DatasourceMenuStyled theme={theme}>
      <div className="main-component">
        {progressing ? (
          <div className="progress-bar">
            <Progress />
          </div>
        ) : (
          <span>
            <Grid container direction="row" sx={{ position: 'relative' }}>
              <IconButton
                size="large"
                onClick={() => {
                  deleteDatasource(element);
                }}
                sx={{ position: 'absolute', right: '5px' }}
              >
                <CloseIcon />
              </IconButton>
              <Grid item xs={12} md={6} className="grid-box">
                <p className="title">Datasource Menu</p>
                <Autocomplete
                  disableClearable
                  value={{ label: datasourceMenu, id: 0 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errorFields.includes('datasourceMenu')}
                      helperText={errorFields.includes('datasourceMenu') && 'Select Datasource Menu'}
                    />
                  )}
                  onChange={(event, value) => {
                    setDatasourceMenu(value.label);
                  }}
                  options={top100Films}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="grid-box"
                sx={{ paddingX: '16px', display: 'flex', justifyContent: 'space-between' }}
              >
                <Button
                  variant="contained"
                  size="large"
                  className="clear-button"
                  onClick={() => {
                    handleClear();
                  }}
                >
                  Clear
                </Button>
                <Button
                  onClick={handleProcessing}
                  color="secondary"
                  variant="contained"
                  size="large"
                  className="progressing-button"
                >
                  Processing
                </Button>
              </Grid>
            </Grid>
            {datasourceMenu && (
              <Grid container direction="row">
                <Grid item xs={12} md={6} className="grid-box">
                  <p className="title">Operation Type</p>
                  <Autocomplete
                    style={{ marginBottom: '16px' }}
                    onChange={(event, value) => {
                      if (value) {
                        setOperationType(value.label);
                      }
                    }}
                    options={top100Films}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errorFields.includes('operationType')}
                        helperText={errorFields.includes('operationType') && 'Select Operation Type'}
                      />
                    )}
                  />
                </Grid>
                {operationType === 'Merge Operation' && (
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} className="grid-box">
                      <p className="title">Merge With</p>
                      <Autocomplete
                        onChange={(event, value) => {
                          if (value) {
                            setMergeWith(value.label);
                          }
                        }}
                        options={top100Films}
                        renderInput={(params) => (
                          <TextField
                            error={errorFields.includes('mergeWith')}
                            helperText={errorFields.includes('mergeWith') && 'Select Merge With'}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="grid-box">
                      <p className="title">Merge Type</p>
                      <Autocomplete
                        onChange={(event, value) => {
                          if (value) {
                            setMergeType(value.label);
                          }
                        }}
                        style={{ marginBottom: '16px' }}
                        options={MergeTypes}
                        renderInput={(params) => (
                          <TextField
                            error={errorFields.includes('mergeType')}
                            helperText={errorFields.includes('mergeType') && 'Select Merge Type'}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="grid-box">
                      <p className="title">Left Columns</p>
                      <Autocomplete
                        multiple
                        onChange={(event, value) => {
                          if (value) {
                            setLeftColumn(value);
                          }
                        }}
                        options={top100Films}
                        renderInput={(params) => (
                          <TextField
                            error={errorFields.includes('leftColumn')}
                            helperText={errorFields.includes('leftColumn') && 'Select Left Column'}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="grid-box">
                      <p className="title">Right Columns</p>
                      <Autocomplete
                        multiple
                        onChange={(event, value) => {
                          if (value) {
                            setRightColumn(value);
                          }
                        }}
                        style={{ marginBottom: '16px' }}
                        options={top100Films}
                        renderInput={(params) => (
                          <TextField
                            error={errorFields.includes('rightColumn')}
                            helperText={errorFields.includes('rightColumn') && 'Select Right Column'}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                {operationType && operationType !== 'Merge Operation' && (
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} className="grid-box">
                      <p className="title">Field Name</p>
                      <Autocomplete
                        multiple
                        onChange={(event, value) => {
                          if (value) {
                            setFieldName(value);
                          }
                        }}
                        options={top100Films}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            error={errorFields.includes('fieldName')}
                            helperText={errorFields.includes('fieldName') && 'Select Field Name'}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </span>
        )}
      </div>
    </DatasourceMenuStyled>
  );
};

export default DatasourceMenu;
