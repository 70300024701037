import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { PredictionStatusProps } from '../../../types/reduxTypes/predictionStatus';

const createReducer = <T extends SliceCaseReducers<PredictionStatusProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setPredictionStatusData(state, action) {
    state.predictionStatusData = action.payload;
  },
  setPredictionStatusStatus(state, action) {
    state.predictionStatusStatus = action.payload;
  }
});

export default reducers;
