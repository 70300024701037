import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const formContainerStyles: React.CSSProperties = {
  width: '100%'
};

export const containerStyles: React.CSSProperties = {
  flexDirection: 'column',
  padding: '0 50px',
  display: 'flex',
  color: '#000'
};

export const titleStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
};

export const formStyles: React.CSSProperties = {
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center'
};

export const radioButtonStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center'
};
export const inputStyles: React.CSSProperties = {
  width: '16px',
  height: '16px',
  fill: '#FFF',
  strokeWidth: '1px',
  stroke: '#000',
  marginRight: '5px',
  accentColor: '#334063'
};

export const logoStyles: React.CSSProperties = {
  width: '16px',
  height: '16px',
  marginRight: '5px'
};

export const spanStyles: React.CSSProperties = {
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
};

export const errorStyles: React.CSSProperties = {
  color: 'red'
};

export const buttonContainerStyles: SxProps<Theme> = {
  marginTop: '365px' //todo
};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};
