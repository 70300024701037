import * as Yup from 'yup';

export const snowflakeTestConnectionURL = '/snowflake_adding/test_connection';

export const validationSchema = Yup.object().shape({
  user: Yup.string().required('Username is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  account: Yup.string().required('Account is required'),
  warehouse: Yup.string().required('Warehouse is required'),
  database: Yup.string().required('Database is required'),
  schema: Yup.string().default('PUBLIC').notRequired(),
});

export const initialValues = {
  user: '',
  password: '',
  account: '',
  warehouse: '',
  database: '',
  schema: ''
};
