import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setSnowflakeGetQueryData, setSnowflakeGetQueryStatus } = slice.actions;

const getSnowflakeGetQuery = (url: any, params: any) => async () => {
  //todo
  try {
    const response = await API.snowflakeGetQuery.getSnowflakeGetQuery(url, params);
    dispatch(setSnowflakeGetQueryData(response.data));
    dispatch(setSnowflakeGetQueryStatus(response.status));
  } catch (error: any) {
    dispatch(setSnowflakeGetQueryData(error?.response?.data));
    dispatch(setSnowflakeGetQueryStatus(error?.response?.status));
  }
};

export default {
  getSnowflakeGetQuery
};
