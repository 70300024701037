import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.summary;

const summaryData = createSelector([selector], (state) => state);

export default {
  summaryData
};
