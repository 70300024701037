import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setPredictionStatusData, setPredictionStatusStatus } = slice.actions;

const getPredictionStatusData = (url: string) => async () => {
  try {
    const response = await API.predictionStatus.getPredictionStatusData(url);
    dispatch(setPredictionStatusData(response.data));
    dispatch(setPredictionStatusStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    return { status: error.response.status, data: error.response.data };
  }
};

export default {
  getPredictionStatusData
};
