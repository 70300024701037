import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const snowflakeGetQueryManager = {
  getSnowflakeGetQuery(url: any, params: any) {
    return axiosInstance.v2({
      url: url,
      method: 'POST',
      data: params
    });
  }
};

export default snowflakeGetQueryManager;