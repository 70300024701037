import { styled } from '@mui/material/styles';

const CheckboxStyled = styled('div')(() => ({
  '.main-box': {
    backgroundColor: '#ffffff',
    margin: '10px',
    padding: '10px',
    borderRadius: '14px',
    height: '150px',
    overflow: 'scroll'
  },

  '.box-title': {
    fontSize: '18px',
    fontWeight: '600'
  },

  '.checkbox': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '6px',
    paddingRight: '6px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      borderRadius: '14px'
    }
  }
}));

export default CheckboxStyled;
