import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setPredictionProgressData, setPredictionProgressStatus } = slice.actions;

const getPredictionProgressData = (url: string) => async () => {
  try {
    const response = await API.predictionProgress.getPredictionProgressData(url);
    dispatch(setPredictionProgressData(response.data));
    dispatch(setPredictionProgressStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getPredictionProgressData
};
