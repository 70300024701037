import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const formContainerStyles: React.CSSProperties = {
  width: '100%'
};

export const containerStyles: React.CSSProperties = {
  flexDirection: 'column',
  padding: '0 50px',
  display: 'flex',
  color: '#000'
};

export const contentStyles: React.CSSProperties = {
  height: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '50%',
  margin: '0 auto'
};

export const titleStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal'
};

export const backButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '60px',
  borderRadius: '8px',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  backgroundColor: '#334063',
  color: '#fff'
};

export const descriptionStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  justifyContent: 'center'
};

export const formStyles: React.CSSProperties = {
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
};

export const dropdownStyles: React.CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
};

export const exampleStyles: React.CSSProperties = {
  marginTop: '10px',
  fontStyle: 'italic',
  fontSize: '14px',
  fontFamily: 'Circular Std Book',
  fontWeight: '400'
};

export const radioButtonStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center'
};
export const inputStyles: React.CSSProperties = {
  width: '16px',
  height: '16px',
  fill: '#FFF',
  strokeWidth: '1px',
  stroke: '#000',
  marginRight: '5px',
  accentColor: '#334063'
};

export const spanStyles: React.CSSProperties = {
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal'
};

export const errorStyles: React.CSSProperties = {
  color: 'red'
};

export const buttonContainerStyles: SxProps<Theme> = {};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};

export const textFieldStyles: React.CSSProperties = {
  width: '800px',
  height: '300px',
  marginTop: '20px'
};

export const loadingStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh'
};
