import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setUndeployData, setUndeployStatus } = slice.actions;

const getUndeploy = (url: any) => async () => {
  try {
    const response = await API.undeploy.getUndeploy(url);
    dispatch(setUndeployData(response.data));
    dispatch(setUndeployStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getUndeploy
};
