import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const containerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0 50px',
  color: '#000'
};

export const titleStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal'
};

export const inputContainerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '40px'
};

export const inputStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column'
};

export const singleInputStyles: React.CSSProperties = {
  width: '560px',
  height: '40px',
  marginBottom: '28px',
  top: '-5px'
};

export const lastInputStyles: React.CSSProperties = {
  width: '560px',
  height: '40px',
  marginBottom: '0px'
};

export const resetStyles: React.CSSProperties = {
  width: '560px',
  textAlign: 'right',
  color: 'rgba(51, 64, 99, 0.71)',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  margin: '6px 0 0 0',
  cursor: 'pointer'
};

export const buttonContainerStyles: SxProps<Theme> = {};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};

export const formContainerStyles: React.CSSProperties = {
  width: '100%'
};

export const backButtonStyles: React.CSSProperties = {
  backgroundColor: '#202090',
  color: '#fff'
};

export const nextButtonStyles: React.CSSProperties = {
  backgroundColor: '#202090',
  color: '#fff',
  float: 'right'
};

export const loadingContainerStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '1100px',
  margin: '0',
  height: '600px'
};

export const loadingIconStyles: React.CSSProperties = {
  color: 'blue'
};

export const inputErrorStyles: React.CSSProperties = {
  color: 'red',
  fontSize: '12px',
  fontStyle: 'italic'
};
