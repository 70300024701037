import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { SnowflakeDataPushStatusProps } from '../../../types/reduxTypes/snowflakeDataPushStatus';

const createReducer = <T extends SliceCaseReducers<SnowflakeDataPushStatusProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setSnowflakeDataPushStatusData(state, action) {
    state.snowflakeDataPushStatusData = action.payload;
  },
  setSnowflakeDataPushStatus(state, action) {
    state.snowflakeDataPushStatus = action.payload;
  }
});

export default reducers;