import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setModelAddingData, setModelAddingStatus } = slice.actions;

const modelAdding = (url: any, params: any) => async () => {
  try {
    const response = await API.modelAdding.modelAdding(url, params);
    dispatch(setModelAddingData(response.data));
    dispatch(setModelAddingStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  modelAdding
};
