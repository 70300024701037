import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const modelListManager = {
  getModelList(url: any) {
    return axiosInstance.v2.get(url);
  }
};

export default modelListManager;
