import { Axios } from '../axiosInstance';

const healthURL = '/health';
const axiosInstance = Axios();

const healthManager = {
  getHealthData() {
    return axiosInstance.v2.get(`${healthURL}`);
  }
};

export default healthManager;
