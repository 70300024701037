import React from 'react';
import { Route, Routes } from 'react-router-dom';

// import HomePage from '../pages/HomePage';
import ManagerPage from '../pages/ManagersPage';
import ResourcesPage from '../pages/ResourcesPage';
import NotFound from '../pages/notFound/notFound';

const Router = () => (
  <Routes>
    {/*<Route path="/" element={<HomePage />} />*/}
    <Route path="/" element={<ResourcesPage />} />
    <Route path="/resources" element={<ResourcesPage />} />
    <Route path="/manager/:page" element={<ManagerPage />} />
    <Route path="/notfound" element={<NotFound />} />
  </Routes>
);

export default Router;
