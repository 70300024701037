import React from 'react';

export const dropdownStyles: React.CSSProperties = {
  borderRadius: '8px',
  width: '400px',
  boxShadow: 'rgba(0, 0, 0, 0.5) 0px 5px 5px 3px',
  cursor: 'pointer',
  backgroundColor: '#fff',
  maxHeight: '200px',
  overflow: 'auto',
  position: 'absolute',
  left: '400px',
  top: '392px'
};

export const resetStyles: React.CSSProperties = {
  width: '560px',
  textAlign: 'right',
  color: 'rgba(51, 64, 99, 0.71)',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  margin: '6px 0 0 0',
  cursor: 'pointer',
  paddingRight: '80px'
};

export const suggestionStyles: React.CSSProperties = {
  padding: '10px',
  margin: '0',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  borderBottom: '1px solid #ddd', // Optional: Add a border between suggestions
};

export const textFieldStyles: React.CSSProperties = {
  width: '400px',
  height: '100px'
};

export const hideStyles: React.CSSProperties = {
  display: 'none'
};