import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { modelAddingProps } from '../../../types/reduxTypes/modelAdding';

const createReducer = <T extends SliceCaseReducers<modelAddingProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setModelAddingData(state, action) {
    state.modelAddingData = action.payload;
  },
  setModelAddingStatus(state, action) {
    state.modelAddingStatus = action.payload;
  }
});

export default reducers;
