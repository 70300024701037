import { AppDispatch } from 'store/store';

import { SeveritiesType } from '../../../types/modals';
import { modalsMiddleware } from '../modals';

import slice from './slice';

const { setLoadingModelId } = slice.actions;

const setLoadingModel = (loadingModelId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoadingModelId(loadingModelId));
  setTimeout(() => {
    dispatch(setLoadingModelId(''));
    dispatch(
      modalsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Hello, i am success'
        }
      })
    );
  }, 2000);
};

export default {
  setLoadingModel
};
