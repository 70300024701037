import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setAuthData, setAuthStatus } = slice.actions;

const getAuthData = () => async () => {
  try {
    const response = await API.auth.getAuthData();
    dispatch(setAuthData(response.data));
    dispatch(setAuthStatus(response.status));
    if (Object.keys( response.data).length > 0 &&  response.data?.user_info?.user_me_data.hasOwnProperty('userId')) {
      localStorage.setItem('userId', response.data?.user_info?.user_me_data.userId);
    }

  } catch (error) {}
};

export default {
  getAuthData
};
