import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.user;

const user = createSelector([selector], (state) => state.id);

export default {
  user
};
