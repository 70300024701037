import React from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

import MainCard from '../MainCard';

// table columns
const columns = [
  { field: 'column_name', headerName: 'Column name' },
  { field: 'type', headerName: 'Type' }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px' // set the maximum width here
  },
  '&:hover': {
    '&::after': {
      content: 'attr(title)',
      display: 'block',
      position: 'absolute',
      backgroundColor: '#333',
      color: '#fff',
      padding: '5px',
      zIndex: 1,
      left: '100%',
      top: '0',
      whiteSpace: 'normal',
      width: 'auto',
      minWidth: '100px',
      textAlign: 'center'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-of-type td, &:last-of-type th': {
    border: 0
  }
}));

const InfoTable = ({ data }: any) => {
  return (
    <>
      {data.success !== null && Object.keys(data).length > 0 ? (
        <MainCard content={false} sx={{ width: '100%' }}>
          <TableContainer sx={{ width: '100%' }}>
            <Table
              style={{ overflowX: 'auto', whiteSpace: 'nowrap', height: '500px' }}
              sx={{ width: '100%' }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  {columns.map((headCell) => (
                    <StyledTableCell sx={{ pl: 3 }}>{headCell.headerName}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(data).length > 0 &&
                  data.success && // add null check here
                  Object.entries(data.success).map(([key, value], index) => (
                    <StyledTableRow hover key={index}>
                      <StyledTableCell>{key}</StyledTableCell>
                      <StyledTableCell>{value as string}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            // marginTop: '-100px',
            // marginLeft: '-480px'
          }}
        >
          <CircularProgress style={{ color: 'blue' }} />
        </div>
      )}
    </>
  );
};

export default InfoTable;
