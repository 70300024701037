import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { PredictionProps } from '../../../types/reduxTypes/prediction';

const createReducer = <T extends SliceCaseReducers<PredictionProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setPredictionData(state, action) {
    state.predictionData = action.payload;
  },
  setPredictionStatus(state, action) {
    state.predictionStatus = action.payload;
  }
});

export default reducers;
