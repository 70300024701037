import React, { useEffect, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Select, { components } from 'react-select';
import { ProblemTypes } from '../../types/components/managers';
import { Type } from '../../types/customer';
import Progress from '../Progress';
import { dispatch, useAppSelector } from '../../store/hooks';
import { datasourceUsersMiddleware, datasourceUsersSelector } from '../../store/slices/datasourceUsers';
import { datasourceColumnsMiddleware, datasourceColumnsSelector } from '../../store/slices/datasourceColumns';
import { modelTrainMiddleware } from '../../store/slices/modelTrain';
import { trainStatusMiddleware } from '../../store/slices/trainStatus';
import { toast } from 'react-toastify';
import { trainProgressMiddleware } from '../../store/slices/trainProgress';
import { userModelsMiddleware } from '../../store/slices/userModels';
import { modelListMiddleware, modelListSelector } from '../../store/slices/modelList';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { IconSettings } from '@tabler/icons';
import CheckboxStyled from './CheckboxStyled';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export interface ICheckboxOption {
  label: string;
  checked: boolean;
}

interface IOptionGroup {
  label: string;
  options: ICheckboxOption[];
  expanded: boolean;
}

interface ICheckboxesProps {
  paramsLR: any;
  setParamsLR: any;
  paramsBoost: any;
  paramsCatboost: any;
  setParamsBoost: any;
  setParamsCatboost: any;
  paramsRandom: any;
  setParamsRandom: any;
  modelInfoModalOpen: any;
  expandedModel: any; //IOptionGroup[];
  onExpandModel: (label: string) => void;
  problemType: string;
  options: any[];
  setCheckedOptions: (options: ICheckboxOption[]) => void;
  title: string;
  error: boolean;
  params: any;
  setParams: any;
  setNnCheckedValue: any;
  setLrCheckedValue: any;
  setBoostCheckedValue: any;
  setCatboostCheckedValue: any;
  setRandomCheckedValue: any;
  checkAllOptions: any;
  setCheckAllOptions: any;
  nnSaveObject: any;
  setNNSaveObject: any;
  nnReset: any;
  logisticSaveObject: any;
  setLogisticSaveObject: any;
  logisticReset: any;
  boostSaveObject: any;
  catboostSaveObject: any;
  setBoostSaveObject: any;
  setCatboostSaveObject: any;
  boostReset: any;
  catboostReset: any;
  randomSaveObject: any;
  setRandomSaveObject: any;
  randomReset: any;
}

const Checkboxes = (props: ICheckboxesProps) => {
  const theme = useTheme();
  const {
    setParams,
    params,
    options,
    setCheckedOptions,
    error,
    problemType,
    paramsLR,
    setParamsLR,
    paramsBoost,
    paramsRandom,
    setParamsBoost,
    setParamsRandom,
    setNnCheckedValue,
    setLrCheckedValue,
    setBoostCheckedValue,
    setRandomCheckedValue,
    checkAllOptions,
    setCheckAllOptions,
    nnSaveObject,
    setNNSaveObject,
    nnReset,
    logisticSaveObject,
    setLogisticSaveObject,
    logisticReset,
    boostSaveObject,
    setBoostSaveObject,
    boostReset,
    randomSaveObject,
    setRandomSaveObject,
    randomReset,
    paramsCatboost,
    setParamsCatboost,
    setCatboostCheckedValue,
    catboostSaveObject,
    setCatboostSaveObject,
    catboostReset
  } = props;

  const [sortedBy, setSortedBy] = useState<'asc' | 'desc'>('desc');
  const [sortedOptions, setSortedOptions] = useState<any[]>([]);

  useEffect(() => {
    if (options.length > 0) {
      setSortedOptions(options);
    }
  }, [options]);

  const handleOptionCheck = (value: string) => {
    const arr = [...sortedOptions];
    let checkedCount = 0;

    arr.forEach((el) => {
      if (el.options) {
        // Handle nested sub-options
        el.options.forEach((subOption: any) => {
          if (subOption.label === value) {
            subOption.checked = !subOption.checked;
          }
          if (subOption.label === 'XGBoost') {
            if (subOption.checked) {
              setBoostCheckedValue('XGBoost');
            } else {
              setBoostCheckedValue('');
            }
          }
          if (subOption.label === 'CatBoost') {
            if (subOption.checked) {
              setCatboostCheckedValue('Catboost');
            } else {
              setCatboostCheckedValue('');
            }
          }

          if (subOption.label === 'Random Forest') {
            if (subOption.checked) {
              setRandomCheckedValue('Random Forest');
            } else {
              setRandomCheckedValue('');
            }
          }

          if (subOption.label === 'Logistic Regression') {
            if (subOption.checked) {
              setLrCheckedValue('Logistic Regression');
            } else {
              setLrCheckedValue('');
            }
          }
          if (subOption.label === 'Linear Regression') {
            if (subOption.checked) {
              setLrCheckedValue('Linear Regression');
            } else {
              setLrCheckedValue('');
            }
          }

          if (subOption.label === 'NN') {
            if (subOption.checked) {
              setNnCheckedValue('NN');
            } else {
              setNnCheckedValue('');
            }
          }

          if (subOption.checked) {
            checkedCount += 1;
          }
        });
      } else {
        el.checked = el.label === value ? !el.checked : el.checked;

        if (el.checked) {
          checkedCount += 1;
        }
      }
    });

    // Update 'Check All' status based on the count of checked items
    if (checkedCount === arr.reduce((acc, el) => acc + (el.options ? el.options.length : 1), 0)) {
      setCheckAllOptions(true);
    } else {
      setCheckAllOptions(false);
    }

    setCheckedOptions(arr);
  };

  const handleAllModelsCheck = () => {
    if (!checkAllOptions) {
      setNnCheckedValue('NN');
      setLrCheckedValue(problemType === 'classification' ? 'Logistic Regression' : 'Linear Regression');
      setBoostCheckedValue('XGBoost');
      setCatboostCheckedValue('Catboost');
      setRandomCheckedValue('Random Forest');
      sortedOptions.forEach((el) => {
        if (el.options) {
          el.options.forEach((subOption: any) => (subOption.checked = true));
        } else {
          el.checked = true;
        }
      });
    } else {
      setNnCheckedValue('');
      setLrCheckedValue('');
      setBoostCheckedValue('');
      setCatboostCheckedValue('');
      setRandomCheckedValue('');
      sortedOptions.forEach((el) => {
        if (el.options) {
          el.options.forEach((subOption: any) => (subOption.checked = false));
        } else {
          el.checked = false;
        }
      });
    }

    setCheckAllOptions((prev: any) => !prev);
    setCheckedOptions([...sortedOptions]); // Ensure the UI updates with the new state
  };

  const onArrowClick = () => {
    const notSortedOptions = [...sortedOptions];

    if (sortedBy === 'desc') {
      setSortedBy('asc');
      notSortedOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
    } else {
      setSortedBy('desc');
      notSortedOptions.sort((a, b) => (a.label > b.label ? -1 : 1));
    }

    setSortedOptions(notSortedOptions);
  };

  useEffect(() => {
    handleAllModelsCheck();
    setCheckedOptions(options);
    // eslint-disable-next-line
  }, []);

  const handleExpandModel = (groupName: string) => {
    setSortedOptions((prevOptions) => {
      return prevOptions.map((option: IOptionGroup) => {
        if (option.label === groupName) {
          return {
            ...option,
            expanded: !option.expanded
          };
        }
        return option;
      });
    });
  };

  return (
    <CheckboxStyled>
      {/* ... (existing code) */}
      <div className="main-box">
        <FormGroup>
          <span className="checkbox">
            <FormControlLabel
              value="All"
              onClick={() => {
                handleAllModelsCheck();
              }}
              control={<Checkbox checked={checkAllOptions} />}
              label="All"
            />
            {sortedBy === 'asc' ? (
              <ArrowUpwardIcon sx={{ marginRight: '2px' }} onClick={onArrowClick} />
            ) : (
              <ArrowDownwardIcon sx={{ marginRight: '2px' }} onClick={onArrowClick} />
            )}
          </span>
          {sortedOptions.length > 0 ? (
            sortedOptions.map((group: IOptionGroup) => (
              <div key={group.label}>
                <span
                  aria-disabled={true}
                  className="expand-checkbox"
                  style={{ display: 'flex' }}
                  onClick={() => {
                    handleExpandModel(group.label);
                  }}
                >
                  {group.expanded ? <ExpandLess /> : <ExpandMore />}
                  <Typography variant="subtitle1" color="textSecondary">
                    {group.label}
                  </Typography>
                </span>
                {group.expanded &&
                  group.options.map((el: any) => (
                    <span key={el.label} className="checkbox">
                      <FormControlLabel
                        value={el.label}
                        onClick={(event) => {
                          handleOptionCheck((event.target as HTMLInputElement).value);
                        }}
                        control={<Checkbox checked={el.checked} />}
                        label={el.label}
                      />
                      {el.checked ? (
                        <IconSettings
                          onClick={() => {
                            props.modelInfoModalOpen(
                              { modelInfo: el.label },
                              { problemType },
                              { params: params },
                              { setParams: setParams },
                              { paramsLR: paramsLR },
                              { setParamsLR: setParamsLR },
                              { paramsBoost: paramsBoost },
                              { setParamsBoost: setParamsBoost },
                              { paramsCatboost: paramsCatboost },
                              { setParamsCatboost: setParamsCatboost },
                              { paramsRandom: paramsRandom },
                              { setParamsRandom: setParamsRandom },
                              { nnSaveObject: nnSaveObject },
                              { setNNSaveObject: setNNSaveObject },
                              { nnReset: nnReset },
                              { logisticSaveObject: logisticSaveObject },
                              { setLogisticSaveObject: setLogisticSaveObject },
                              { logisticReset: logisticReset },
                              { boostSaveObject: boostSaveObject },
                              { setBoostSaveObject: setBoostSaveObject },
                              { boostReset: boostReset },
                              { catboostSaveObject: catboostSaveObject },
                              { setCatboostSaveObject: setCatboostSaveObject },
                              { catboostReset: catboostReset },
                              { randomSaveObject: randomSaveObject },
                              { setRandomSaveObject: setRandomSaveObject },
                              { randomReset: randomReset }
                            );
                          }}
                          stroke={1.5}
                          size="1.8rem"
                        />
                      ) : (
                        <div>{/* Render something else or leave empty */}</div>
                      )}
                    </span>
                  ))}
              </div>
            ))
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // width: '100vw',
                height: '15vh'
              }}
            >
              <CircularProgress style={{ color: 'blue' }} />
            </div>
          )}
        </FormGroup>
        {error && <p style={{ color: theme.palette.error.main }}>Select Model</p>}
      </div>
    </CheckboxStyled>
  );
};

export default Checkboxes;
