import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setSnowflakePushDataToAzureData, setSnowflakePushDataToAzureStatus } = slice.actions;

const getSnowflakePushDataToAzure = (url: any, params: any) => async () => {
  //todo
  try {
    const response = await API.snowflakePushDataToAzure.getSnowflakePushDataToAzure(url, params);
    dispatch(setSnowflakePushDataToAzureData(response.data));
    dispatch(setSnowflakePushDataToAzureStatus(response.status));
  } catch (error: any) {
    dispatch(setSnowflakePushDataToAzureData(error?.response?.data));
    dispatch(setSnowflakePushDataToAzureStatus(error?.response?.status));
  }
};

export default {
  getSnowflakePushDataToAzure
};
