import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.deployStatus;

const deployStatusData = createSelector([selector], (state) => state);

export default {
  deployStatusData
};
