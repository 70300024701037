import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const modelSummarySaveManager = {
  getModelSummarySave(params: any, url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'PUT',
      data: params
    });
  }
};

export default modelSummarySaveManager;
