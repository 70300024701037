import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { TrainStatusProps } from '../../../types/reduxTypes/trainStatus';

const createReducer = <T extends SliceCaseReducers<TrainStatusProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setTrainStatusData(state, action) {
    state.trainStatusData = action.payload;
  }
});

export default reducers;
