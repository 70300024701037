import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.predictionStatus;

const predictionStatusData = createSelector([selector], (state) => state);

export default {
  predictionStatusData
};
