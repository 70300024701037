import React, { useEffect, useState } from 'react';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { IAnalyzeChartData, IDashboardProps } from '../../types';
import { removeLastExtension } from '../../helpers/removeExtension';

export const lineChartOptions = {
  chart: {
    height: 350,
    type: 'line',
    animations: {
      enabled: true, // Enable animations for lines
      speed: 1000 // Set the animation time in milliseconds (e.g., 2000 ms)
    },
    toolbar: {
      show: true,
      tools: {
        download: true, // Show the download icon
        selection: false, // Hide the selection icon
        zoom: false, // Hide the zoom icon
        zoomin: false, // Hide the zoom-in icon
        zoomout: false, // Hide the zoom-out icon
        pan: false, // Hide the pan (hand) icon
        reset: false // Hide the reset (home) icon
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'straight'
  },
  yaxis: {
    labels: {
      formatter: function (val: any) {
        return val.toFixed(2); // Formats the value to two decimal places
      }
    }
  }
};

// ==============================|| LINE CHART ||============================== //

const LineChart = (props: IAnalyzeChartData) => {
  const { analyzeManagerChartData } = props;
  const theme = useTheme();
  // @ts-ignore
  const primary = theme.palette.blue.light;
  const darkLight = theme.palette.dark.light;
  const grey200 = theme.palette.grey[200];
  const grey800 = theme.palette.grey[800];
  const secondary = theme.palette.secondary.main;
  const error = theme.palette.error.main;

  const [series, setSeries] = useState<any>([]);

  const [options, setOptions] = useState<ChartProps>(lineChartOptions);

  useEffect(() => {
    if (props.isSum) {
      setSeries([
        {
          name: `${removeLastExtension(props.modelSummaryData.successfully?.model_name)}`,
          data:
            props.modelSummaryData &&
            props?.modelSummaryData?.successfully?.summary?.ROC_AUC_curve?.fpr.map((val: any, idx: any) => [
              val,
              props.modelSummaryData.successfully.summary?.ROC_AUC_curve?.tpr[idx]
            ])
        },
        {
          name: 'Random Classifier',
          data:
            props.modelSummaryData &&
            props?.modelSummaryData?.successfully?.summary?.ROC_AUC_curve?.p_fpr.map((val: any, idx: any) => [
              val,
              props.modelSummaryData.successfully.summary?.ROC_AUC_curve?.p_tpr[idx]
            ])
        }
      ]);
      setOptions((prevState) => ({
        ...prevState,
        colors: [primary, secondary, '#003B0A'],
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5]
        },
        xaxis: {
          // min: 0,
          // max: 1.0,
          tickAmount: 10,
          labels: {
            formatter: (val: any) => {
              return val.toFixed(1);
            },
            style: {
              colors: [grey800]
            }
          },
          title: {
            text: 'False Positive Rate',
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: 'black',
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title'
            }
          }
        },
        yaxis: {
          // min: 0,
          // max: 1,
          tickAmount: 11,
          labels: {
            formatter: (val: any) => {
              return val.toFixed(1);
            },
            style: {
              colors: [grey800]
            }
          },
          title: {
            text: 'True Positive Rate',
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: 'black',
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title'
            }
          }
        },
        grid: {
          show: true,
          borderColor: 'grey',
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          theme: 'light'
        }
      }));
    } else {
      if (Object.keys(analyzeManagerChartData).length === 0) {
        const initialObject = {
          name: 'Series 1',
          data: [
            [0, 0],
            [1, 1]
          ]
        };
        const initialArray: any = [];
        initialArray.push(initialObject);
        setSeries(() => {
          for (const data in analyzeManagerChartData) {
            initialArray.push({
              name: data,
              data: analyzeManagerChartData[data].map((val: any, idx: any) => [
                val,
                analyzeManagerChartData[data][idx + 1]
              ])
            });
          }
          return initialArray;
        });
      } else {
        setSeries([
          {
            name: 'Random Classifier',
            data: [
              [0, 0],
              [1, 1]
            ]
          },
          ...Object.keys(analyzeManagerChartData).map((key, idx) => ({
            name: removeLastExtension(key),
            data: analyzeManagerChartData[key]
          }))
        ]);
      }
    }
  }, [analyzeManagerChartData, props.modelSummaryData]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [primary, secondary, '#003B0A'],
      xaxis: {
        // min: 0,
        // max: 1.0,
        tickAmount: 10,
        labels: {
          formatter: (val: any) => {
            return val.toFixed(1);
          },
          style: {
            colors: [grey800]
          }
        },
        title: {
          text: 'False Positive Rate',
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'black',
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title'
          }
        }
      },
      yaxis: {
        // min: 0,
        // max: 1,
        tickAmount: 11,
        labels: {
          formatter: (val: any) => {
            return val.toFixed(1);
          },
          style: {
            colors: [grey800]
          }
        },
        title: {
          text: 'True Positive Rate',
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'black',
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title'
          }
        }
      },
      grid: {
        show: true,
        borderColor: 'grey',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      tooltip: {
        theme: 'light'
      }
    }));
  }, [error, primary, '#00FF00', darkLight, grey200, grey800, '#00FF00', secondary]);
  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default LineChart;
