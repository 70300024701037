import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.predictionProgress;

const predictionProgressData = createSelector([selector], (state) => state);

export default {
  predictionProgressData
};
