import React, { memo, useEffect, useMemo } from 'react';
import { Avatar, Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { dispatch, useAppSelector } from 'store/hooks';
import { viewsMiddleware, viewsSelector } from 'store/slices/menu';

import MenuCard from '../../MenuCard';
import MenuList from '../../MenuList';
import { IconArrowBarLeft, IconArrowBarRight, IconMenu2 } from '@tabler/icons';
import API from '../../../manager/API';
import {
  getAllResourcesURL,
  snowflakePushDataToAzureURL,
  userId
} from '../../../modals/DatasetModal/DatasetModalSteps/Step4/Step4.constants';
import { toast } from 'react-toastify';
import { resourcesMiddleware } from '../../../store/slices/resources';
import * as CryptoJS from 'crypto-js';

interface SidebarProps {
  window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const drawerWidth = 260;
  const { drawerOpen } = useAppSelector(viewsSelector.menu);

  const container = window !== undefined ? () => window.document.body : undefined;

  async function onAvatarClick() {
    dispatch(viewsMiddleware.openMenuDrawer(!drawerOpen));
  }

  const drawerSx = useMemo(
    () => ({
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        // background: theme.palette.secondary.light,
        color: theme.palette.text.primary,
        borderRight: 'none',
        // [theme.breakpoints.up('md')]: {
        //   top: '73px'
        // },
        padding: '0 5px',
        overflowX: 'hidden'
      }
    }),
    [theme.breakpoints, theme.palette.text.primary]
  );

  useEffect(() => {
    if (!matchUpMd) {
      dispatch(viewsMiddleware.openMenuDrawer(false));
    }
  }, [matchUpMd]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(viewsMiddleware.openMenuDrawer(!drawerOpen))}
        sx={drawerSx}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerOpen && (
          <Box
            // className="main-box"
            style={{
              position: 'absolute',
              zIndex: '1000',
              top: '160px',
              left: '234px'
            }}
          >
            <Avatar onClick={onAvatarClick} style={{ backgroundColor: 'rgb(195, 205, 255)' }}>
              <IconArrowBarLeft size="1.3rem" style={{ cursor: 'pointer', color: '#000' }} />
            </Avatar>
          </Box>
        )}
        <MenuList />
        <MenuCard />
      </Drawer>
      {!drawerOpen && (
        <Box
          style={{
            position: 'absolute',
            zIndex: '1000',
            top: '160px',
            left: '-15px'
          }}
        >
          <Avatar onClick={onAvatarClick} style={{ backgroundColor: 'rgb(195, 205, 255)' }}>
            <IconArrowBarRight size="1.3rem" style={{ cursor: 'pointer', color: '#000' }} />
          </Avatar>
        </Box>
      )}
    </Box>
  );
};

export default memo(Sidebar);
