import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setModelTrainData } = slice.actions;

const getModelTrain = (params: any, url: any) => async () => {
  try {
    const response = await API.modelTrain.getModelTrain(params, url);
    dispatch(setModelTrainData(response.data));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {}
};

export default {
  getModelTrain
};
