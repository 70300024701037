import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { SummaryProps } from '../../../types/reduxTypes/summary';

const createReducer = <T extends SliceCaseReducers<SummaryProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setSummaryData(state, action) {
    state.summaryData = action.payload;
  },
  setSummaryStatus(state, action) {
    state.summaryStatus = action.payload;
  }
});

export default reducers;
