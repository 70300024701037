import { IAction, SliceReducers } from 'store/store';
import { ModalName } from 'types/modals';
import { IOpenedAlert, IOpenedModal, ViewsProps } from 'types/reduxTypes/modals';

const actions: SliceReducers<ViewsProps> = {
  addModalToList<P>(state: ViewsProps, action: IAction<IOpenedModal<P>>) {
    state.modals.push(action.payload);
  },
  removeModalFromList(state: ViewsProps, action: IAction<ModalName>) {
    state.modals = state.modals.filter((modal) => modal.name !== action.payload);
  },
  updateToastNotificationState<P>(state: ViewsProps, action: IAction<IOpenedAlert<P>>) {
    state.toastNotificationPopUp = action.payload ? action.payload : { open: false, props: {} };
  }
};

export default actions;
