import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const predictionManager = {
  getPredictionData(url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'GET'
    });
  }
};

export default predictionManager;
