import React, { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Card, CardContent, Dialog, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';

import { IDatasetModal } from './DatasetModal.models';
import Step1 from './DatasetModalSteps/Step1/Step1.component';
import Step2 from './DatasetModalSteps/Step2/Step2.component';
import Step3 from './DatasetModalSteps/Step3/Step3.component';
import Step4 from './DatasetModalSteps/Step4/Step4.component';
import { icons, stepsCount, StepIconRoot, Connector } from './DatasetModal.constants';
import {
  cardContentStyles,
  cardContentSx,
  dialogTitleStyles,
  iconButtonStyles,
  stepperStyles
} from './DatasetModal.styles';

const DatasetModalComponent: FC<IDatasetModal> = ({ title, data, rowData, setNewArray, onClose }) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [step2Data, setStep2Data] = useState<Object>({
    user: '',
    password: '',
    account: '',
    warehouse: '',
    database: '',
    schema: ''
  }); //todo checked,
  const [step2EncryptedData, setStep2EncryptedData] = useState<Object>({}); //todo
  const [step3Data, setStep3Data] = useState<Object>({}); //todo checked,
  const [step4Data, setStep4Data] = useState<Object>({}); //todo

  function StepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <StepIconRoot ownerState={{ completed, active }} className={className}>
        {completed && <CheckIcon />}
        {active && icons[Number(props.icon)]}
        {!active && !completed && icons[Number(props.icon)]}
      </StepIconRoot>
    );
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open
      PaperProps={{ style: { overflowX: 'hidden', height: '800px', padding: '0' } }}
    >
      <Box style={{ height: '100%' }}>
        <DialogTitle style={dialogTitleStyles} id="draggable-dialog-title">
          {title}
          <IconButton onClick={onClose} sx={iconButtonStyles}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <div style={stepperStyles}>
            <Stepper alternativeLabel activeStep={activeStep - 1} connector={<Connector />}>
              {stepsCount.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon} />
                </Step>
              ))}
            </Stepper>
          </div>
          <CardContent sx={cardContentSx} style={cardContentStyles}>
            {activeStep === 1 && <Step1 setActiveStep={setActiveStep} />}
            {activeStep === 2 && (
              <Step2
                setActiveStep={setActiveStep}
                step2Data={step2Data}
                setStep2Data={setStep2Data}
                step2EncryptedData={step2EncryptedData}
                setStep2EncryptedData={setStep2EncryptedData}
              />
            )}
            {activeStep === 3 && (
              <Step3
                setActiveStep={setActiveStep}
                step2Data={step2Data}
                step2EncryptedData={step2EncryptedData}
                step3Data={step3Data}
                setStep3Data={setStep3Data}
                setStep4Data={setStep4Data}
              />
            )}
            {activeStep === 4 && (
              <Step4
                setActiveStep={setActiveStep}
                data={data}
                rowData={rowData}
                setNewArray={setNewArray}
                onClose={onClose}
                step2Data={step2Data}
                step2EncryptedData={step2EncryptedData}
                step3Data={step3Data}
                step4Data={step4Data}
              />
            )}
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  );
};

export default DatasetModalComponent;
