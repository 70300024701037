import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const dialogTitleStyles: React.CSSProperties = {
  padding: '0',
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
  backgroundImage: 'linear-gradient(180deg, #334063 100%, #6B7A97 54.5%, #C3C3F0 100%)',
  color: 'transparent',
  WebkitBackgroundClip: 'text',
  lineHeight: 'normal',
  fontWeight: '400',
  fontSize: '32px',
  fontFamily: 'Circular Std Book'
};

export const stepperStyles: React.CSSProperties = {
  marginTop: '30px',
  marginBottom: '30px'
};

export const iconButtonStyles: SxProps<Theme> = {
  position: 'absolute',
  right: 20,
  top: 10
};

export const cardContentStyles: React.CSSProperties = {
  padding: '0',
  display: 'flex',
  justifyContent: 'center'
};
export const cardContentSx: SxProps<Theme> = {
  overflowX: 'hidden'
};
