import React, { Suspense, useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
// assets
import { Tabs } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
// project importsss
import MainCard from 'components/MainCard';

import AnalyzeManager from './AnalyzeManager';
import DatasourceManager from './DatasourceManager';
import ModelManager from './ModelManager';

const ManagerPage = () => {
  const { page } = useParams();
  const theme = useTheme();
  const renderActiveTab = useCallback(() => {
    let tab = null;

    switch (page) {
      case 'datasource': {
        tab = <DatasourceManager />;
        break;
      }

      case 'model': {
        tab = <ModelManager />;
        break;
      }

      case 'analyze': {
        tab = <AnalyzeManager />;
        break;
      }

      default: {
        tab = <DatasourceManager />;
      }
    }

    return <Suspense>{tab}</Suspense>;
  }, [page]);

  return (
    <MainCard>
      <div className="reports-sidebar bg-white shadow rounded">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          variant="scrollable"
          sx={{
            mb: 3,
            '& a': {
              minHeight: 'auto',
              minWidth: 10,
              py: 1.5,
              px: 1,
              mr: 2.25,
              color: theme.palette.grey[600],
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              transitionDuration: '0.7s'
            },
            '& a.Mui-selected': {
              color: theme.palette.secondary.light
            },
            '& .MuiTabs-indicator': {
              bottom: 2
            },
            '& a > svg': {
              marginBottom: '0px !important',
              mr: 1.25
            }
          }}
        >
          {/*<NavLink*/}
          {/*  style={{*/}
          {/*    textDecoration: 'none',*/}
          {/*    fontSize: '24px',*/}
          {/*    fontWeight: '600',*/}
          {/*    backgroundColor: page === 'datasource' ? theme.palette.secondary.light : '',*/}
          {/*    borderRadius: '4%'*/}
          {/*  }}*/}
          {/*  to="/manager/datasource"*/}
          {/*>*/}
          {/*  Data Sources*/}
          {/*</NavLink>*/}
          <NavLink
            style={{
              textDecoration: 'none',
              fontSize: '24px',
              fontWeight: '600',
              backgroundColor: page === 'model' ? theme.palette.secondary.light : '',
              borderRadius: '9px'
            }}
            to="/manager/model"
            className="tab"
          >
            Model building and deployment
          </NavLink>
          <NavLink
            style={{
              textDecoration: 'none',
              fontSize: '24px',
              fontWeight: '600',
              backgroundColor: page === 'analyze' ? theme.palette.secondary.light : '',
              borderRadius: '9px'
            }}
            to="/manager/analyze"
          >
            Result assessment
          </NavLink>
        </Tabs>
      </div>
      <div>{renderActiveTab()}</div>
    </MainCard>
  );
};

export default ManagerPage;
