import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const formContainerStyles: React.CSSProperties = {
  width: '100%'
};

export const containerStyles: React.CSSProperties = {
  flexDirection: 'column',
  padding: '0 50px',
  display: 'flex',
  color: '#000'
};

export const contentStyles: React.CSSProperties = {
  height: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '50%',
  margin: '0 auto'
};

export const titleStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal'
};

export const formStyles: React.CSSProperties = {
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
};

export const preproccessingStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  // flexDirection: 'column',
  alignItems: 'center',
  gap: '60px'
};

export const preproccessing1Styles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
};

export const formOptionStyles: React.CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const radioButtonStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px'
};
export const radioButtonLastChildStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  marginLeft: '-73px'
};

export const inputStyles: React.CSSProperties = {
  width: '18px',
  height: '18px',
  fill: '#FFF',
  strokeWidth: '1px',
  stroke: '#000',
  marginRight: '10px',
  accentColor: '#334063'
};

export const span1Styles: React.CSSProperties = {
  color: '#E9271B',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  textAlign: 'center'
};
export const spanStyles: React.CSSProperties = {
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  marginRight: '45px',
  paddingTop: '4px'
};

export const spanLastStyles: React.CSSProperties = {
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  paddingTop: '4px'
};

export const backButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '60px',
  borderRadius: '8px',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  backgroundColor: '#334063',
  color: '#fff'
};

export const nextButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '60px',
  borderRadius: '8px',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  backgroundColor: '#334063',
  color: '#fff',
  float: 'right'
};

export const descriptionStyles: React.CSSProperties = {
  color: '#000',
  margin: '0',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  textAlign: 'center'
};

export const descriptionLastStyles: React.CSSProperties = {
  color: '#000',
  marginTop: '30px',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  textAlign: 'center'
};

export const buttonContainerStyles: SxProps<Theme> = {};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};
