import { IBlock } from './Step32.models';

export const emptyBlock = (block?: Partial<IBlock>): IBlock => ({
  id: 0,
  inputValue: '',
  category: '',
  radioButtonValue: null,
  customValue: '',
  ...block
});
