import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const modelSummaryArraysManager = {
  getModelSummaryArrays(url: any) {
    return axiosInstance.v2.get(url);
  }
};

export default modelSummaryArraysManager;
