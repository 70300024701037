import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { UserProps } from '../../../types/reduxTypes/user';

const createReducer = <T extends SliceCaseReducers<UserProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  getUsers(state, action) {
    state.id = action.payload;
  }
});

export default reducers;
