import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setModelListData, setModelListStatus } = slice.actions;

const getModelList = (url: any) => async () => {
  try {
    const response = await API.modelList.getModelList(url);
    dispatch(setModelListData(response.data));
    dispatch(setModelListStatus(response.status));
  } catch (error) {}
};

export default {
  getModelList
};
