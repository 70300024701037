import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const downloadManager = {
  downloadFile(url: any) {
    return axiosInstance.v2.post(url);
  }
};

export const downloadFile = (url: any, params = {}, rest = { responseType: 'blob' }) => {
  return axiosInstance.v2.post(url, params, { responseType: 'blob' });
};

export default downloadManager;
