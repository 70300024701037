import API from '../../../../manager/API';
import { toast } from 'react-toastify';
import React, { FC, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { Cookies } from 'react-cookie';

import {
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  formContainerStyles,
  loadingContainerStyles,
  loadingIconStyles,
  titleStyles
} from './Step3.styles';
import { IStep3 } from './Step3.models';
import { snowflakeGetQueryURL } from './Step3.constants';
import QuerySelector from './QuerySelector/QuerySelector.component';
import { removeLastExtension } from '../../../../helpers/removeExtension';

const Step3: FC<IStep3> = ({ setActiveStep, step2Data, step3Data, setStep3Data, setStep4Data, step2EncryptedData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const cookies = new Cookies();

  const [value, setValue] = useState<string>(cookies.get('step3Data') ? cookies.get('step3Data').query : '');

  const onBackClick = () => {
    setActiveStep(2);
  };

  async function onSubmit() {
    setLoading(true);
    try {
      const response = await API.snowflakeGetQuery.getSnowflakeGetQuery(snowflakeGetQueryURL, {
        ...step2EncryptedData,
        ...step3Data
      });
      toast.success(removeLastExtension(response.data.message));
      setStep4Data(response);
      setActiveStep(4);
      cookies.set('step3Data', JSON.stringify(step3Data), { path: '/' });
    } catch (error: any) {
      toast.error(removeLastExtension(error?.response?.data?.message));
    }
    setLoading(false);
  }

  useEffect(() => {
    setStep3Data({ query: value });
  }, [value]);

  return (
    <>
      {!loading ? (
        <Grid item xs={12} md={12} style={containerStyles}>
          <Grid item xs={12}>
            <h2 style={titleStyles}>Query to pull data</h2>
          </Grid>
          <Grid item style={formContainerStyles}>
            <QuerySelector value={value} setValue={setValue} />
          </Grid>
          <Grid item xs={12} sx={buttonContainerStyles}>
            <Button
              variant="contained"
              size="large"
              style={{
                width: '120px',
                height: '60px',
                borderRadius: '8px',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                backgroundColor: '#334063',
                color: '#fff'
              }}
              sx={buttonStyles}
              onClick={onBackClick}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                width: '120px',
                height: '60px',
                borderRadius: '8px',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                backgroundColor: value.length === 0 ? '#d9d9d9' : '#334063',
                color: value.length === 0 ? '#635F5F' : '#fff',
                float: 'right'
              }}
              sx={buttonStyles}
              type="submit"
              onClick={onSubmit}
              disabled={value.length === 0}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      ) : (
        <div style={loadingContainerStyles}>
          <CircularProgress style={loadingIconStyles} />
        </div>
      )}
    </>
  );
};

export default Step3;
