import React, { FC, useEffect } from 'react';
import Dropdown from 'rc-dropdown';
import { TextareaAutosize, TextField } from '@mui/material';

import { keys } from './QuerySelector.constants';
import { IQuerySelector } from './QuerySelector.models';
import { dropdownStyles, hideStyles, suggestionStyles, textFieldStyles, resetStyles } from './QuerySelector.styles';
import { inputErrorStyles } from '../Step3.styles';

const QuerySelector: FC<IQuerySelector> = ({ value, setValue }) => {
  const [keyWords, setKeywords] = React.useState([...keys]);
  const [visible, setVisible] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const ref = React.createRef();

  function onResetClick() {
    setValue('')
  }

  const handleBlur = () => {
    setTouched(true)
  };

  const onClick = ({ target }: React.MouseEvent<HTMLParagraphElement>) => {
    setVisible(false);
    setValue((value: string) => {
      if (value !== '') {
        let splittedArray = value.split(' ');
        const newValue = value.slice(0, value.length - splittedArray[splittedArray.length - 1].length);
        return newValue + (target as HTMLParagraphElement).innerText;
      }
      return value;
    });
  };

  useEffect(() => {
    if (keyWords.includes(value)) {
      setVisible(false);
    }
  }, [keyWords]);

  return (
    <>
      <style>
        {`
          .suggestion:hover {
            background-color: #f0f8ff; /* Add your hover background color here */
            border-left: 3px solid #007BFF; /* Add your hover border color here */
          }
        `}
      </style>
      <label htmlFor="query" style={{ marginBottom: '10px' }}>
        SQL Query:
      </label>
      <TextareaAutosize
        onBlur={handleBlur}
        style={textFieldStyles}  // You might need to adjust the styles based on TextArea
        value={value}
        onKeyDown={(event) => {}}
        onChange={({ target }) => {
          const curVal = target.value.trim();
          setValue(target.value);

          if (!keyWords.includes(value) && curVal !== '') {
            const splittedArray = curVal.split(' ');
            const lastItem = splittedArray[splittedArray.length - 1];
            const currentKeys = keys.filter((key) => key.toLowerCase().includes(lastItem.toLowerCase()));
            setKeywords(currentKeys);
            setVisible(true);
          }
        }}
      />
      {touched && value.length === 0 && (
        <span style={inputErrorStyles}>Query is required</span>
      )}
      <p onClick={onResetClick} style={resetStyles}>
        Reset
      </p>
      {!visible || value.endsWith(' ') || value.trim() !== '' &&
        (keyWords.length > 0 && (
          <Dropdown
            ref={ref}
            visible={visible}
            trigger={['click']}
            getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
            overlay={
              <div style={dropdownStyles}>
                {keyWords.map((key, index) => (
                  <p
                    className="suggestion"
                    style={{
                      ...suggestionStyles
                    }}
                    key={index}
                    onClick={onClick}
                  >
                    {key}
                  </p>
                ))}
              </div>
            }
          >
            <>
              <p style={hideStyles}> </p>
              <p style={hideStyles}> </p>
            </>
          </Dropdown>
        ))}
    </>
  );
};

export default QuerySelector;
