import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setSummaryData, setSummaryStatus } = slice.actions;

const getSummary = (url: any) => async () => {
  try {
    const response = await API.summary.getSummary(url);
    dispatch(setSummaryData(response.data));
    dispatch(setSummaryStatus(response.status));
    return response;
  } catch (error) {}
};

export default {
  getSummary
};
