import React from 'react';

export const containerStyles: React.CSSProperties = {
  paddingLeft: '50px',
  display: 'flex',
  color: '#000',
  position: 'relative'
};

export const dropdownRadioStyles: React.CSSProperties = {
  width: '100%'
};

export const dropdownStyles: React.CSSProperties = {
  marginTop: '30px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
};

export const removeIconStyles: React.CSSProperties = {
  color: 'red',
  paddingTop: '75px',
  display: 'flex',
  alignItems: 'flex-start'
};

export const autocompleteStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end'
};
