import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import { useFormik, Formik, Form } from 'formik';

import {
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  errorStyles,
  formContainerStyles,
  formStyles, inputStyles,
  logoStyles,
  radioButtonStyles, spanStyles,
  titleStyles
} from './Step1.styles';
import snowflakeLogo from '../../../../assets/img/1.png';
import { IStep1 } from './Step1.models';
import { initialValues, validationSchema } from './Step1.constants';


const Step1: FC<IStep1> = ({ setActiveStep }) => {

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  const { values, handleChange, errors, touched } = formik;

  function onSubmit(){
    setActiveStep(2);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form style={formContainerStyles}>
        <Grid item xs={12} md={12} style={containerStyles}>
          <Grid item xs={12}>
            <h2 style={titleStyles}>Database</h2>
          </Grid>
          <Grid item xs={12} md={12} style={formStyles}>
            <label style={radioButtonStyles}>
              <input
                type="radio"
                name="database"
                value="snowflake"
                checked={values.database === 'snowflake'}
                onChange={handleChange}
                style={inputStyles}
              />
              <img src={snowflakeLogo} alt="snowflake" style={logoStyles} />
              <span style={spanStyles}>Snowflake</span>
            </label>
            {touched.database && errors.database && <div style={errorStyles}>{errors.database}</div>}
          </Grid>
          <Grid item xs={12} md={12} sx={buttonContainerStyles}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{
                width: '120px',
                height: '60px',
                borderRadius: '8px',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                backgroundColor: !values.database ? '#d9d9d9' : '#334063',
                color: !values.database ? '#635F5F' : '#fff',
                float: 'right'
              }}
              sx={buttonStyles}
              onClick={onSubmit}
              disabled={!values.database}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default Step1;
