import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { SnowflakePushDataToAzureProps } from '../../../types/reduxTypes/snowflakePushDataToAzure';

const createReducer = <T extends SliceCaseReducers<SnowflakePushDataToAzureProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setSnowflakePushDataToAzureData(state, action) {
    state.snowflakePushDataToAzureData = action.payload;
  },
  setSnowflakePushDataToAzureStatus(state, action) {
    state.snowflakePushDataToAzureStatus = action.payload;
  }
});

export default reducers;