import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const modelViewManager = {
  getModelView(url: any) {
    return axiosInstance.v2.get(url);
  }
};

export default modelViewManager;
