import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { PredictionProgressProps } from '../../../types/reduxTypes/predictionProgress';

const createReducer = <T extends SliceCaseReducers<PredictionProgressProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setPredictionProgressData(state, action) {
    state.predictionProgressData = action.payload;
  },
  setPredictionProgressStatus(state, action) {
    state.predictionProgressStatus = action.payload;
  }
});

export default reducers;
