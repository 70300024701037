import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.preview;

const previewData = createSelector([selector], (state) => state);

export default {
  previewData
};
