import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ModelSummaryArraysProps } from '../../../types/reduxTypes/modelSummaryArrays';

const createReducer = <T extends SliceCaseReducers<ModelSummaryArraysProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setModelSummaryArraysData(state, action) {
    state.modelSummaryArraysData = action.payload;
  },
  setModelSummaryArraysStatus(state, action) {
    state.modelSummaryArraysStatus = action.payload;
  }
});

export default reducers;
