import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const formContainerStyles: React.CSSProperties = {
  width: '100%'
};

export const containerStyles: React.CSSProperties = {
  flexDirection: 'column',
  padding: '0 50px',
  display: 'flex',
  color: '#000'
};

export const contentStyles: React.CSSProperties = {
  maxHeight: '500px',
  minHeight: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '50%',
  margin: '0 auto'
};

export const titleStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  marginBottom: '40px'
};
export const descriptionStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  justifyContent: 'center',
  marginBottom: '16px'
};

export const formStyles: React.CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '115px'
};

export const uploadFileDescriptionStyles: React.CSSProperties = {
  marginTop: '8px'
};

export const uploadButtonStyles: React.CSSProperties = {
  color: '#fff',
  backgroundColor: '#202090',
  marginRight: '10px'
};

export const radioButtonStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center'
};
export const inputStyles: React.CSSProperties = {
  width: '16px',
  height: '16px',
  fill: '#FFF',
  strokeWidth: '1px',
  stroke: '#000',
  marginRight: '5px',
  accentColor: '#334063'
};

export const logoStyles: React.CSSProperties = {
  width: '16px',
  height: '16px',
  marginRight: '5px'
};
export const dropdownStyles: React.CSSProperties = {
  width: '472px',
  padding: 0
};

export const spanStyles: React.CSSProperties = {
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal'
};

export const errorStyles: React.CSSProperties = {
  color: 'red'
};

export const buttonContainerStyles: SxProps<Theme> = {};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};

export const redirectStyles: React.CSSProperties = {
  cursor: 'pointer',
  color: '#334063',
  fontStyle: 'italic',
  textDecoration: 'underline'
};

export const orStyles: React.CSSProperties = {
  cursor: 'auto',
  color: '#000',
  fontStyle: 'normal',
  textDecoration: 'none'
};
export const redirectParentStyles: React.CSSProperties = {
  marginTop: '20px',
  float: 'right',
  marginLeft: '215px',
  fontWeight: '400',
  fontSize: '12px',
  fontFamily: 'Circular Std Book'
};
