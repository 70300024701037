import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.previewStatus;

const previewStatusData = createSelector([selector], (state) => state);

export default {
  previewStatusData
};
