import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const undeployManager = {
  getUndeploy(url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'POST'
    });
  }
};

export default undeployManager;
