import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';
import { toast } from 'react-toastify';

export const { setPreviewData, setPreviewStatus } = slice.actions;

const getPreview = (url: any) => async () => {
  try {
    const response = await API.preview.getPreview(url);
    dispatch(setPreviewData(response.data));
    dispatch(setPreviewStatus(response.status));
    return response;
  } catch (error) {
    toast.error('Something went wrong!');
  }
};

export default {
  getPreview
};
