import { styled } from '@mui/material/styles';

const DatasourceMenuStyled = styled('div')(({ theme }) => ({
  '.main-component': {
    backgroundColor: theme.palette.primary.light,
    marginTop: '8px',
    borderRadius: '8px'
  },

  '.progress-bar': {
    padding: '70px 230px'
  },

  '.title': {
    fontSize: '18px',
    fontWeight: '600'
  },

  '.clear-button': {
    margin: '54px auto',
    height: '52px',
    background: theme.palette.warning.dark,
    '&:hover': { background: theme.palette.warning.main },
    color: 'grey.900'
  },

  '.progressing-button': {
    margin: '54px auto',
    height: '52px'
  },

  '.grid-box': {
    paddingRight: '16px',
    paddingLeft: '16px'
  }
}));

export default DatasourceMenuStyled;
