import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { modelAddingStatusProps } from '../../../types/reduxTypes/modelAddingStatus';

const createReducer = <T extends SliceCaseReducers<modelAddingStatusProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setModelAddingStatusData(state, action) {
    state.modelAddingStatusData = action.payload;
  }
});

export default reducers;
