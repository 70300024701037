import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ModelSummarySaveProps } from '../../../types/reduxTypes/modelSummarySave';

const createReducer = <T extends SliceCaseReducers<ModelSummarySaveProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setModelSummarySaveData(state, action) {
    state.modelSummarySaveData = action.payload;
  },
  setModelSummarySaveStatus(state, action) {
    state.modelSummarySaveStatus = action.payload;
  }
});

export default reducers;
