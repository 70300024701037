import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { HealthProps } from '../../../types/reduxTypes/health';

const createReducer = <T extends SliceCaseReducers<HealthProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setHealthData(state, action) {
    state.healthData = action.payload;
  }
});

export default reducers;
