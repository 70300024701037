import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const trainModelManager = {
  getModelTrain(params: any, url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'POST',
      data: params
    });
  }
};

export default trainModelManager;
