import React, { useEffect, useState } from 'react';

import { PlusIconButton } from '../../components/Buttons';
import DatasetMenu from '../../components/DatasetMenu';
import MainDashboard from '../../components/MainDashboard/index';
import { ModelManagerDashboardHeader, ModelManagerDashboardSearch } from '../../helpers/dashboardData';
import { dispatch, useAppSelector } from '../../store/hooks';
import { HeadCell, IDashboardAction, IDashboardProps } from '../../types';
import { userModelsMiddleware, userModelsSelector } from '../../store/slices/userModels';
import {
  deleteDialogOpen,
  deployUndeployButton,
  inferenceDialogOpen,
  modelViewDialogOpen,
  summaryDialogOpen,
  urlDialogOpen
} from '../../components/MainDashboard/Actions/ModelActions';
import { set } from 'husky';
import { toast } from 'react-toastify';
import { uploadMiddleware } from '../../store/slices/upload';
import { uploadStatusMiddleware } from '../../store/slices/uploadStatus';
import { storageMiddleware } from '../../store/slices/storage';
import { Download } from '@mui/icons-material';
import { modalsMiddleware } from '../../store/slices/modals';
import { SeveritiesType } from '../../types/modals';
import { previewStatusSelector } from '../../store/slices/previewStatus';
import { deployStatusSelector } from '../../store/slices/deployStatus';
import { Product } from '../../types/customer';

interface Model {
  id: number;
}

const ModelManager = () => {
  const [modelsCount, setModelsCount] = useState<Model[]>([]);
  const [countError, setCountError] = useState<boolean>(false);
  const [a, setA] = useState<boolean>(false);
  const [rowDataStatus, setRowDataStatus] = useState({});

  const { userModelsData } = useAppSelector(userModelsSelector.userModelsData);

  const getUserModelsURL = `/datasource/user_models`;
  const [userModelsArray, setUserModelsArray] = useState<any>([]);
  const { deployStStatus } = useAppSelector(deployStatusSelector.deployStatusData);

  useEffect(() => {
    dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
  }, []);

  useEffect(() => {
    if (userModelsData && userModelsData.length > 0) {
      const newArray1 = userModelsData.map((object: any, index: any) => {
        return { id: index + 1, ...object };
      });
      setUserModelsArray(newArray1);
    } else {
      setUserModelsArray([]);
    }
  }, [userModelsData]);

  const toastNotification = () => {
    dispatch(
      modalsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: 'Hello, i am error'
        }
      })
    );
  };

  const isSaved = userModelsArray.map((item: any) => item.model_status === 'saved');

  const [modelActions, setModelActions] = useState<IDashboardAction[]>([
    {
      id: 'View',
      title: 'View',
      name: 'view',
      style: {
        backgroundColor: '#FCA311',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#F77F00'
        }
      },
      color: 'primary',
      state: true,
      openFunction: modelViewDialogOpen
    },
    {
      id: 'Summary',
      title: 'Summary',
      name: 'summary',
      style: {
        backgroundColor: '#3DA5D9',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#2364AA'
        }
      },
      color: 'primary',
      state: true,
      openFunction: summaryDialogOpen
    },
    {
      id: 'Inference',
      title: 'Predict',
      name: 'inference',
      style: {
        backgroundColor: '#0EAD96',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#1A936F'
        }
      },
      color: 'error',
      state: true,
      openFunction: inferenceDialogOpen
    },
    {
      id: 'View_url',
      title: 'URL',
      name: 'View URL',
      style: {
        color: '#ffffff',
        backgroundColor: '#FCA311',
        '&:hover': {
          backgroundColor: '#F77F00'
        }
      },
      color: 'secondary',
      state: true,
      openFunction: urlDialogOpen
    },
    {
      id: isSaved ? 'Deploy' : 'Undeploy',
      title: isSaved ? 'Deploy' : 'Undeploy',
      name: 'deploy',
      style: {
        color: '#ffffff',
        backgroundColor: '#0EAD96',
        '&:hover': {
          backgroundColor: '#1A936F'
        }
      },
      color: 'secondary',
      state: true,
      openFunction: toastNotification,
      valueOnClick: {
        hideMe: true,
        field: isSaved ? 'Undeploy' : 'Deploy'
      }
    },
    {
      id: !a ? 'Undeploy' : 'Deploy',
      title: 'Undeploy',
      name: 'undeploy',
      style: {
        backgroundColor: '#FCA311',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#F77F00'
        }
      },
      color: 'warning',
      state: false,
      // openFunction: deployUndeployButton,
      valueOnClick: {
        hideMe: true,
        field: 'Deploy'
      }
    },
    {
      id: 'Drop',
      title: 'Drop',
      name: 'drop',
      style: {
        backgroundColor: '#EF233C',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#D90429'
        }
      },
      color: 'error',
      state: true,
      openFunction: deleteDialogOpen
    }
  ]);

  const onPlusClick = () => {
    if (!modelsCount.length) {
      setModelsCount([{ id: 0 }]);
    } else if (modelsCount.length < 10) {
      setModelsCount((prev) => [...prev, { id: modelsCount[modelsCount.length - 1].id + 1 }]);
    }
  };

  const deleteDataset = (modelId: any) => {
    setModelsCount((prev) => prev.filter((model) => model.id !== modelId));
  };

  return (
    <div>
      <PlusIconButton handlePlusButtonClick={onPlusClick} title="Add a new model" error={countError} />
      {modelsCount.map((model) => (
        <div key={model.id}>
          <DatasetMenu element={model.id} deleteDataset={deleteDataset} />
        </div>
      ))}
      <div style={{ marginTop: '16px' }}>
        <MainDashboard
          setUserModelsArray={setUserModelsArray}
          rowDataStatus={rowDataStatus}
          setRowDataStatus={setRowDataStatus}
          a={a}
          setA={setA}
          isComp={false}
          isModels
          minimize
          title="My Models"
          data={userModelsArray}
          tableHeaders={ModelManagerDashboardHeader}
          actions={modelActions}
          setActions={setModelActions}
          searchProps={ModelManagerDashboardSearch}
        />
      </div>
    </div>
  );
};

export default ModelManager;
