import React, { useState } from 'react';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import { lineChartOptions } from './LineChart';

const RegressionChart = ({ data }:any) => {
  const xData = data.successfully.summary.residual_visualisation.y_predicted;
  const yData = data.successfully.summary.residual_visualisation.y_true;

  // Combine x and y data into two separate series
  const series = [
    {
      name: 'Predicted Target',
      data: xData.map((value:any, index:any) => [index + 1, value]),
    },
    {
      name: 'Actual Target',
      data: yData.map((value:any, index:any) => [index + 1, value]),
    },
  ];

  const updatedOptions: ChartProps = {
    ...lineChartOptions,
    colors: ['#ff0000', '#0084ff'], // Specify your colors here
  };

  const [options, setOptions] = useState<ChartProps>(updatedOptions);

  return (
    <ReactApexChart options={options} series={series} type="line" width="500" />
  );
};

export default RegressionChart;
