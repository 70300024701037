import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const modelSettingsManager = {
  getModelSettings(url: any, params: any) {
    return axiosInstance.v2.post(url, params);
  }
};

export default modelSettingsManager;
