import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { UploadModelStatusProps } from '../../../types/reduxTypes/uploadModelStatus';

const createReducer = <T extends SliceCaseReducers<UploadModelStatusProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setUploadModelStatusData(state, action) {
    state.uploadModelStatusData = action.payload;
  }
});

export default reducers;
