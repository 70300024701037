import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setStorageData, setStorageStatus } = slice.actions;

const getStorage = (url: any) => async () => {
  try {
    const response = await API.storage.getStorage(url);
    dispatch(setStorageData(response.data));
    dispatch(setStorageStatus(response.status));
  } catch (error) {}
};

export default {
  getStorage
};
