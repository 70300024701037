import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.modelView;

const modelViewData = createSelector([selector], (state) => state);

export default {
  modelViewData
};
