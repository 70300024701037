import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const datasourceUsersManager = {
  getDatasourceColumns(url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'GET'
    });
  }
};

export default datasourceUsersManager;
