import { IconBrandAsana, IconDashboard, IconHome } from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconHome,
  IconBrandAsana
};

const dashboard = {
  id: 'default',
  title: '',
  // title: 'Default',
  type: 'group',
  children: [
    // {
    //   id: 'welcome',
    //   title: 'Welcome',
    //   type: 'item',
    //   url: '/',
    //   icon: icons.IconHome,
    //   breadcrumbs: false
    // },
    {
      id: 'resources',
      title: 'Datasets',
      type: 'item',
      url: '/resources',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'DataModeling',
      title: 'Model building and deployment',
      type: 'collapse',
      icon: icons.IconBrandAsana,
      children: [
        // {
        //   id: 'DataSources',
        //   title: 'Data Sources',
        //   type: 'item',
        //   url: '/manager/datasource'
        // },
        {
          id: 'ModelBuilding',
          title: 'Model building and deployment',
          type: 'item',
          url: '/manager/model'
        },
        {
          id: 'ModelComparison',
          title: 'Result assessment',
          type: 'item',
          url: '/manager/analyze'
        }
      ]
    }
  ]
};

export default dashboard;
