import React, { useState } from 'react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { IStep3Inner2 } from './Step3Inner2.models';
import CloseIcon from '@mui/icons-material/Close';
import { containerStyles, dropdownRadioStyles, dropdownStyles, removeIconStyles } from './Step3Inner2.styles';
import { emptyBlock } from '../Step32.helpers';
import { INanColumn } from '../../../ModelSetupModal.models';
import { setIn } from 'formik';

const Step3Inner2: React.FC<IStep3Inner2> = ({
  firstLabel,
  dropDownOptions,
  block,
  blocks,
  updateCurrentBlock,
  deleteBlock
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const blockIndex = blocks.findIndex((b: any) => b.id === block.id);

  function onColumnNameSelect(event: any, value: string | null) {
    if (value) {
      setInputValue(value);
    } else {
      setInputValue('');
    }
    const selectedOption = dropDownOptions.find((option) => option.name === value);
    if (!selectedOption || !value) return;
    const newBlock = emptyBlock({ id: block.id, inputValue: value, category: selectedOption.type });
    updateCurrentBlock(newBlock);
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newBlock = { ...block, radioButtonValue: value, customValue: '' };
    updateCurrentBlock(newBlock);
  };

  const handleCustomValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (block.category !== 'object') {
      const filteredValue = value.slice(0, 20).replace(/[^\d.]/g, '');
      const newBlock = { ...block, customValue: parseFloat(filteredValue) };
      updateCurrentBlock(newBlock);
    } else {
      const value = event.target.value;
      const filteredValue = value.replace(/\d/g, ''); // Remove all numeric characters
      const newBlock = { ...block, customValue: filteredValue };
      updateCurrentBlock(newBlock);
    }
  };

  return (
    <Grid style={containerStyles}>
      <div style={dropdownRadioStyles}>
        <Grid item style={dropdownStyles}>
          <p
            style={{
              color: '#333333',
              margin: '6px 0',
              width: '50%',
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Circular Std Book'
            }}
          >
            {firstLabel}
          </p>
          <Autocomplete
            options={dropDownOptions.map((option: INanColumn) => option.name)}
            value={block.inputValue || ''}
            clearOnEscape
            disableClearable={!block.inputValue} // Disable clear indicator when input is empty
            onChange={(event, value) => {
              if (value === null) {
                // Clear input value when clear indicator is clicked
                setInputValue('');
                updateCurrentBlock(emptyBlock({ id: block.id, inputValue: '', category: '' }));
              } else {
                onColumnNameSelect(event, value); // Call the existing handler
              }
            }}
            fullWidth
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontFamily: 'Circular Std Book' } // Style for input text
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ fontFamily: 'Circular Std Book' }}>
                {/* Style for dropdown options */}
                {option}
              </li>
            )}
            sx={{
              fontFamily: 'Circular Std Book' // This will set the font for the component
            }}
          />
        </Grid>
        <Grid
          style={{ display: 'flex', position: 'relative', justifyContent: 'space-between', width: '100%' }}
          item
          xs={12}
        >
          {block.inputValue && (
            <FormControl style={{ width: '100%' }}>
              <RadioGroup
                row
                name="actions"
                style={{ flexWrap: 'nowrap' }}
                value={block.radioButtonValue}
                onChange={handleRadioChange}
              >
                {block.category !== 'object' ? (
                  <>
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px' }}
                      value="mean"
                      control={<Radio />}
                      label="mean"
                    />
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px' }}
                      value="median"
                      control={<Radio />}
                      label="median"
                    />
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px', width: '100%' }}
                      value="custom value"
                      control={<Radio />}
                      label="custom value"
                    />
                  </>
                ) : (
                  <>
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px' }}
                      value="mode"
                      control={<Radio />}
                      label="mode"
                    />
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px', width: '100%' }}
                      value="custom value"
                      control={<Radio />}
                      label="custom value"
                    />
                  </>
                )}
                {/*{block.radioButtonValue === 'custom value' && (*/}
                <TextField
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    padding: '0',
                    marginTop: '6px',
                    visibility: block.radioButtonValue !== 'custom value' ? 'hidden' : 'visible'
                  }}
                  type={block.category !== 'object' ? 'number' : 'text'}
                  value={block.customValue}
                  onChange={handleCustomValueChange}
                  margin="normal"
                  fullWidth
                  onKeyDown={(e) => {
                    if (block.category !== 'object') {
                      if (e.key === '-' || e.key === 'e' || e.key === 'E' || e.key === '+') {
                        e.preventDefault();
                      }
                    }
                  }}
                  inputProps={{
                    maxLength: 20,
                    style: { height: '0px', borderRadius: '0 !important' }
                  }} // Ensure the user cannot type more than 20 characters
                />
                {/*)}*/}
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
      </div>
      {/*{Object.keys(blocks).length > 1 && (*/}
      <IconButton
        style={{
          color: 'red',
          paddingTop: '75px',
          display: 'flex',
          alignItems: 'flex-start',
          visibility: blockIndex > 0 ? 'visible' : 'hidden' // Only visible if it's not the first block
        }}
        onClick={() => deleteBlock(block.id, block.inputValue)}
        disableRipple={true}
      >
        <CloseIcon />
      </IconButton>
      {/*)}*/}
    </Grid>
  );
};

export default Step3Inner2;
