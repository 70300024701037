import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.modelSummary;

const modelSummaryData = createSelector([selector], (state) => state);

export default {
  modelSummaryData
};
