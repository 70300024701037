import { Axios } from '../axiosInstance';

const authURL = '/token_auth/me';
const axiosInstance = Axios();

const authManager = {
  getAuthData() {
    return axiosInstance.v2.get(`${authURL}`);
  }
};

export default authManager;
