export const MbConverter = (bytes: number): string => {
  if (bytes < 1048576) { // Less than 1 MB in bytes, represent in KB
    return (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes >= 1048576 && bytes < 1073741824) { // Less than 1 GB in bytes, represent in MB
    return (bytes / 1024 / 1024).toFixed(2) + ' MB';
  } else if (bytes >= 1073741824 && bytes < 1099511627776) { // Less than 1 TB in bytes, represent in GB
    return (bytes / 1024 / 1024 / 1024).toFixed(2) + ' GB';
  } else { // 1 TB and above, represent in TB
    return (bytes / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
  }
};
