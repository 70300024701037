import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';
import { toast } from 'react-toastify';
import { removeLastExtension } from '../../../helpers/removeExtension';

const { setPredictionData, setPredictionStatus } = slice.actions;

const getPredictionData = (url: string) => async () => {
  try {
    const response = await API.prediction.getPredictionData(url);
    dispatch(setPredictionData(response.data));
    dispatch(setPredictionStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    toast.error(removeLastExtension(error?.response.data.message));
  }
};

export default {
  getPredictionData
};
