import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ActionsPreviewProps } from '../../../types/reduxTypes/actionsPreview';

const createReducer = <T extends SliceCaseReducers<ActionsPreviewProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setActionsPreviewData(state, action) {
    state.actionsPreviewData = action.payload;
  },
  setActionsPreviewStatus(state, action) {
    state.actionsPreviewStatus = action.payload;
  }
});

export default reducers;
