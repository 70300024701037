import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.models;

const loadingModel = createSelector([selector], (state) => state.loadingModelId);

export default {
  loadingModel
};
