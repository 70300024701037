import { NavItemType } from "types";

import dashboard from "./dashboard";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard],
};

export default menuItems;
