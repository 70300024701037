import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { PreviewProps } from '../../../types/reduxTypes/preview';

const createReducer = <T extends SliceCaseReducers<PreviewProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setPreviewData(state, action) {
    state.previewData = action.payload;
  },
  setPreviewStatus(state, action) {
    state.previewStatus = action.payload;
  }
});

export default reducers;
