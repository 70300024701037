import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormik } from 'formik';
import { Button, Grid } from '@mui/material';
import { initialValues } from './Step4.constants';
import {
  backButtonStyles,
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  descriptionLastStyles,
  descriptionStyles,
  formContainerStyles,
  formOptionStyles,
  formStyles,
  inputStyles,
  nextButtonStyles,
  preproccessing1Styles,
  preproccessingStyles,
  radioButtonLastChildStyles,
  radioButtonStyles,
  span1Styles,
  spanLastStyles,
  spanStyles,
  titleStyles
} from './Step4.styles';
import { IStep4 } from './Step4.models';
import { contentStyles } from '../Step1/Step1.styles';
import { removeLastExtension } from '../../../../helpers/removeExtension';

const Step4: React.FC<IStep4> = ({ step4Data, setStep4Data, setActiveStep, arrayOfNumericalColumns, step1Data }) => {
  const formik = useFormik({
    initialValues: {
      preprocessing: step4Data.preprocessing?.length > 0 ? step4Data.preprocessing : '',
      option: step4Data.option?.length > 0 ? step4Data.option : ''
    },
    onSubmit
  });

  const { values, handleChange } = formik;

  useEffect(() => {
    setStep4Data({ ...values });
  }, [values]);

  function onBackClick() {
    setActiveStep(3);
  }

  function onSubmit() {
    setActiveStep(5);
    if (step4Data.preprocessing.length > 0) {
      setStep4Data({ ...step4Data, skip: false });
    } else {
      setStep4Data({ ...step4Data, skip: true });
    }
  }

  return (
    <Formik initialValues={step4Data} onSubmit={onSubmit}>
      <Form style={formContainerStyles}>
        <Grid item xs={12} md={12} style={containerStyles}>
          <div style={contentStyles}>
            <Grid item xs={12}>
              <h2 style={titleStyles}>Numerical Preprocessing</h2>
            </Grid>
            {Object.keys(arrayOfNumericalColumns).length === 0 ? (
              <Grid item xs={12}>
                <span style={span1Styles}>
                  The dataset '{removeLastExtension(step1Data.uploadedFile)}' does not contain any numerical columns.
                </span>
              </Grid>
            ) : Object.keys(arrayOfNumericalColumns).length === 1 ? (
              <Grid item xs={12}>
                <span style={span1Styles}>
                  The dataset '{removeLastExtension(step1Data.uploadedFile)}' contains only 1 numeric column, which is
                  already chosen for target variable.
                </span>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={12} style={formStyles}>
                  <h2 style={descriptionStyles}>Have you implemented any standard scaling method?</h2>
                  <Grid item xs={12} md={12} style={formOptionStyles}>
                    <label style={radioButtonStyles}>
                      <input
                        type="radio"
                        name="option"
                        value="no"
                        checked={values.option === 'no'}
                        onChange={handleChange}
                        style={inputStyles}
                      />
                      <span style={spanStyles}>No</span>
                    </label>
                    <label style={radioButtonStyles}>
                      <input
                        type="radio"
                        name="option"
                        value="yes"
                        checked={values.option === 'yes'}
                        onChange={handleChange}
                        style={inputStyles}
                      />
                      <span style={spanLastStyles}>Yes</span>
                    </label>
                  </Grid>
                  {values.option === 'yes' && (
                    <>
                      <Grid item xs={12} md={12} style={preproccessing1Styles}>
                        <h2 style={descriptionLastStyles}>Which method?</h2>
                        <Grid item xs={12} md={12} style={preproccessingStyles}>
                          <label style={radioButtonStyles}>
                            <input
                              type="radio"
                              name="preprocessing"
                              value="mean_std"
                              onChange={handleChange}
                              checked={values.preprocessing === 'mean_std'}
                              style={inputStyles}
                            />
                            <span style={spanStyles}>Mean and standard deviation scaling</span>
                          </label>
                          <label style={radioButtonLastChildStyles}>
                            <input
                              type="radio"
                              name="preprocessing"
                              value="min_max"
                              onChange={handleChange}
                              checked={values.preprocessing === 'min_max'}
                              style={inputStyles}
                            />
                            <span style={spanStyles}>Min and max scaling</span>
                          </label>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </div>
          <Grid item xs={12} md={12} sx={buttonContainerStyles}>
            <Button variant="contained" size="large" style={backButtonStyles} sx={buttonStyles} onClick={onBackClick}>
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{
                backgroundColor:
                  (!values.option || (values.option === 'yes' && !values.preprocessing)) &&
                  Object.keys(arrayOfNumericalColumns).length > 0 &&
                  Object.keys(arrayOfNumericalColumns).length !== 1
                    ? '#d9d9d9'
                    : '#334063',
                color:
                  (!values.option || (values.option === 'yes' && !values.preprocessing)) &&
                  Object.keys(arrayOfNumericalColumns).length > 0 &&
                  Object.keys(arrayOfNumericalColumns).length !== 1
                    ? '#635F5F'
                    : '#fff',
                width: '120px',
                height: '60px',
                borderRadius: '8px',
                fontFamily: 'Circular Std Book',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                float: 'right'
              }}
              sx={buttonStyles}
              onClick={onSubmit}
              disabled={
                (!values.option || (values.option === 'yes' && !values.preprocessing)) &&
                Object.keys(arrayOfNumericalColumns).length > 0 &&
                Object.keys(arrayOfNumericalColumns).length !== 1
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default Step4;
