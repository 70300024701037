import { AppDispatch } from 'store/store';
import { ModalName } from 'types/modals';
import { IOpenedAlert, IOpenedModal } from 'types/reduxTypes/modals';

import slice from './slice';

const { addModalToList, removeModalFromList, updateToastNotificationState } = slice.actions;

const openModal =
  <P>(value: IOpenedModal<P>) =>
  (dispatch: AppDispatch) => {
    dispatch(addModalToList(value));
  };

const setToastNotificationPopUpState =
  <P>(value: IOpenedAlert<P>) =>
  (dispatch: AppDispatch) => {
    dispatch(updateToastNotificationState(value));
  };

const closeModal = (name: ModalName) => (dispatch: AppDispatch) => {
  dispatch(removeModalFromList(name));
};

export default {
  openModal,
  closeModal,
  setToastNotificationPopUpState
};
