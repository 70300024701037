import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setDatasourceColumnsData } = slice.actions;

const getDatasourceColumns = (url: any) => async () => {
  try {
    const response = await API.datasourceColumns.getDatasourceColumns(url);
    dispatch(setDatasourceColumnsData(response.data));
  } catch (error) {
    dispatch(setDatasourceColumnsData([]))
  }
};

export default {
  getDatasourceColumns
};
