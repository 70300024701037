import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  RadioGroup,
  Typography,
  Button,
  Radio,
  FormControlLabel,
  TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setPreviewData, setPreviewStatus } from './../../store/slices/preview/middleware';
import { setResourcesData, setResourcesStatus } from './../../store/slices/resources/middleware';

import { dispatch, useAppSelector } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { previewMiddleware, previewSelector } from '../../store/slices/preview';
import { MbConverter } from '../../helpers/MbConverter';
import { makeStyles } from '@mui/styles';
import PreviewInfoTable from '../PreviewInfoTable';
import { actionsPreviewMiddleware, actionsPreviewSelector } from '../../store/slices/actionsPreview';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { uploadMiddleware } from '../../store/slices/upload';
import { uploadStatusMiddleware } from '../../store/slices/uploadStatus';
import { storageMiddleware } from '../../store/slices/storage';
import { modelTrainMiddleware } from '../../store/slices/modelTrain';
import { trainStatusMiddleware } from '../../store/slices/trainStatus';
import { trainProgressMiddleware } from '../../store/slices/trainProgress';
import { userModelsMiddleware } from '../../store/slices/userModels';
import { previewStatusMiddleware, previewStatusSelector } from '../../store/slices/previewStatus';
import { resourcesMiddleware } from '../../store/slices/resources';
import { removeLastExtension } from '../../helpers/removeExtension';

export interface PreviewDialogProps {
  fileName: string;
  fileSize: number;
  setChecked: any;
}

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#FCA311',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#FFC87F'
    }
  }
}));

const PreviewDialog = ({ fileName, fileSize, setChecked }: PreviewDialogProps) => {
  const [selectedColumn, setSelectedColumn] = useState<any>([]);
  const [selectedColumnName, setSelectedColumnName] = useState<any>([]);
  const [selectedColumnNameCopy, setSelectedColumnNameCopy] = useState<any>([]);
  const [isCloseButtonDisabled, setIsCloseButtonDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const userId = localStorage.getItem('userId');
  const { actionsPreviewData, actionsPreviewStatus } = useAppSelector(actionsPreviewSelector.actionsPreviewData);
  const actionsPreviewURL = '/data_operations/preprocessing';

  const [params, setParams] = useState<any>({
    file_name: `${fileName}`,
    is_transform: isChecked,
    new_filename: '',
    is_save: false,
    actions: {}
  });

  const [oneNumerical, setOneNumerical] = useState(false);
  const [oneCategorical, setOneCategorical] = useState(false);
  const [twoOrMoreNumerical, setTwoOrMoreNumerical] = useState(false);
  const [twoOrMoreCategorical, setTwoOrMoreCategorical] = useState(false);
  const [twoOrMoreNumericalCategorical, setTwoOrMoreNumericalCategorical] = useState(false);
  const [saveClick, setSaveClick] = useState(false);
  const [disableHandleMissingValues, setDisableHandleMissingValues] = useState(false);

  const [handlingMissingValue, setHandlingMissingValue] = useState('');
  const [handlingOutliersValue, setHandlingOutliersValue] = useState('');
  const [categoricalEncodingValue, setCategoricalEncodingValue] = useState('');
  const [createNewColumnValue, setCreateNewColumnValue] = useState('');
  const [manageColumnsValue, setManageColumnsValue] = useState('');
  const [createNewColumnInputValue, setCreateNewColumnInputValue] = useState('');
  const [newFileNameValue, setNewFileNameValue] = useState('');
  const [customValue, setCustomValue] = useState<any>(null);
  const [newColumnName, setNewColumnName] = useState<any>(null);
  const [duplicateName, setDuplicateName] = useState<any>(null);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);
  const [extensionError, setExtensionError] = useState(false);
  const [lastPressedPreview, setLastPressedPreview] = useState('');
  let [count, setCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  let [handlingMissingValuesPreviewModalSubmitClick, setHandlingMissingValuesPreviewModalSubmitClick] = useState(false);
  let [handlingOutliersValuesPreviewModalSubmitClick, setHandlingOutliersValuesPreviewModalSubmitClick] =
    useState(false);
  let [categoricalEncodingValuesPreviewModalSubmitClick, setCategoricalEncodingValuesPreviewModalSubmitClick] =
    useState(false);
  let [createNewColumnValuesPreviewModalSubmitClick, setCreateNewColumnValuesPreviewModalSubmitClick] = useState(false);
  let [manageColumnsValuesPreviewModalSubmitClick, setManageColumnsValuesPreviewModalSubmitClick] = useState(false);

  const getPreviewUrl = `/data_operations/file_preview?filename=${fileName}`;
  const { previewData, previewStatus } = useAppSelector(previewSelector.previewData);
  const { previewStatusData } = useAppSelector(previewStatusSelector.previewStatusData);
  const classes = useStyles();

  useEffect(() => {
    dispatch(previewMiddleware.getPreview(getPreviewUrl));
  }, []);

  // useEffect(() => {
  //   setHandlingMissingValue('')
  //   setManageColumnsValue('')
  //   setCreateNewColumnValue('')
  //   setCustomValue('')
  //   setNewColumnName('')
  //   setCreateNewColumnInputValue('')
  //   setNewFileNameValue('')
  // }, [selectedColumnName.length]);

  useEffect(() => {
    if (typeof actionsPreviewData === 'object' && actionsPreviewData.hasOwnProperty('preview_data'))
      dispatch(setPreviewData(actionsPreviewData));
  }, [actionsPreviewData]);

  useEffect(() => {
    if (selectedColumnName.length === 0) {
      setIsChecked(false);
    }
    setOneNumerical(
      selectedColumn.some(
        (obj: any) =>
          (obj.type === 'int64' || obj.type === 'datetime64[ns]' || obj.type === 'float64') &&
          selectedColumnName.length === 1
      )
    );
    setOneCategorical(selectedColumn.some((obj: any) => obj.type === 'object' && selectedColumnName.length === 1));
    setTwoOrMoreNumerical(selectedColumn.some((obj: any) => obj.type !== 'object' && selectedColumnName.length > 1));
    setTwoOrMoreCategorical(selectedColumn.some((obj: any) => obj.type === 'object' && selectedColumnName.length > 1));
    setTwoOrMoreNumericalCategorical(
      selectedColumn.some(
        (obj: any) => obj.type === 'int64' || obj.type === 'datetime64[ns]' || obj.type === 'float64'
      ) &&
        selectedColumn.some((obj: any) => obj.type === 'object') &&
        selectedColumn.length > 1
    );
  }, [selectedColumn]);

  useEffect(() => {
    if (lastPressedPreview !== 'manageColumnsValue') {
      setManageColumnsValue('');
    }
    if (lastPressedPreview !== 'createNewColumnValue') {
      setCreateNewColumnValue('');
    }
    if (lastPressedPreview !== 'categoricalEncodingValue') {
      setCategoricalEncodingValue('');
    }
    if (lastPressedPreview !== 'handlingOutliersValue') {
      setHandlingOutliersValue('');
    }
    if (lastPressedPreview !== 'handlingMissingValue') {
      setHandlingMissingValue('');
    }
  }, [lastPressedPreview]);

  const handleAccordionChange = (index: number) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const modelInfoModalOpen = (
    { onPreviewClick }: { onPreviewClick: any },
    { onSubmitClick }: { onSubmitClick: any },
    { setOnSubmitClick }: { setOnSubmitClick: any }
  ) => {
    dispatch(
      modalsMiddleware.openModal({
        name: ModalName.PreviewClickModal,
        props: { onPreviewClick, onSubmitClick, setOnSubmitClick }
      })
    );
  };

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.PreviewModal));
    dispatch(setPreviewData({}));
    dispatch(setPreviewStatus(0));
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const customValueChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setCustomValue(value);
    } else {
      setCustomValue(null);
      setDisableHandleMissingValues(true);
    }
    // if(customValue === null){
    //   setDisableHandleMissingValues(true)
    // }
  };

  const newColumnNameChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setNewColumnName(event.target.value);
    } else {
      setNewColumnName(null);
    }
  };

  const duplicateChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setDuplicateName(event.target.value);
    } else {
      setDuplicateName(null);
    }
  };

  const handlingMissingValuesChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setHandlingMissingValue(event.target.value);
    } else {
      setHandlingMissingValue('');
      setDisableHandleMissingValues(true);
    }
  };

  const handlingOutliersValuesChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setHandlingOutliersValue(event.target.value);
    } else {
      setHandlingOutliersValue('');
    }
  };

  const categoricalEncodingValuesChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setCategoricalEncodingValue(event.target.value);
    } else {
      setCategoricalEncodingValue('');
    }
  };

  const createNewColumnValuesChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setCreateNewColumnValue(event.target.value);
    } else {
      setCreateNewColumnValue('');
    }
  };

  const createNewColumnInputChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setCreateNewColumnInputValue(event.target.value);
    } else {
      setCreateNewColumnInputValue('');
    }
  };

  const manageColumnsValuesChange = (event: any) => {
    const value = event.target.value;
    if (value.length > 0) {
      setManageColumnsValue(event.target.value);
    } else {
      setManageColumnsValue('');
    }
  };

  const newFileNameValueChange = (event: any) => {
    const value = event.target.value;

    // Regular expression to allow only alphanumeric characters, underscores, and hyphens
    const validPattern = /^[a-zA-Z0-9_-]*$/;

    // Allow user to type _ and - but set error if those characters are present at the start
    if (validPattern.test(value)) {
      setNewFileNameValue(value);
      if (value.startsWith('-') || value.startsWith('_') || value.endsWith('-') || value.endsWith('_')) {
        setExtensionError(true);
        setErrorMessage('The dataset name cannot start and end with a special character.'); // Set specific error message
      } else {
        setExtensionError(false); // No error if only alphanumeric characters
        setErrorMessage(''); // Clear the error message
      }
    } else {
      setNewFileNameValue(value);
      setExtensionError(true);
      setErrorMessage('Spaces or special symbols are not allowed.'); // General error message
    }
  };

  const handlingMissingValuesPreviewClick = async (e: React.MouseEvent<HTMLButtonElement>, x?: any) => {
    setLastPressedPreview('handlingMissingValue');
    if (count > 1 || count === 1) {
      modelInfoModalOpen(
        { onPreviewClick: handlingMissingValuesPreviewClick },
        { onSubmitClick: handlingMissingValuesPreviewModalSubmitClick },
        { setOnSubmitClick: setHandlingMissingValuesPreviewModalSubmitClick }
      );
    }
    if (count === 0 || x) {
      let newParams = {};
      if (handlingMissingValue === 'custom value') {
        const words = handlingMissingValue.toLowerCase().split(' ');
        const modified = words
          .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
          .join('');
        const used_cols = [[selectedColumnName, !isNaN(customValue) ? parseInt(customValue) : customValue]];
        newParams = {
          ...params,
          actions: {
            [`hmv_${modified}`]: { used_cols }
          }
        };
      } else {
        newParams = {
          ...params,
          actions: {
            [`hmv_${handlingMissingValue}`]: { used_cols: selectedColumnName }
          }
        };
      }
      await dispatch(actionsPreviewMiddleware.getActionsPreview(actionsPreviewURL, newParams));
      setCount((prevCount) => prevCount + 1);
    }
    // setHandlingMissingValuesPreviewModalSubmitClick(false)
  };

  const handlingOutliersValuesPreviewClick = async (e: React.MouseEvent<HTMLButtonElement>, x?: any) => {
    setLastPressedPreview('handlingOutliersValue');
    if (count > 1 || count === 1) {
      modelInfoModalOpen(
        { onPreviewClick: handlingOutliersValuesPreviewClick },
        { onSubmitClick: handlingOutliersValuesPreviewModalSubmitClick },
        { setOnSubmitClick: setHandlingOutliersValuesPreviewModalSubmitClick }
      );
    }
    if (count === 0 || x) {
      let newParams = {};
      newParams = {
        ...params,
        actions: {
          [`ho_${handlingOutliersValue}`]: { used_cols: selectedColumnName }
        }
      };
      await dispatch(actionsPreviewMiddleware.getActionsPreview(actionsPreviewURL, newParams));
      setCount((prevCount) => prevCount + 1);
    }
  };

  const categoricalEncodingValuesPreviewClick = async (e: React.MouseEvent<HTMLButtonElement>, x?: any) => {
    setLastPressedPreview('categoricalEncodingValue');
    if (count > 1 || count === 1) {
      modelInfoModalOpen(
        { onPreviewClick: categoricalEncodingValuesPreviewClick },
        { onSubmitClick: categoricalEncodingValuesPreviewModalSubmitClick },
        { setOnSubmitClick: setCategoricalEncodingValuesPreviewModalSubmitClick }
      );
    }
    if (count === 0 || x) {
      let newParams = {};
      newParams = {
        ...params,
        actions: {
          [`ca_${categoricalEncodingValue}`]: { used_cols: selectedColumnName }
        }
      };
      await dispatch(actionsPreviewMiddleware.getActionsPreview(actionsPreviewURL, newParams));
      setCount((prevCount) => prevCount + 1);
    }
  };

  const createNewColumnValuesPreviewClick = async (e: React.MouseEvent<HTMLButtonElement>, x?: any) => {
    setLastPressedPreview('createNewColumnValue');
    if (count > 1 || count === 1) {
      modelInfoModalOpen(
        { onPreviewClick: createNewColumnValuesPreviewClick },
        { onSubmitClick: createNewColumnValuesPreviewModalSubmitClick },
        { setOnSubmitClick: setCreateNewColumnValuesPreviewModalSubmitClick }
      );
    }
    if (count === 0 || x) {
      let newParams = {};
      const array = [`${createNewColumnInputValue}`];
      const newArray = [...selectedColumnName, ...array];
      newParams = {
        ...params,
        actions: {
          manage_create: {
            used_cols: {
              [`${createNewColumnValue}`]: newArray
            }
          }
        }
      };
      await dispatch(actionsPreviewMiddleware.getActionsPreview(actionsPreviewURL, newParams));
      setCount((prevCount) => prevCount + 1);
    }
  };

  const manageColumnsValuesPreviewClick = async (e: React.MouseEvent<HTMLButtonElement>, x?: any) => {
    setLastPressedPreview('manageColumnsValue');
    if (count > 1 || count === 1) {
      modelInfoModalOpen(
        { onPreviewClick: manageColumnsValuesPreviewClick },
        { onSubmitClick: manageColumnsValuesPreviewModalSubmitClick },
        { setOnSubmitClick: setManageColumnsValuesPreviewModalSubmitClick }
      );
    }
    if (count === 0 || x) {
      let newParams = {};
      if (manageColumnsValue === 'rename') {
        const combinedArray = selectedColumnName.concat([newColumnName]);
        newParams = {
          ...params,
          actions: {
            [`manage_${manageColumnsValue}`]: { used_cols: [combinedArray] }
          }
        };
      } else {
        newParams = {
          ...params,
          actions: {
            [`manage_${manageColumnsValue}`]: { used_cols: selectedColumnName }
          }
        };
      }
      await dispatch(actionsPreviewMiddleware.getActionsPreview(actionsPreviewURL, newParams));
      setCount((prevCount) => prevCount + 1);
    }
  };

  const onDiscardClick = async () => {
    setCount(0);
    dispatch(previewMiddleware.getPreview(getPreviewUrl));
  };

  const onSaveClick = async () => {
    setIsCloseButtonDisabled(true);
    let newParams = {};
    if (isChecked) {
      newParams = {
        ...params,
        is_transform: false,
        is_save: true,
        new_filename: newFileNameValue.endsWith('.csv') ? newFileNameValue : `${newFileNameValue}.csv`
      };
    } else {
      newParams = {
        ...params,
        is_transform: true,
        is_save: true,
        new_filename: ''
      };
    }
    if (handlingMissingValue === 'custom value') {
      const words = handlingMissingValue.toLowerCase().split(' ');
      const modified = words
        .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
        .join('');
      const used_cols = [[selectedColumnNameCopy, !isNaN(customValue) ? parseInt(customValue) : customValue]];
      newParams = {
        ...newParams,
        is_save: true,
        actions: {
          [`hmv_${modified}`]: { used_cols }
        }
      };
    } else {
      newParams = {
        ...newParams,
        is_save: true,
        actions: {
          [`hmv_${handlingMissingValue}`]: { used_cols: selectedColumnNameCopy }
        }
      };
    }

    if (handlingOutliersValue.length > 0) {
      newParams = {
        ...newParams,
        is_save: true,
        actions: {
          [`ho_${handlingOutliersValue}`]: { used_cols: selectedColumnNameCopy }
        }
      };
    }

    if (categoricalEncodingValue.length > 0) {
      newParams = {
        ...newParams,
        is_save: true,
        actions: {
          [`ca_${categoricalEncodingValue}`]: { used_cols: selectedColumnNameCopy }
        }
      };
    }

    if (createNewColumnValue.length > 0) {
      const array = [`${createNewColumnInputValue}`];
      const newArray = [...selectedColumnNameCopy, ...array];
      newParams = {
        ...newParams,
        is_save: true,
        actions: {
          manage_create: {
            used_cols: {
              [`${createNewColumnValue}`]: newArray
            }
          }
        }
      };
    }
    if (manageColumnsValue.length > 0) {
      if (manageColumnsValue === 'rename') {
        const combinedArray = selectedColumnNameCopy.concat([newColumnName]);
        newParams = {
          ...newParams,
          is_save: true,
          actions: {
            [`manage_${manageColumnsValue}`]: { used_cols: [combinedArray] }
          }
        };
      } else {
        newParams = {
          ...newParams,
          is_save: true,
          actions: {
            [`manage_${manageColumnsValue}`]: { used_cols: selectedColumnNameCopy }
          }
        };
      }
    }

    if (isChecked && newFileNameValue.length > 0) {
      setSaveClick(true);
      setExtensionError(false);
      const actionPreviewSaveData = await dispatch(
        actionsPreviewMiddleware.getActionsPreview(actionsPreviewURL, newParams)
      );

      if (actionPreviewSaveData === undefined) {
        onModalClose();
      }

      if (actionPreviewSaveData && actionPreviewSaveData.status === 200) {
        const previewStatusURL = `/data_operations/preprocessing_status/${actionPreviewSaveData.data.task_id}`;
        let intervalId: any = setInterval(async () => {
          const actionPreviewSaveDataStatus = await dispatch(previewStatusMiddleware.previewStatus(previewStatusURL));

          if (actionPreviewSaveDataStatus && actionPreviewSaveDataStatus.data.queue_state === 'SUCCESS') {
            setIsCloseButtonDisabled(false);
            toast.success('Saved Successfully!');
            clearInterval(intervalId);
            setSaveClick(false);
            onModalClose();
            const getAllResourcesURL = `/datasource/all-files`;
            setChecked(0);
            await dispatch(resourcesMiddleware.getResources(getAllResourcesURL));
          }
          if (
            (actionPreviewSaveDataStatus && actionPreviewSaveDataStatus.data.queue_state === 'FAILURE') ||
            (actionPreviewSaveDataStatus && Object.keys(actionPreviewSaveDataStatus).length === 0)
          ) {
            setIsCloseButtonDisabled(false);
            clearInterval(intervalId);
            if (actionPreviewSaveDataStatus && Object.keys(actionPreviewSaveDataStatus).length === 0) {
              toast.warn('You will see your trained model after refresh');
            } else {
              toast.error(removeLastExtension(actionPreviewSaveDataStatus.data.message));
            }
          }
        }, 10000);
      }
    } else {
      setExtensionError(true);
    }

    if (!isChecked) {
      setSaveClick(true);
      const actionPreviewSaveData = await dispatch(
        actionsPreviewMiddleware.getActionsPreview(actionsPreviewURL, newParams)
      );

      if (actionPreviewSaveData && actionPreviewSaveData.status === 200) {
        const previewStatusURL = `/data_operations/preprocessing_status/${actionPreviewSaveData.data.task_id}`;
        let intervalId: any = setInterval(async () => {
          const actionPreviewSaveDataStatus = await dispatch(previewStatusMiddleware.previewStatus(previewStatusURL));

          if (actionPreviewSaveDataStatus && actionPreviewSaveDataStatus.data.queue_state === 'SUCCESS') {
            setResourcesStatus(0);
            setResourcesData([]);
            toast.success('Saved Successfully!');
            clearInterval(intervalId);
            setSaveClick(false);
            onModalClose();
            window.location.reload();
            const getAllResourcesURL = `/datasource/all-files`;
            await dispatch(resourcesMiddleware.getResources(getAllResourcesURL));
          }
          if (
            (actionPreviewSaveDataStatus && actionPreviewSaveDataStatus.data.queue_state === 'FAILURE') ||
            (actionPreviewSaveDataStatus && Object.keys(actionPreviewSaveDataStatus).length === 0)
          ) {
            setIsCloseButtonDisabled(false);
            clearInterval(intervalId);
            if (actionPreviewSaveDataStatus && Object.keys(actionPreviewSaveDataStatus).length === 0) {
              toast.warn('You will see your trained model after refresh');
            } else {
              toast.error(removeLastExtension(actionPreviewSaveDataStatus.data.message));
            }
          }
        }, 10000);
      }
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      style={{ overflowX: 'hidden' }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onModalClose();
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="simple-dialog-title"
      open
    >
      <Box>
        <DialogTitle style={{ textAlign: 'center', position: 'relative' }} id="draggable-dialog-title">
          Data Processing
          <IconButton
            onClick={onModalClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
            disabled={isCloseButtonDisabled}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={`${removeLastExtension(fileName)} / ${MbConverter(fileSize)}`}
          />
          {previewStatus === 200 && !saveClick ? (
            <CardContent sx={{ pt: 0 }}>
              <Grid container spacing={2} columns={12}>
                <Grid item xs={8} style={{ padding: '0px 5px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '0px',
                      width: '100%',
                      '& .MuiPaper-root': {
                        width: '100%'
                      }
                    }}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
                  >
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={onDiscardClick}
                        fullWidth
                        style={{ marginRight: '20px', marginTop: '20px', width: '50px', marginBottom: '10px' }}
                        disabled={count === 0}
                      >
                        Discard
                      </Button>
                      <Button
                        variant="contained"
                        onClick={onSaveClick}
                        fullWidth
                        style={{
                          backgroundColor:
                            (isChecked && (!newFileNameValue || extensionError)) || count === 0 ? '#CCCCCC' : '#202090',
                          color:
                            (isChecked && (!newFileNameValue || extensionError)) || count === 0 ? '#666666' : '#fff',
                          marginTop: '20px',
                          width: '50px',
                          marginBottom: '10px'
                        }}
                        disabled={(isChecked && (!newFileNameValue || extensionError)) || count === 0}
                      >
                        Save
                      </Button>
                    </div>
                    <PreviewInfoTable
                      handleAccordionChange={handleAccordionChange}
                      setOpenAccordionIndex={setOpenAccordionIndex}
                      data={previewData}
                      selectedColumn={selectedColumn}
                      setSelectedColumn={setSelectedColumn}
                      setSelectedColumnName={setSelectedColumnName}
                      selectedColumnName={selectedColumnName}
                      setSelectedColumnNameCopy={setSelectedColumnNameCopy}
                      selectedColumnNameCopy={selectedColumnNameCopy}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} style={{ padding: '26px 5px' }}>
                  <div style={{ marginBottom: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: '0.25rem' }}
                        // disabled={selectedColumnName.length === 0}
                      />
                      <label style={{ marginLeft: '0.25rem' }}>
                        Do you need to create a new file after data preprocessing actions below?
                      </label>
                    </div>
                    {isChecked && (
                      <>
                        <TextField
                          style={{ width: '300px' }}
                          onChange={newFileNameValueChange}
                          placeholder="Please type the new dataset name here"
                          value={newFileNameValue}
                          size="small"
                          error={extensionError} // Highlight the input field in case of invalid input
                        />
                        {extensionError && <p style={{ color: 'red' }}>{errorMessage}</p>}
                      </>
                    )}
                  </div>
                  <Accordion expanded={openAccordionIndex === 0} onChange={() => handleAccordionChange(0)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      disabled={
                        selectedColumnName.length === 0 ||
                        twoOrMoreNumericalCategorical ||
                        selectedColumn.some((item: any) => item.isTransform === true)
                      }
                    >
                      <Typography variant="subtitle1">Handle Missing Values</Typography>
                    </AccordionSummary>
                    {/*<AccordionDetails>*/}
                    {/*  <Typography variant="subtitle2" align="center">*/}
                    {/*    Replace with*/}
                    {/*  </Typography>*/}
                    {/*</AccordionDetails>*/}
                    <AccordionDetails>
                      <FormControl component="fieldset">
                        <RadioGroup name="handlingMissingValue" value={handlingMissingValue}>
                          {oneCategorical || twoOrMoreCategorical ? null : (
                            <FormControlLabel
                              onChange={handlingMissingValuesChange}
                              value="mean"
                              control={<Radio />}
                              label="mean"
                            />
                          )}
                          {oneNumerical || twoOrMoreNumerical ? null : (
                            <FormControlLabel
                              onChange={handlingMissingValuesChange}
                              value="mode"
                              control={<Radio />}
                              label="mode"
                            />
                          )}
                          {oneCategorical || twoOrMoreCategorical ? null : (
                            <FormControlLabel
                              onChange={handlingMissingValuesChange}
                              value="median"
                              control={<Radio />}
                              label="median"
                            />
                          )}
                          {oneCategorical || twoOrMoreCategorical ? null : (
                            <FormControlLabel
                              onChange={handlingMissingValuesChange}
                              value="0"
                              control={<Radio />}
                              label="0"
                            />
                          )}
                          <FormControlLabel
                            onChange={handlingMissingValuesChange}
                            value="custom value"
                            control={<Radio />}
                            label="custom value"
                          />
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                    {handlingMissingValue === 'custom value' && (
                      <TextField
                        // type="number"
                        style={{ marginBottom: '20px' }}
                        onChange={customValueChange}
                        placeholder="Please type custom value"
                        value={customValue}
                        size="small"
                      />
                    )}
                    <AccordionDetails>
                      <Button
                        onClick={(e) => handlingMissingValuesPreviewClick(e)}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={
                          (handlingMissingValue === 'custom value' &&
                            customValue === null &&
                            disableHandleMissingValues) ||
                          (handlingMissingValue !== 'mean' &&
                            handlingMissingValue !== 'mode' &&
                            handlingMissingValue !== 'median' &&
                            handlingMissingValue !== '0' &&
                            customValue === null)
                        }
                      >
                        Preview
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={openAccordionIndex === 1} onChange={() => handleAccordionChange(1)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      disabled={
                        selectedColumnName.length === 0 ||
                        oneCategorical ||
                        twoOrMoreCategorical ||
                        twoOrMoreNumericalCategorical ||
                        selectedColumn.some((item: any) => item.isTransform === true)
                      }
                    >
                      <Typography variant="subtitle1">Handle Outliers</Typography>
                    </AccordionSummary>
                    {/*<AccordionDetails>*/}
                    {/*  <Typography variant="subtitle2" align="center">*/}
                    {/*    Replace with*/}
                    {/*  </Typography>*/}
                    {/*</AccordionDetails>*/}
                    <AccordionDetails>
                      <FormControl component="fieldset">
                        <RadioGroup>
                          <FormControlLabel
                            onChange={handlingOutliersValuesChange}
                            value="remove"
                            control={<Radio />}
                            label="remove"
                          />
                          <FormControlLabel
                            onChange={handlingOutliersValuesChange}
                            value="winsorize"
                            control={<Radio />}
                            label="winsorizing"
                          />
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <Button
                        onClick={(e) => handlingOutliersValuesPreviewClick(e)}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={handlingOutliersValue === ''}
                      >
                        Preview
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={openAccordionIndex === 2} onChange={() => handleAccordionChange(2)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      disabled={
                        selectedColumnName.length === 0 ||
                        oneNumerical ||
                        twoOrMoreNumerical ||
                        twoOrMoreNumericalCategorical ||
                        selectedColumn.some((item: any) => item.isTransform === true)
                      }
                    >
                      <Typography variant="subtitle1">Categorical Encoding</Typography>
                    </AccordionSummary>
                    {/*<AccordionDetails>*/}
                    {/*  <Typography variant="subtitle2" align="center">*/}
                    {/*    Replace with*/}
                    {/*  </Typography>*/}
                    {/*</AccordionDetails>*/}
                    <AccordionDetails>
                      <FormControl component="fieldset">
                        <RadioGroup>
                          <FormControlLabel
                            onChange={categoricalEncodingValuesChange}
                            value="ordinal"
                            control={<Radio />}
                            label="ordinal encoding"
                          />
                          <FormControlLabel
                            onChange={categoricalEncodingValuesChange}
                            value="onehot"
                            control={<Radio />}
                            label="one-hot encoding"
                          />
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <Button
                        onClick={(e) => categoricalEncodingValuesPreviewClick(e)}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={categoricalEncodingValue === ''}
                      >
                        Preview
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={openAccordionIndex === 3} onChange={() => handleAccordionChange(3)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      disabled={
                        selectedColumnName.length !== 2 ||
                        oneNumerical ||
                        oneCategorical ||
                        twoOrMoreNumericalCategorical ||
                        selectedColumn.some((item: any) => item.isTransform === true)
                      }
                    >
                      <Typography variant="subtitle1">Create a New Column</Typography>
                    </AccordionSummary>
                    {/*<AccordionDetails>*/}
                    {/*  <Typography variant="subtitle2" align="center">*/}
                    {/*    Replace with*/}
                    {/*  </Typography>*/}
                    {/*</AccordionDetails>*/}
                    <input
                      type="text"
                      placeholder="New Column Name"
                      value={createNewColumnInputValue}
                      onChange={createNewColumnInputChange}
                    />
                    <AccordionDetails>
                      <FormControl component="fieldset">
                        <RadioGroup name={createNewColumnValue} value={createNewColumnValue}>
                          <FormControlLabel
                            onChange={createNewColumnValuesChange}
                            value="+"
                            control={<Radio />}
                            label="sum"
                          />
                          {twoOrMoreCategorical ? null : (
                            <FormControlLabel
                              onChange={createNewColumnValuesChange}
                              value="-"
                              control={<Radio />}
                              label="subtraction"
                            />
                          )}
                          {twoOrMoreCategorical ? null : (
                            <FormControlLabel
                              onChange={createNewColumnValuesChange}
                              value="*"
                              control={<Radio />}
                              label="multiplication"
                            />
                          )}
                          {twoOrMoreCategorical ? null : (
                            <FormControlLabel
                              onChange={createNewColumnValuesChange}
                              value="/"
                              control={<Radio />}
                              label="division"
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <Button
                        onClick={(e) => createNewColumnValuesPreviewClick(e)}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={createNewColumnValue === '' || createNewColumnInputValue === ''}
                      >
                        Preview
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={openAccordionIndex === 4} onChange={() => handleAccordionChange(4)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      disabled={
                        selectedColumnName.length === 0 || selectedColumn.some((item: any) => item.isTransform === true)
                      }
                    >
                      <Typography variant="subtitle1">Manage Columns</Typography>
                    </AccordionSummary>
                    {/*<AccordionDetails>*/}
                    {/*  <Typography variant="subtitle2" align="center">*/}
                    {/*    Replace with*/}
                    {/*  </Typography>*/}
                    {/*</AccordionDetails>*/}
                    <AccordionDetails>
                      <FormControl component="fieldset">
                        <RadioGroup name={manageColumnsValue} value={manageColumnsValue}>
                          <FormControlLabel
                            onChange={manageColumnsValuesChange}
                            value="drop"
                            control={<Radio />}
                            label="drop column"
                          />
                          {/*<FormControlLabel*/}
                          {/*  onChange={manageColumnsValuesChange}*/}
                          {/*  value="duplicate"*/}
                          {/*  control={<Radio />}*/}
                          {/*  label="Duplicate column"*/}
                          {/*/>*/}
                          {/*{manageColumnsValue === 'duplicate' && (*/}
                          {/*  <TextField*/}
                          {/*    // type="number"*/}
                          {/*    style={{ marginBottom: '20px' }}*/}
                          {/*    onChange={duplicateChange}*/}
                          {/*    placeholder="Please type new column name"*/}
                          {/*    value={duplicateName}*/}
                          {/*    size="small"*/}
                          {/*  />*/}
                          {/*)}*/}
                          {twoOrMoreNumerical || twoOrMoreCategorical || twoOrMoreNumericalCategorical ? null : (
                            <FormControlLabel
                              onChange={manageColumnsValuesChange}
                              value="rename"
                              control={<Radio />}
                              label="rename column"
                            />
                          )}
                          {manageColumnsValue === 'rename' && (
                            <TextField
                              // type="number"
                              style={{ marginBottom: '20px' }}
                              onChange={newColumnNameChange}
                              placeholder="Please type new column name"
                              value={newColumnName}
                              size="small"
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <Button
                        onClick={(e) => manageColumnsValuesPreviewClick(e)}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={manageColumnsValue !== 'drop' && newColumnName === null}
                        // disabled={manageColumnsValue === ''}
                      >
                        Preview
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </CardContent>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '1100px',
                margin: '0',
                height: '100vh'
              }}
            >
              <CircularProgress style={{ color: 'blue' }} />
            </div>
          )}
        </Card>
      </Box>
    </Dialog>
  );
};

export default PreviewDialog;
