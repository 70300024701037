import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Fab, Grid } from '@mui/material';

import { IPlusIconButtonProps } from '../../types/components/buttons';

export const PlusIconButton = (props: IPlusIconButtonProps) => {
  const { handlePlusButtonClick, title, error } = props;

  return (
    <Grid container direction="row">
      <Grid item>
        <Fab style={{backgroundColor: '#202090', color: '#fff'}} onClick={handlePlusButtonClick}>
          <AddRoundedIcon fontSize="medium" />
        </Fab>
      </Grid>
      <Grid item sx={{ marginLeft: '16px' }}>
        <h3>{title}</h3>
        {error && <p style={{ color: 'red' }}>Max count is 1</p>}
      </Grid>
    </Grid>
  );
};

export const MinusIconButton = (props: { onClick: () => void }) => {
  const { onClick } = props;

  return (
    <Grid container direction="row-reverse">
      <Grid item>
        <Button className="minus-icon" onClick={onClick}>
          <RemoveIcon />
        </Button>
      </Grid>
    </Grid>
  );
};
