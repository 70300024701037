import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.delete;

const deleteData = createSelector([selector], (state) => state);

export default {
  deleteData
};
