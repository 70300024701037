import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CardContent, Grid, InputAdornment, TextField } from '@mui/material';

import { DashboardSearchProps, KeyedObject } from '../../types';
// import { readString } from 'react-papaparse';

import DashboardToolbar from './DashboardToolbar';
import { dispatch, useAppSelector } from '../../store/hooks';
import { resourcesSelector } from '../../store/slices/resources';
import { toast } from 'react-toastify';
import { uploadMiddleware, uploadSelector } from '../../store/slices/upload';
import { uploadStatusMiddleware, uploadStatusSelector } from '../../store/slices/uploadStatus';
import { storageMiddleware } from '../../store/slices/storage';
import { userModelsMiddleware, userModelsSelector } from '../../store/slices/userModels';
import { Storage, CloudUpload } from '@mui/icons-material';
import DatasetModalComponent from '../../modals/DatasetModal/DatasetModal.component';
import { uploadModelMiddleware, uploadModelSelector } from '../../store/slices/uploadModel';
import { setUploadModelData, setUploadModelStatus } from './../../store/slices/uploadModel/middleware';
import { uploadModelStatusMiddleware } from '../../store/slices/uploadModelStatus';
import { removeLastExtension } from '../../helpers/removeExtension';
import axios from 'axios';

const DashboardSearch = ({
  page,
  setPage,
  isModelSetupModalOpen,
  setIsModelSetupModalOpen,
  setUserModelsArray,
  fileSize,
  setNewArray,
  tableData,
  rows,
  setRows,
  hasUpload,
  searchProps,
  numSelected,
  selected,
  setSelected,
  actions,
  setActions,
  processing,
  setProcessing,
  isModels,
  rowData,
  setRowData,
  data,
  rowDataStatus,
  setRowDataStatus,
  setChecked
}: DashboardSearchProps) => {
  const [search, setSearch] = useState<string>('');
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadingModel, setUploadingModel] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const products = tableData;
  const userId = localStorage.getItem('userId');
  const getAllResourcesURL = `/datasource/all-files`;
  const getUserModelsURL = `/datasource/user_models`;
  const { uploadModelData, uploadModelStatus } = useAppSelector(uploadModelSelector.uploadModelData);

  useEffect(() => {
    setUploadModelData({});
    setUploadModelStatus(0);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const datasetRow = {
    id: 0,
    ceration_date: '2023-02-28 16:10:24+00:00',
    filename: '',
    shared_user: [],
    size: 1024,
    user: 'Armen Panyan',
    disabled: true
  };

  const handleFileUpload = async (e: any) => {
    const currentTarget = e.currentTarget;
    const uploadFileUrl = `/blob_file/stream_upload`;
    const file = e.target.files[0];

    const newRow = {
      id: 0,
      filename: file.name,
      shared_user: [],
      size: '',
      user: 'Armen Panyan',
      disabled: true
    };

    if (!file) {
      toast.error('No file selected');
      return;
    }

    const fileName = file.name.trim();
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // Check if the file extension is CSV
    if (fileExtension !== 'csv') {
      toast.error('Please select a .csv file');
      currentTarget.value = ''; // Clear the file input
      return;
    }

    // Validate CSV content before proceeding with upload
    // const validateCsvContent = new Promise((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.onload = function (e) {
    //     const content = e?.target?.result;
    //
    //     if (typeof content === 'string') {
    //       readString(content, {
    //         header: true,
    //         skipEmptyLines: true,
    //         complete: (result: any) => {
    //           if (result.errors.length === 0) {
    //             resolve(result.data);
    //           } else {
    //             toast.error('The file content does not match a valid CSV format');
    //             currentTarget.value = '';
    //             reject('Invalid CSV content');
    //           }
    //         },
    //         error: (error: any) => {
    //           toast.error('Error parsing the file content as CSV');
    //           currentTarget.value = '';
    //           reject('Error parsing CSV content');
    //         }
    //       });
    //     } else {
    //       toast.error('Failed to read the file content as a string');
    //       currentTarget.value = '';
    //       reject('File content is not a string');
    //     }
    //   };
    //   reader.readAsText(file);
    // });

    try {
      // await validateCsvContent;

      // CSV is valid, proceed with the upload process
      setUploading(true);

      if (setNewArray && file.name.endsWith('.csv')) {
        const x = tableData.unshift(newRow);
        setNewArray(tableData);
      }

      const formData = new FormData();
      const cleanedFile = new File([file], fileName);
      formData.append('file', cleanedFile);

      try {
        const response = await dispatch(uploadMiddleware.uploadFile(formData, uploadFileUrl, getAllResourcesURL));

        // if (response.status !== 200) {
        //   throw new Error('Upload failed');
        // }

        const uploadFileData = response.data;

        setUploading(false);

        // if (uploadFileData.status === 200) {
        //   if (setProcessing) {
        //     setProcessing(false);
        //   }
        // } else {
        //   tableData.shift();
        //   toast.error(uploadFileData.data.message);
        //   if (setNewArray) {
        //     setNewArray(tableData);
        //   }
        //   if (setProcessing) {
        //     setProcessing(false);
        //   }
        //   return;
        // }

        if (uploadFileData && Object.keys(uploadFileData).length > 0 && uploadFileData?.status_update) {
          const uploadStatusUrl = `/blob_file/data_status/${uploadFileData.status_update}`;
          let intervalId: any = setInterval(async () => {
            try {
              const uploadFileStatusData = await dispatch(
                uploadStatusMiddleware.uploadStatus(uploadFileData.status_update, uploadStatusUrl, getAllResourcesURL)
              );

              if (uploadFileStatusData.status === 200) {
                if (uploadFileStatusData.data.queue_state === 'SUCCESS') {
                  if (setProcessing) {
                    setProcessing(false);
                    toast.success('Upload successful!');
                  }
                  const getStorageURL = `blob_file/get_user_storage_size`;
                  dispatch(storageMiddleware.getStorage(getStorageURL));
                  clearInterval(intervalId);
                } else if (
                  uploadFileStatusData.data.queue_state === 'FAILURE' ||
                  (uploadFileStatusData && Object.keys(uploadFileStatusData).length === 0)
                ) {
                  clearInterval(intervalId);
                  if (uploadFileStatusData && Object.keys(uploadFileStatusData).length === 0) {
                    toast.warn(removeLastExtension(uploadFileStatusData.data.message));
                  } else {
                    toast.error(removeLastExtension(uploadFileStatusData.data.message));
                  }
                  if (setProcessing) {
                    setProcessing(false);
                  }
                  if (setNewArray) {
                    const x = tableData.shift();
                    setNewArray(tableData);
                  }
                }
              } else {
                clearInterval(intervalId);
                toast.error(removeLastExtension(uploadFileStatusData.data.message));
                if (setProcessing) {
                  setProcessing(false);
                }
                if (setNewArray) {
                  const x = tableData.shift();
                  setNewArray(tableData);
                }
              }
            } catch (error: any) {
              clearInterval(intervalId);
              toast.error(removeLastExtension(error?.response.data.message));
              if (setProcessing) {
                setProcessing(false);
              }
              if (setNewArray) {
                const x = tableData.shift();
                setNewArray(tableData);
              }
            }
          }, 5000);
        }
      } catch (error: any) {
        console.error('Upload failed with error:', (error as Error).message);
        toast.error(removeLastExtension(error?.response.data.message));
        setUploading(false);
        tableData.shift();
        if (setNewArray) {
          setNewArray(tableData);
        }
      }
    } catch (error) {
      console.error('CSV validation failed:', (error as Error).message);
    }
  };

  // const handleFileUpload = async (e: any) => {
  //   const currentTarget = e.currentTarget;
  //   const uploadFileUrl = `/blob_file/upload`;
  //   const file = e.target.files[0];
  //
  //   const newRow = {
  //     id: 0,
  //     filename: file.name,
  //     shared_user: [],
  //     size: '',
  //     user: 'Armen Panyan',
  //     disabled: true
  //   };
  //
  //   if (!file) {
  //     toast.error('No file selected');
  //     return;
  //   }
  //
  //   const fileName = file.name;
  //   const fileExtension = fileName.split('.').pop().toLowerCase();
  //
  //   // Check if the file extension is CSV
  //   if (fileExtension !== 'csv') {
  //     toast.error('Please select a .csv file');
  //     currentTarget.value = ''; // Clear the file input
  //     return;
  //   }
  //
  //   // Create a promise to validate CSV content before proceeding with upload
  //   // const validateCsvContent = new Promise((resolve, reject) => {
  //   //   const reader = new FileReader();
  //   //   reader.onload = function (e) {
  //   //     const content = e?.target?.result;
  //   //
  //   //     // Ensure content is a string
  //   //     if (typeof content === 'string') {
  //   //       // Parse the CSV content using react-papaparse
  //   //       readString(content, {
  //   //         header: true, // Set to true if the first row of the CSV contains headers
  //   //         skipEmptyLines: true, // Skip empty lines to avoid parsing issues
  //   //         complete: (result: any) => {
  //   //           if (result.errors.length === 0) {
  //   //             resolve(result.data);
  //   //           } else {
  //   //             toast.error('The file content does not match a valid CSV format');
  //   //             currentTarget.value = ''; // Clear the file input
  //   //             reject('Invalid CSV content');
  //   //           }
  //   //         },
  //   //         error: (error: any) => {
  //   //           toast.error('Error parsing the file content as CSV');
  //   //           currentTarget.value = ''; // Clear the file input
  //   //           reject('Error parsing CSV content');
  //   //         }
  //   //       });
  //   //     } else {
  //   //       toast.error('Failed to read the file content as a string');
  //   //       currentTarget.value = ''; // Clear the file input
  //   //       reject('File content is not a string');
  //   //     }
  //   //   };
  //   //   reader.readAsText(file);
  //   // });
  //
  //   try {
  //     // Wait for CSV validation to complete
  //     // await validateCsvContent;
  //
  //     // If CSV is valid, proceed with the upload process
  //     setUploading(true);
  //
  //     if (setNewArray && file.name.endsWith('.csv')) {
  //       const x = tableData.unshift(newRow);
  //       setNewArray(tableData);
  //     }
  //
  //     const data = new FormData();
  //     data.append('file', file);
  //     const uploadFileData = await dispatch(uploadMiddleware.uploadFile(data, uploadFileUrl, getAllResourcesURL));
  //
  //     setUploading(false);
  //
  //     if (uploadFileData.status === 200) {
  //       if (setProcessing) {
  //         setProcessing(false);
  //       }
  //     } else {
  //       tableData.shift();
  //       toast.error(uploadFileData.data.message);
  //       if (setNewArray) {
  //         setNewArray(tableData);
  //       }
  //       if (setProcessing) {
  //         setProcessing(false);
  //       }
  //     }
  //
  //     if (uploadFileData && Object.keys(uploadFileData).length > 0 && uploadFileData?.data?.status_update) {
  //       const uploadStatusUrl = `/blob_file/data_status/${uploadFileData.data.status_update}`;
  //       let intervalId: any = setInterval(async () => {
  //         try {
  //           const uploadFileStatusData = await dispatch(
  //             uploadStatusMiddleware.uploadStatus(
  //               uploadFileData?.data?.status_update,
  //               uploadStatusUrl,
  //               getAllResourcesURL
  //             )
  //           );
  //
  //           if (uploadFileStatusData.status === 200) {
  //             if (uploadFileStatusData.data.queue_state === 'SUCCESS') {
  //               if (setProcessing) {
  //                 setProcessing(false);
  //                 toast.success('Upload successful!');
  //               }
  //               const getStorageURL = `blob_file/get_user_storage_size`;
  //               dispatch(storageMiddleware.getStorage(getStorageURL));
  //               clearInterval(intervalId);
  //             } else if (
  //               uploadFileStatusData.data.queue_state === 'FAILURE' ||
  //               (uploadFileStatusData && Object.keys(uploadFileStatusData).length === 0)
  //             ) {
  //               clearInterval(intervalId);
  //               if (uploadFileStatusData && Object.keys(uploadFileStatusData).length === 0) {
  //                 toast.warn('You will see your trained model after refresh');
  //               } else {
  //                 toast.error('Error in status endpoint!');
  //               }
  //               if (setProcessing) {
  //                 setProcessing(false);
  //               }
  //               if (setNewArray) {
  //                 const x = tableData.shift();
  //                 setNewArray(tableData);
  //               }
  //             }
  //           } else {
  //             clearInterval(intervalId);
  //             toast.error('Error in status endpoint!');
  //             if (setProcessing) {
  //               setProcessing(false);
  //             }
  //             if (setNewArray) {
  //               const x = tableData.shift();
  //               setNewArray(tableData);
  //             }
  //           }
  //         } catch (error) {
  //           clearInterval(intervalId);
  //           toast.error('Error in status endpoint!');
  //           if (setProcessing) {
  //             setProcessing(false);
  //           }
  //           if (setNewArray) {
  //             const x = tableData.shift();
  //             setNewArray(tableData);
  //           }
  //         }
  //       }, 5000);
  //     }
  //   } catch (error) {
  //     // If CSV is invalid, the upload process will not be triggered
  //     console.error('CSV validation failed:', error);
  //   }
  // };

  const handleModelUpload = async (e: any) => {
    const currentTarget = e.currentTarget;
    const uploadModelUrl = `/model_import/model_upload`;
    const file = e.target.files[0];
    const fileName = file.name.trim();

    const newRow = {
      id: 0,
      // ceration_date: '2023-02-28 16:10:24+00:00',
      filename: file.name,
      shared_user: [],
      size: '',
      user: 'Armen Panyan',
      disabled: true
    };

    if (
      setUserModelsArray &&
      (file.name.endsWith('.pkl') ||
        file.name.endsWith('.h5') ||
        file.name.endsWith('.pickle') ||
        file.name.endsWith('.p'))
    ) {
      const x = tableData.unshift(newRow);
      setUserModelsArray(tableData);
    }

    if (
      !file.name.endsWith('.pkl') &&
      !file.name.endsWith('.h5') &&
      !file.name.endsWith('.pickle') &&
      !file.name.endsWith('.p')
    ) {
      toast.error('Please select a .pkl or .h5 file');
      currentTarget.value = '';
      return;
    }

    setUploadingModel(true);
    const data = new FormData();
    const cleanedFile = new File([file], fileName);
    data.append('file', cleanedFile);
    data.append('file', file);
    const uploadModelData = await dispatch(uploadModelMiddleware.uploadModel(data, uploadModelUrl));
    setUploadingModel(false);

    if (uploadModelData?.status === 200) {
      if (setProcessing) {
        setProcessing(false);
      }
    } else {
      tableData.shift();
      toast.error(removeLastExtension(uploadModelData?.data.message));
      if (setUserModelsArray) {
        setUserModelsArray(tableData);
      }
      if (setProcessing) {
        setProcessing(false);
      }
    }

    if (uploadModelData && Object.keys(uploadModelData).length > 0 && uploadModelData?.data?.status_update) {
      const uploadModelStatusUrl = `/model_import/model_import_status/${uploadModelData.data.status_update}`;
      let intervalId: any = setInterval(async () => {
        try {
          const uploadModelStatusData = await dispatch(
            uploadModelStatusMiddleware.uploadModelStatus(uploadModelStatusUrl)
          );

          if (uploadModelStatusData.status === 200) {
            if (uploadModelStatusData.data.queue_state === 'SUCCESS') {
              if (setProcessing) {
                setProcessing(false);
                toast.success('Upload successful!');
              }
              dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
              const getStorageURL = `blob_file/get_user_storage_size`;
              dispatch(storageMiddleware.getStorage(getStorageURL));
              clearInterval(intervalId);
            } else if (
              uploadModelStatusData.data.queue_state === 'FAILURE' ||
              (uploadModelStatusData && Object.keys(uploadModelStatusData).length === 0)
            ) {
              clearInterval(intervalId);
              if (uploadModelStatusData && Object.keys(uploadModelStatusData).length === 0) {
                toast.warn('You will see your trained model after refresh');
              } else {
                toast.error(removeLastExtension(uploadModelStatusData.data.message));
              }
              if (setProcessing) {
                setProcessing(false);
              }
              if (setUserModelsArray) {
                const x = tableData.shift();
                setUserModelsArray(tableData);
              }
            }
          } else {
            clearInterval(intervalId);
            toast.error(removeLastExtension(uploadModelStatusData.data.message));
            if (setProcessing) {
              setProcessing(false);
            }
            if (setUserModelsArray) {
              const x = tableData.shift();
              setUserModelsArray(tableData);
            }
          }
        } catch (error: any) {
          clearInterval(intervalId);
          toast.error(removeLastExtension(error?.response.data.message));
          if (setProcessing) {
            setProcessing(false);
          }
          if (setUserModelsArray) {
            const x = tableData.shift();
            setUserModelsArray(tableData);
          }
        }
      }, 5000);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    const newString = event?.target.value;
    setSearch(newString ?? '');

    // Always filter from the full dataset `products` rather than `rows`
    if (newString) {
      const newRows = products.filter((row: KeyedObject) => {
        const properties = searchProps;
        return properties.some((property) =>
          removeLastExtension(row[property])?.toString().toLowerCase().includes(newString.toLowerCase())
        );
      });
      setRows(newRows);
      setPage(0);
    } else {
      setRows(products); // Reset to full dataset when there's no search query
      setPage(0);
    }
  };

  useEffect(() => {
    setRows(products); // Initialize rows with full dataset
    setPage(0); // Ensure we start from the first page
  }, [products]);

  return (
    <CardContent>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleSearch}
            placeholder={isModels ? 'Search Model' : 'Search Dataset'}
            value={search}
            size="small"
          />
        </Grid>
        {tableData.length > 0 && numSelected > 0 && (
          <Grid item xs={12} sm={10}>
            <DashboardToolbar
              setChecked={setChecked}
              isModelSetupModalOpen={isModelSetupModalOpen}
              setIsModelSetupModalOpen={setIsModelSetupModalOpen}
              rowDataStatus={rowDataStatus}
              setRowDataStatus={setRowDataStatus}
              fileSize={fileSize}
              rowData={rowData}
              setRowData={setRowData}
              data={data}
              isModels={isModels}
              selected={selected}
              setSelected={setSelected}
              numSelected={selected.length}
              actions={actions}
              setActions={setActions}
            />
          </Grid>
        )}
        {!isModels && (tableData.length === 0 || (hasUpload && numSelected <= 0)) && (
          <Grid item style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '50px' }}>
            <Button
              component="label"
              variant="contained"
              size="large"
              style={{ color: '#fff', backgroundColor: '#202090', marginRight: '10px' }}
              startIcon={<CloudUpload />}
            >
              Upload Dataset
              {!uploading && <input type="file" hidden onChange={handleFileUpload} />}
            </Button>
            {/*<Button*/}
            {/*  component="label"*/}
            {/*  variant="contained"*/}
            {/*  size="large"*/}
            {/*  style={{ color: '#fff', backgroundColor: '#202090', marginRight: '10px' }}*/}
            {/*  startIcon={<CloudUpload />}*/}
            {/*>*/}
            {/*  Upload Dataset1*/}
            {/*  {!uploading && <input type="file" hidden onChange={handleFileUpload1} />}*/}
            {/*</Button>*/}

            <Button
              component="label"
              variant="contained"
              size="large"
              style={{ color: '#fff', backgroundColor: '#202090' }}
              startIcon={<Storage />}
              onClick={openModal}
            >
              Get Dataset From DB
            </Button>
            {isModalOpen && (
              <DatasetModalComponent
                setNewArray={setNewArray}
                rowData={datasetRow}
                data={tableData}
                onClose={closeModal}
                title="Get Dataset From DB"
              />
            )}
          </Grid>
        )}
        {isModels && (tableData.length === 0 || numSelected <= 0) && (
          <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '50px' }}>
            <Button
              component="label"
              variant="contained"
              size="large"
              style={{ color: '#fff', backgroundColor: '#202090', marginRight: '10px' }}
              startIcon={<CloudUpload />}
            >
              Upload Model
              {!uploadingModel && <input type="file" hidden onChange={handleModelUpload} />}
            </Button>
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
};

export default DashboardSearch;
