import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setModelSummarySaveData, setModelSummarySaveStatus } = slice.actions;

const getModelSummarySave = (params: any, url: any) => async () => {
  try {
    const response = await API.modelSummarySave.getModelSummarySave(params, url);
    dispatch(setModelSummarySaveData(response.data));
    dispatch(setModelSummarySaveStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getModelSummarySave
};
