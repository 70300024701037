import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ModelListProps } from '../../../types/reduxTypes/modelList';

const createReducer = <T extends SliceCaseReducers<ModelListProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setModelListData(state, action) {
    state.modelListData = action.payload;
  },
  setModelListStatus(state, action) {
    state.modelListStatus = action.payload;
  }
});

export default reducers;
