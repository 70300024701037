import React, { useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardContent, Dialog, DialogTitle, IconButton } from '@mui/material';

import { dispatch, useAppSelector } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { Order } from '../../types';
import { deleteMiddleware, deleteSelector } from '../../store/slices/delete';
import { resourcesMiddleware, resourcesSelector } from '../../store/slices/resources';
import { userModelsMiddleware } from '../../store/slices/userModels';
import { storageMiddleware } from '../../store/slices/storage';
import CloseIcon from '@mui/icons-material/Close';

export interface PreviewClickDialogProps {
  onPreviewClick: any,
  onSubmitClick: any,
  setOnSubmitClick: any,
}

const PreviewClickDialog = ({ onPreviewClick, onSubmitClick, setOnSubmitClick }: PreviewClickDialogProps) => {

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.PreviewClickModal));
  };

  const onSubmit = (e:any) => {
    onPreviewClick(e,true)
    onModalClose();
  };

  return (
    <Dialog  onClose={onModalClose}  open>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        The previous changes will not be saved.
        Continue?
      </DialogTitle>
      <Card>
        <CardContent sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-around', gap: '16px' }}>
            <Button variant="outlined" fullWidth onClick={onModalClose}>
              Cancel
            </Button>
            <Button
              style={{ color: '#fff', backgroundColor: '#202090' }}
              variant="contained"
              onClick={onSubmit}
              fullWidth
              size="large"
            >
              Ok
            </Button>
          </div>
        </CardContent>
      </Card>
    </Dialog>

  );
};

export default PreviewClickDialog;
