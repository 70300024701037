import React from 'react';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IStep5Inner } from './Step5Inner2.models';
import { autocompleteStyles, containerStyles, dropdownStyles, removeIconStyles } from './Step5Inner2.styles';
import { emptyBlock } from '../Step52.helpers';
import { descriptionStyles, marginStyles } from '../Step52.styles';

const Step5Inner2: React.FC<IStep5Inner> = ({
  firstLabel,
  dropDownOptions,
  block,
  blocks,
  updateCurrentBlock,
  deleteBlock
}) => {
  const blockIndex = blocks.findIndex((b: any) => b.id === block.id);

  function onDropdownSelect(event: any, value: string | null) {
    const selectedOption = dropDownOptions.find((option) => option === value);
    if (!selectedOption || !value) return;
    const newBlock = emptyBlock({ id: block.id, dropdownValue: value });
    updateCurrentBlock(newBlock);
  }

  function onNumberSelect(event: any) {
    const value = event.target.value;
    // if (!value) return;
    const numericValue = parseInt(value);
    if ((numericValue >= 1 && numericValue <= 10) || value === '') {
      const newBlock = { ...block, firstInputValue: value };
      updateCurrentBlock(newBlock);
    }
  }

  function onNameSelect(event: any) {
    const value = event.target.value;
    // if (!value) return;
    const filteredValue = value.replace(/\d/g, ''); // Remove all numeric characters
    const newBlock = { ...block, secondInputValue: filteredValue };
    updateCurrentBlock(newBlock);
  }

  return (
    <>
      <Grid item xs={12} md={12} style={containerStyles}>
        <Grid style={autocompleteStyles}>
          <Grid xs={6} md={6} style={dropdownStyles}>
            <p
              style={{
                color: '#7f7f7f',
                margin: '6px 0',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Circular Std Book'
              }}
            >
              {firstLabel}
            </p>
            <Autocomplete
              options={dropDownOptions}
              value={block.dropdownValue || ''}
              onChange={(event, value) => {
                if (value === null) {
                  // Clear input value when clear indicator is clicked
                  updateCurrentBlock(emptyBlock({ id: block.id, dropdownValue: '' }));
                } else {
                  onDropdownSelect(event, value); // Call the existing handler
                }
              }}
              disableClearable={!block.dropdownValue} // Disable clear indicator when input is empty
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontFamily: 'Circular Std Book' // Ensure font-family is applied to each option
                  }}
                >
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      fontFamily: 'Circular Std Book' // Ensure font-family is applied to the selected input value
                    }
                  }}
                  label=""
                />
              )}
              clearOnEscape={false}
              fullWidth
            />
          </Grid>
          {/*{block.dropdownValue && (*/}
          {/*  <>*/}
          <Grid style={{ flex: '1' }} xs={6} md={6}>
            <p
              style={{
                color: '#7f7f7f',
                margin: '6px 0',
                // width: '50%',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Circular Std Book'
              }}
            >
              Number of top categories
            </p>
            <TextField
              style={{ width: '100%' }}
              type="number"
              value={block.firstInputValue}
              inputProps={{ min: 1, max: 10 }}
              onChange={onNumberSelect}
              onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '.') {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid style={{ flex: '1' }} xs={6} md={6}>
            <p
              style={{
                color: '#7f7f7f',
                margin: '6px 0',
                // width: '50%',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Circular Std Book',
                paddingLeft: '20px'
              }}
            >
              Name of grouped categories
            </p>
            <TextField
              type="text"
              style={{ width: '100%', marginLeft: '20px' }}
              value={block.secondInputValue}
              onChange={onNameSelect}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
          {Object.keys(blocks).length > 1 && (
            <IconButton
              style={{
                color: 'red',
                paddingTop: '45px',
                paddingLeft: '25px',
                display: 'flex',
                alignItems: 'flex-start',
                visibility: blockIndex > 0 ? 'visible' : 'hidden' // Only visible if it's not the first block
              }}
              onClick={() => deleteBlock(block.id, block.dropdownValue)}
              disableRipple={true}
            >
              <CloseIcon />
            </IconButton>
          )}
          {/*  </>*/}
          {/*)}*/}
        </Grid>
      </Grid>
    </>
  );
};

export default Step5Inner2;
