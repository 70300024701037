import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.uploadDataset;

const uploadDatasetData = createSelector([selector], (state) => state);

export default {
  uploadDatasetData
};
