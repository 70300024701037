import React from 'react';
import { useAppSelector } from 'store/hooks';
import { ModalName } from 'types/modals';
import { IOpenedModal } from 'types/reduxTypes/modals';

import { modalsSelector } from '../../store/slices/modals';
import DatasourceSummaryDialog, { DatasourceSummaryDialogProps } from '../Dialog/DatasourceSummaryDialog';
import PreviewDialog, { PreviewDialogProps } from '../Dialog/PreviewDialog';
import ModelViewDialog, { ModelViewDialogProps } from '../Dialog/ModelViewDialog';
import ModelManagerInferenceDialog, { ModelManagerInferenceDialogProps } from '../Dialog/ModelManagerInferenceDialog';
import DeleteDialog, { DeleteDialogProps } from '../Dialog/DeleteDialog';
import NNModelDialog, { ModelInfoDialogProps } from '../Dialog/NNModelDialog';
import LogisticRegressionModelDialog from '../Dialog/LogisticRegressionDialog';
import XGBoostModelDialog from '../Dialog/XGBoostDialog';
import CatboostModelDialog from '../Dialog/CatboostDialog';
import RandomModelDialog from '../Dialog/RandomDialog';
import ModelManagerSummaryDialog, { ModelManagerSummaryDialogProps } from '../Dialog/ModelManagerSummaryDialog';
import ShareInfoDialog, { ShareInfoDialogProps } from '../Dialog/ShareInfoDialog';
import UrlDialog, { UrlDialogProps } from '../Dialog/UrlDialog';
import PreviewClickDialog, { PreviewClickDialogProps } from '../Dialog/PreviewClickDialog';

const getDeleteModal = (modal: IOpenedModal<DeleteDialogProps>) => <DeleteDialog key={modal.name} {...modal.props} />;
const getPreviewClickModal = (modal: IOpenedModal<PreviewClickDialogProps>) => (
  <PreviewClickDialog key={modal.name} {...modal.props} />
);

const getDatasourceSummaryDialog = (modal: IOpenedModal<DatasourceSummaryDialogProps>) => (
  <DatasourceSummaryDialog key={modal.name} {...modal.props} />
);

const getPreviewDialog = (modal: IOpenedModal<PreviewDialogProps>) => (
  <PreviewDialog key={modal.name} {...modal.props} />
);

const getModelViewDialog = (modal: IOpenedModal<ModelViewDialogProps>) => (
  <ModelViewDialog key={modal.name} {...modal.props} />
);
const getModelManagerInferenceDialog = (modal: IOpenedModal<ModelManagerInferenceDialogProps>) => (
  <ModelManagerInferenceDialog key={modal.name} {...modal.props} />
);

const getModelManagerSummaryDialog = (modal: IOpenedModal<ModelManagerSummaryDialogProps>) => (
  <ModelManagerSummaryDialog key={modal.name} {...modal.props} />
);

const getShareInfoDialog = (modal: IOpenedModal<ShareInfoDialogProps>) => (
  <ShareInfoDialog key={modal.name} {...modal.props} />
);

const getNNInfoDialog = (modal: IOpenedModal<ModelInfoDialogProps>) => (
  <NNModelDialog key={modal.name} {...modal.props} />
);

const getLogisticRegressionInfoDialog = (modal: IOpenedModal<ModelInfoDialogProps>) => (
  <LogisticRegressionModelDialog key={modal.name} {...modal.props} />
);

const getXGBoostInfoDialog = (modal: IOpenedModal<ModelInfoDialogProps>) => (
  <XGBoostModelDialog key={modal.name} {...modal.props} />
);

const getCatboostInfoDialog = (modal: IOpenedModal<ModelInfoDialogProps>) => (
  <CatboostModelDialog key={modal.name} {...modal.props} />
);

const getRandomInfoDialog = (modal: IOpenedModal<ModelInfoDialogProps>) => (
  <RandomModelDialog key={modal.name} {...modal.props} />
);

const getUrlDialog = (modal: IOpenedModal<UrlDialogProps>) => <UrlDialog key={modal.name} {...modal.props} />;

export const ModalsController = () => {
  const modals = useAppSelector(modalsSelector.modals);

  return (
    <>
      {modals.map((modal) => {
        switch (modal.name) {
          case ModalName.DeleteModal:
            return getDeleteModal(modal);
          case ModalName.PreviewClickModal:
            return getPreviewClickModal(modal);
          case ModalName.DatasourceSummaryModal:
            return getDatasourceSummaryDialog(modal);
          case ModalName.PreviewModal:
            return getPreviewDialog(modal);
          case ModalName.ModelManagerInferenceDialog:
            return getModelManagerInferenceDialog(modal);
          case ModalName.ModelManagerSummaryDialog:
            return getModelManagerSummaryDialog(modal);
          case ModalName.ShareInfoModal:
            return getShareInfoDialog(modal);
          case ModalName.ModelViewModal:
            return getModelViewDialog(modal);
          case ModalName.NNInfoModal:
            return getNNInfoDialog(modal);
          case ModalName.LogisticRegressionInfoModal:
            return getLogisticRegressionInfoDialog(modal);
          case ModalName.XGBoostInfoModal:
            return getXGBoostInfoDialog(modal);
          case ModalName.CatboostInfoModal:
            return getCatboostInfoDialog(modal);
          case ModalName.RandomInfoModal:
            return getRandomInfoDialog(modal);
          case ModalName.urlDialog:
            return getUrlDialog(modal);
          default:
            return null;
        }
      })}
    </>
  );
};
