import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.resources;

const resourcesData = createSelector([selector], (state) => state);

export default {
  resourcesData
};
