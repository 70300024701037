import * as Yup from 'yup';

export const validationSchema = Yup.object({
  uploadedFile: Yup.mixed().required('A file is required').nullable()
});

export const initialValues = {
  uploadedFile: ''
};

