import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setUploadDatasetData, setUploadDatasetStatus } = slice.actions;

const uploadDataset = (params: any, url: any) => async () => {
  try {
    const response = await API.uploadDataset.uploadDataset(params, url);
    dispatch(setUploadDatasetData(response.data));
    dispatch(setUploadDatasetStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  uploadDataset
};
