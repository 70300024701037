import { Simulate } from 'react-dom/test-utils';
import input = Simulate.input;

export const removeLastExtension = (inputString: string) => {
  if (inputString !== null || inputString !== undefined) {
    if (Array.isArray(inputString) && inputString.length > 0) {
      inputString = inputString[0];
    }

    // Create a regular expression to match the specified extensions globally
    const regex = /\.(csv|onnx|h5|pkl|pickle|p|json)/g;

    // Use replace to remove all occurrences of the specified extensions
    inputString = inputString?.replace(regex, '');
  }

  return inputString;
};
