import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.modelSummarySave;

const modelSummarySaveData = createSelector([selector], (state) => state);

export default {
  modelSummarySaveData
};
