import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setDatasourceUsersData } = slice.actions;

const getDatasourceUsers = (url: any) => async () => {
  try {
    const response = await API.datasourceUsers.getDatasourceColumns(url);
    dispatch(setDatasourceUsersData(response.data));
  } catch (error) {}
};

export default {
  getDatasourceUsers
};
