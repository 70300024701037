import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { undeployProps } from '../../../types/reduxTypes/undeploy';

const createReducer = <T extends SliceCaseReducers<undeployProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setUndeployData(state, action) {
    state.undeployData = action.payload;
  },
  setUndeployStatus(state, action) {
    state.undeployStatus = action.payload;
  }
});

export default reducers;
