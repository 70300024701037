import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setPreviewStatusData } = slice.actions;

const previewStatus = (url: any) => async () => {
  try {
    const response = await API.previewStatus.previewStatus(url);
    dispatch(setPreviewStatusData(response.data));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  previewStatus
};
