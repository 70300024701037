import React from 'react';

export const containerStyles: React.CSSProperties = {
  padding: '0px 0px',
  color: '#000',
  position: 'relative',
  justifyContent: 'center',
  gap: '20px'
};

export const dropdownStyles: React.CSSProperties = {
  marginRight: '20px',
  width: '100%',
  flex: '1'
};
export const autocompleteStyles: React.CSSProperties = {
  width: '100%',
  display: 'flex'
};

export const removeIconStyles: React.CSSProperties = {
  color: 'red',
  paddingTop: '35px',
  paddingLeft: '25px',
  display: 'flex',
  alignItems: 'flex-start'
};

export const descriptionStyles: React.CSSProperties = {
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal'
};
