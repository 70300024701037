import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { IAction } from 'store/store';

import { ModelsProps } from '../../../types/reduxTypes/models';

const createReducer = <T extends SliceCaseReducers<ModelsProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setLoadingModelId(state, action: IAction<string>) {
    state.loadingModelId = action.payload;
  }
});

export default reducers;
