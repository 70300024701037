import React, { useState } from 'react';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Autocomplete, Box, Button, Dialog, DialogTitle, TextField } from '@mui/material';

import { top100Films } from '../../helpers/managers';
import { dispatch } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { MultipleInput } from '../../types/components/managers/datasource';
import { ModalName, SeveritiesType } from '../../types/modals';

export interface ShareInfoDialogProps {
  fileName: string;
}

const ShareInfoDialog = ({ fileName }: { fileName: string }) => {
  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.ShareInfoModal));
  };
  const successShare = () => {
    dispatch(
      modalsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Hello, i am success'
        }
      })
    );
  };
  const [shareWith, setShareWith] = useState<MultipleInput[]>([]);

  return (
    <Dialog onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <div style={{ padding: '30px 20px', minWidth: '600px' }}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          File Share
        </DialogTitle>
        <Box sx={{ padding: '20px' }}>
          <Box>
            <ul>
              <li>{fileName}</li>
            </ul>
          </Box>
          <Box>
            <Autocomplete
              multiple
              getOptionDisabled={(option) => shareWith.length === 10 || shareWith.includes(option)}
              onChange={(event, value) => {
                if (value) {
                  setShareWith(value);
                }
              }}
              options={top100Films}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={shareWith.length === 10}
                  helperText={shareWith.length === 10 && 'You cannot share a file with more than 10 people'}
                />
              )}
            />
          </Box>
        </Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            gap: '16px',
            paddingRight: '40px',
            paddingLeft: '40px',
            marginTop: '20px'
          }}
        >
          <Button variant="outlined" fullWidth onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            sx={{
              color: '#ffffff',
              backgroundColor: '#FCA311',
              '&:hover': {
                backgroundColor: '#F77F00'
              }
            }}
            onClick={successShare}
            variant="contained"
            fullWidth
            title="Delete"
            size="large"
          >
            <IosShareIcon />
            Share
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ShareInfoDialog;
