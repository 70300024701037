import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.uploadModelStatus;

const uploadModelStatusData = createSelector([selector], (state) => state);

export default {
  uploadModelStatusData
};
