import API from '../../../manager/API';
import { toast } from 'react-toastify';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setDownloadData, setDownloadStatus } = slice.actions;

const downloadFile = (url: any) => async () => {
  try {
    const response = await API.download.downloadFile(url);
    await dispatch(setDownloadData(response.data));
    dispatch(setDownloadStatus(response.status));
    toast.success('Download successful!');
  } catch (error) {
    toast.error('Something went wrong!');
  }
};

export default {
  downloadFile
};
