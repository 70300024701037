export enum Header {
  status = 'model_status',
  trainedModels = 'trainedModels',
  whoCanSee = 'whoCanSee',
  user = 'user',
  size = 'size',
  creation_time = 'creation_time',
  tags = 'tags',
  ds_name = 'ds_name',
  task = 'task',
  model_type = 'model_type'
}

export enum Status {
  Deployed = 'Deployed',
  Saved = 'Saved'
}
