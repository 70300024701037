import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.auth;

const authData = createSelector([selector], (state) => state);

export default {
  authData
};
