import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setUserModelsData, setUserModelsStatus } = slice.actions;

const getUserModels = (url: any) => async () => {
  try {
    const response = await API.userModels.getUserModels(url);
    dispatch(setUserModelsData(response.data));
    dispatch(setUserModelsStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getUserModels
};
