import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setUploadModelStatusData } = slice.actions;

const uploadModelStatus = (url: any) => async () => {
  try {
    const response = await API.uploadModelStatus.uploadModelStatus(url);
    dispatch(setUploadModelStatusData(response.data));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    return { status: error.response.status, data: error.response.data };
  }
};

export default {
  uploadModelStatus
};
