import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { StorageProps } from '../../../types/reduxTypes/storage';

const createReducer = <T extends SliceCaseReducers<StorageProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setStorageData(state, action) {
    state.storageData = action.payload;
  },
  setStorageStatus(state, action) {
    state.storageStatus = action.payload;
  }
});

export default reducers;
