import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { SnowflakeTestConnectionProps } from '../../../types/reduxTypes/snowflakeTestConnection';

const createReducer = <T extends SliceCaseReducers<SnowflakeTestConnectionProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setSnowflakeTestConnectionData(state, action) {
    state.snowflakeTestConnectionData = action.payload;
  },
  setSnowflakeTestConnectionStatus(state, action) {
    state.snowflakeTestConnectionStatus = action.payload;
  }
});

export default reducers;