import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.modelSummaryArrays;

const modelSummaryArraysData = createSelector([selector], (state) => state);

export default {
  modelSummaryArraysData
};
