import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const trainStatusManager = {
  getTrainStatus(url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'GET'
    });
  }
};

export default trainStatusManager;
