import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Form, Formik, useFormik } from 'formik';
import {
  Button,
  Grid,
  Input,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField
} from '@mui/material';

import {
  bodyColorStyles,
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  descriptionColorStyles,
  descriptionStyles,
  formContainerStyles,
  inputColorStyles,
  styledTableCellStyles,
  tableCellColorStyles,
  tableContainerStyles,
  tableStyles,
  titleStyles
} from './Step4.styles';
import {
  convertBytesToMB,
  getAllResourcesURL,
  initialValues,
  snowflakePushDataToAzureURL,
  userId,
  validationSchema,
  StyledTableCell,
  StyledTableRow
} from './Step4.constants';
import API from '../../../../manager/API';
import { IStep4 } from './Step4.models';
import { toast } from 'react-toastify';
import { dispatch } from '../../../../store/hooks';
import { resourcesMiddleware } from '../../../../store/slices/resources';
import { removeLastExtension } from '../../../../helpers/removeExtension';

const Step4: FC<IStep4> = ({
  setActiveStep,
  data,
  rowData,
  setNewArray,
  onClose,
  step2Data,
  step3Data,
  step4Data,
  step2EncryptedData
}) => {
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { column_names, result_size, result_size_bytes, data: queryData } = step4Data?.data?.result;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  const { values, handleChange, handleBlur, errors, touched } = formik;

  const onBackClick = () => {
    setActiveStep(3);
  };

  async function onSubmit() {
    try {
      setIsLoading(true);
      const response = await API.snowflakePushDataToAzure.getSnowflakePushDataToAzure(snowflakePushDataToAzureURL, {
        ...step2EncryptedData,
        ...step3Data,
        file_name: values.fileName.endsWith('.csv') ? values.fileName : `${values.fileName}.csv`,
      });
      onClose();
      const blinkingRow = rowData;
      const x = data.unshift(blinkingRow);
      setNewArray([...data]);
      const snowflakeDataPushStatusURL = `/snowflake_adding/data_push_status/${response.data.status_update}`;
      let intervalId: any = setInterval(async () => {
        try {
          const response = await API.snowflakeDataPushStatus.getSnowflakeDataPushStatus(snowflakeDataPushStatusURL);
          toast.success(removeLastExtension(response.data.message));
          if (response.data.queue_state === 'SUCCESS') {
            clearInterval(intervalId);
            dispatch(resourcesMiddleware.getResources(getAllResourcesURL));
            const y = data.shift();
            setNewArray([...data]);
            toast.success('Dataset Updated Successfully');
          } else {
            // Handle other states if needed
          }
        } catch (error: any) {
          clearInterval(intervalId);
          toast.error(removeLastExtension(error?.response?.data?.message));
          const y = data.shift();
          setNewArray([...data]);
        } finally {
          setIsLoading(false);
        }
      }, 5000);
    } catch (error: any) {
      toast.error(removeLastExtension(error?.response?.data?.message));
      const y = data.shift();
      setNewArray([...data]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsFormValid(values.fileName !== '' && /^[a-zA-Z0-9_-]+$/.test(values.fileName) && !Object.keys(errors).length);
  }, [values, errors]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form style={formContainerStyles}>
        <Grid item xs={12} md={12} style={containerStyles}>
          <Grid item xs={12}>
            <h2 style={titleStyles}>Dataset Preview</h2>
          </Grid>
          <Grid item xs={12} style={descriptionStyles}>
            <Grid>
              <p style={descriptionColorStyles}>
                Dataset has {result_size} rows and {column_names.length} columns ({convertBytesToMB(result_size_bytes)}
                Mb)
              </p>
              <p style={descriptionColorStyles}>Here is a sample of {queryData.length} rows</p>
            </Grid>
            <Grid item>
              <p style={inputColorStyles}>Please type the dataset name here:</p>
              <TextField
                error={!!errors.fileName} // This will make the input border red if there's an error
                id="fileName"
                label="FileName:"
                type="text"
                name="fileName"
                value={values.fileName}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[a-zA-Z0-9_-]*$/;

                  // Allow typing any characters, but check for validity
                  if (value.startsWith('-') || value.startsWith('_')) {
                    formik.setFieldError('fileName', 'Spaces or special symbols are not allowed.');
                  } else if (!regex.test(value)) {
                    formik.setFieldError('fileName', 'Spaces or special symbols are not allowed.');
                  } else {
                    formik.setFieldError('fileName', ''); // Clear the error if input is valid
                  }

                  handleChange(e); // Always update the form value
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  formik.setFieldTouched('fileName', true);
                }}
                helperText={errors.fileName ? errors.fileName : ''} // Show error message if there's an error
                inputProps={{ style: { width: '250px' } }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer style={tableContainerStyles}>
              <Table sx={tableStyles} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {column_names.map((columnName: any, index: any) => (
                      <StyledTableCell key={index} sx={styledTableCellStyles} style={tableCellColorStyles}>
                        {columnName}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={bodyColorStyles}>
                  {queryData.slice(0, 50).map((row: any, rowIndex: any) => (
                    <StyledTableRow hover key={rowIndex}>
                      {row.map((cell: any, cellIndex: any) => (
                        <StyledTableCell key={cellIndex}>{cell}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sx={buttonContainerStyles}>
            <Button
              variant="contained"
              size="large"
              style={{
                width: '120px',
                height: '60px',
                borderRadius: '8px',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                backgroundColor: '#334063',
                color: '#fff'
              }}
              sx={buttonStyles}
              onClick={onBackClick}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                width: '120px',
                height: '60px',
                borderRadius: '8px',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                backgroundColor: !isFormValid ? '#d9d9d9' : '#334063',
                color: !isFormValid ? '#635F5F' : '#fff',
                float: 'right'
              }}
              sx={buttonStyles}
              type="submit"
              onClick={onSubmit}
              disabled={!isFormValid}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default Step4;
