import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { TableCell, tableCellClasses, TableRow } from '@mui/material';

export const userId = localStorage.getItem('userId');

export const snowflakePushDataToAzureURL = '/snowflake_adding/push_data_to_azure';
export const getAllResourcesURL = `/datasource/all-files`;

export const validationSchema = Yup.object().shape({
  fileName: Yup.string()
    .test(
      'no-leading-underscore-or-dash',
      'The dataset name cannot start and end with a special character.',
      (value) => {
        if (!value) return true; // if value is empty, let required validation handle it
        return !/^[_-]/.test(value); // returns false if value starts with _ or -
      }
    )
    .test(
      'no-trailing-underscore-or-dash',
      'The dataset name cannot start and end with a special character.',
      (value) => {
        if (!value) return true; // if value is empty, let required validation handle it
        return !/[_-]$/.test(value); // returns false if value ends with _ or -
      }
    )
    .matches(/^[a-zA-Z0-9_-]*$/, 'Spaces or special symbols are not allowed.')
    .required('File name is required')
});

export const initialValues = {
  fileName: ''
};

export const convertBytesToMB = (bytes: number) => {
  if (isNaN(bytes) || bytes <= 0) {
    return 'Invalid input';
  }
  const gigabytes = bytes / 1024 ** 2; // 1 GB = 1024^3 bytes
  return gigabytes.toFixed(2);
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px' // set the maximum width here
  },
  '&:hover': {
    '&::after': {
      content: 'attr(title)',
      display: 'block',
      position: 'absolute',
      backgroundColor: '#333',
      color: '#fff',
      padding: '5px',
      zIndex: 1,
      left: '100%',
      top: '0',
      whiteSpace: 'normal',
      width: 'auto',
      minWidth: '100px',
      textAlign: 'center'
    }
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-of-type td, &:last-of-type th': {
    border: 0
  }
}));
