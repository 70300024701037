export const keys = [
  'SELECT',
  'FROM',
  'WHERE',
  'GROUP BY',
  'HAVING',
  'ORDER BY',
  'LIMIT',
  'OFFSET',
  'JOIN',
  'INNER JOIN',
  'OUTER JOIN',
  'LEFT JOIN',
  'RIGHT JOIN',
  'UNION',
  'UNION ALL',

  'SUM()',
  'AVG()',
  'MIN()',
  'MAX()',
  'COUNT()',
  'TO_DATE()',
  'TO_TIMESTAMP()',
  'CONVERT_TIMEZONE()',
  'NVL()',
  'COALESCE()',
  'CASE WHEN',
  'RANK()',
  'DENSE_RANK()',
  'ROW_NUMBER()',
  'LEAD()',
  'LAG()',
  'COVAR_POP()',
  'COVAR_SAMP()',
  'CORR()',
  'BITAND()',
  'BITOR()',
  'BITXOR()',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'UUID()',
  'ARRAY_AGG()',
  'TRY_CAST()',
  'SEQUENCE'
];