import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { AuthProps } from '../../../types/reduxTypes/auth';

const createReducer = <T extends SliceCaseReducers<AuthProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setAuthData(state, action) {
    state.authData = action.payload;
  },
  setAuthStatus(state, action) {
    state.authStatus = action.payload;
  }
});

export default reducers;
