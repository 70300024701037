import { toast } from 'react-toastify';
import slice from './slice';
import { dispatch } from '../../hooks';
import API from '../../../manager/API';
import { resourcesMiddleware } from '../resources';
import { userModelsMiddleware } from '../userModels';
import { storageMiddleware } from '../storage';
import { removeLastExtension } from '../../../helpers/removeExtension';

const { setDeleteData, setDeleteStatus } = slice.actions;
const userId = localStorage.getItem('userId');

const deleteFile = (params: any, url: any) => async () => {
  try {
    const response = await API.delete.deleteFile(params, url);
    dispatch(setDeleteData(response.data));
    dispatch(setDeleteStatus(response.status));
    toast.success('Delete successful!');
    const getAllResourcesURL = `/datasource/all-files`;
    const getUserModelsURL = `/datasource/user_models`;
    const getStorageURL = `blob_file/get_user_storage_size`;
    dispatch(resourcesMiddleware.getResources(getAllResourcesURL));
    dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
    dispatch(storageMiddleware.getStorage(getStorageURL));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    toast.error(removeLastExtension(error?.response.data.message));
  }
};

export default {
  deleteFile
};
