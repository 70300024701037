import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { SnowflakeGetQueryProps } from '../../../types/reduxTypes/snowflakeGetQuery';

const createReducer = <T extends SliceCaseReducers<SnowflakeGetQueryProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setSnowflakeGetQueryData(state, action) {
    state.snowflakeGetQueryData = action.payload;
  },
  setSnowflakeGetQueryStatus(state, action) {
    state.snowflakeGetQueryStatus = action.payload;
  }
});

export default reducers;