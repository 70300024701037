import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const storageManager = {
  getStorage(url: any) {
    return axiosInstance.v2.get(url);
  }
};

export default storageManager;
