import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Typography } from '@mui/material';

import { useAppSelector } from '../../../store/hooks';
import { viewsSelector } from '../../../store/slices/menu';

const Footer = () => {
  const { drawerOpen } = useAppSelector(viewsSelector.menu);

  return (
    <footer
      style={{
        marginTop: 'auto',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        alignItems: 'center',
        padding: '10px',
        // zIndex: '99'
        // marginLeft: drawerOpen ? '260px' : ''
      }}
    >
      <img src="/img.png" alt="logo" />
      <span>
        <Typography sx={{ padding: '5px' }}>Version: 0:0:1</Typography>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ padding: '5px' }}>Help</Typography>
          <HelpOutlineIcon color="primary" />
        </span>
      </span>
    </footer>
  );
};

export default Footer;
