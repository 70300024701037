import React from 'react';
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { EnhancedTableHeadProps, HeadCell } from 'types';

import { Order } from '../../types';
import { Model, Product } from '../../types/customer';

interface ProEnhancedTableHeadProps extends EnhancedTableHeadProps {
  tableHeaders: HeadCell<Product & Model>[];
  isComp?: boolean;
}

const DashboardHeader = ({
  isComp,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  tableHeaders
}: ProEnhancedTableHeadProps) => {
  const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ pl: 3 }}>
          {!isComp && rowCount !== 0? (
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          ) : (
            <div></div>
          )}
        </TableCell>
        {tableHeaders.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : Order.asc}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="body1" fontWeight="bold">
                {headCell.label}
              </Typography>
              {orderBy === headCell?.id ? (
                <Box
                  component="span"
                  // sx={visuallyHidden}
                  style={{ display: 'none' }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
          <Typography
            fontWeight="bold"
            variant="subtitle1"
            sx={{
              color: 'grey.900'
            }}
          >
            Actions
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default DashboardHeader;
