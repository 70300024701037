import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setHealthData } = slice.actions;

const getHealthData = () => async () => {
  try {
    const response = await API.health.getHealthData();
    dispatch(setHealthData(response.data));
  } catch (error) {}
};

export default {
  getHealthData
};
